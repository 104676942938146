<!--
   [2024/01/25 sb.hwang]
   CommunityHomeBannerYoutube.vue
   @note 
-->
<template>
    <v-main class="pt-0 pb-15 youtube-wrap">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            show-arrows
            fixed-tabs
            class="mb-3">
            <v-tab v-for="(list, index) in youtubeList" 
                :key="index">
                {{ list.ytbCtgryNm }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="(list, r_idx) in youtubeList"
                :key="r_idx"
                :transition="false"
            >
                <div class="carousel-item">
                    <vue-slick-carousel 
                        :slides-to-show="slide"
                        :dots="true">
                        <div 
                            v-for="(item, i_idx) in list.ytbLinkList" 
                            :key="i_idx" 
                            class="pd16"
                        >
                            <div class="carousel-card">
                                <div class="carousel-click cursor-pointer"
                                    @click="clickYoutube(list.ytbLinkList[i_idx].ytbVdoUrlAddr)"/>
                                <iframe
                                    tabindex="-1"
                                    :src="list.ytbLinkList[i_idx].ytbVdoUrlAddr + '?rel=0'"
                                    title="YouTube video player"
                                    class="youtube-video"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                    onload="loading"
                                />
                            </div>
                            <div class="carousel-content">
                                {{ list.ytbLinkList[i_idx].ytbVdoDscpCn }}
                            </div>
                        </div>
                    </vue-slick-carousel>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <com-dialog
            v-model="dialogYoutube"
            width="1000"
            class="dialog-youtube"
            :persistent="false"
            :bg="true"
        >
        <template v-slot:title>
            <div/>
        </template>
            <template v-slot:content>
                <div class="dialog-content">
                    <iframe
                        width="100%"
                        :height="dialogHeight"
                        :src="url + '?rel=0'"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    />
                </div>
            </template>
        </com-dialog>
    </v-main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ComDialog from '../com/ComDialog.vue';

export default {
    name: "CommunityHomeBannerYoutube",
    components: {
        VueSlickCarousel,
        ComDialog
    },
    data() {
        return {
            tab: null,
            dialogYoutube: false,
            url: '',
            boilerplate: false,
            youtubeList: [],
        };
    },
    computed: {
        slide() {
            if (this.$vuetify.breakpoint.xl) {
                return 4;
            }
            else if(this.$vuetify.breakpoint.lg) {
                return 3;
            }
            else if(this.$vuetify.breakpoint.md) {
                return 2;
            }
            return 1;  // sm, xs
        },
        dialogHeight() {
            if(this.$vuetify.breakpoint.mobile) {
                return '315px';
            }
            return '512px';
        }
    },
    methods: {
        clickYoutube(url) {
            this.dialogYoutube = true;
            this.url = url;
        },
        selectYoutubeLink() {
            this.$store.dispatch('selectYoutubeList')
            .then( res => {
                this.youtubeList = res;
            })
        }
    },
    created() {
        this.selectYoutubeLink();
    },
};
</script>

<style scoped>
.youtube-wrap {
    margin-left: 80px;
    margin-right: 80px;
}
.carousel-item {
    padding: 40px;
}
.pd16 {
  padding: 16px;
}

.carousel-card {
  height: 250px;
  border-radius: 8px;
  display: flex;
  position: relative;
  /* background: gray; */
}
.carousel-loader {
    position: absolute;
    width: 100%;
    height: 250px;
}
.youtube-video {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.carousel-click {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
}
.carousel-content {
    height: 50px;    
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

::v-deep .slick-prev:before,
::v-deep .slick-next:before,
::v-deep .slick-dots li.slick-active button:before,
::v-deep .slick-dots li button:before {
    color: #0096eb;
}

::v-deep .slick-dots li.slick-active button:before {
    border: 1px solid;
    border-radius: 50%;
}

.dialog-content {
    width: 100%;
}

@media (max-width: 599px) { 
    .youtube-wrap {
        margin-left: 10px;
        margin-right: 10px;
    }
    .carousel-card {
        height: 200px;
    }
}
</style>