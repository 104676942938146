<!--
   [2022/12/14 se.yoo]
   CommunityResetPwd.vue
   @note 비밀번호 찾기 비밀번호 변경 컴포넌트
-->
<template>
    <div class="mt-4">
        <v-form ref="pwdForm">
            <div class="community-login-input-label mt-9">
                {{ $t('password') }}
            </div>
            <v-text-field
                v-model="pswdCn"
                :rules="[rules.required, rules.password]"
                :type="showPassword ? 'text' : 'password'"
                height="47"
                :placeholder="$t('pwCondition')"
                class="mt-0 pt-0"
            >
                <template v-slot:append>
                    <v-icon
                        :aria-label="`${$t('altPasswordLookIcon') + showPassword ? $t('altNotLook') : $t('altLook')}`"
                        class="mt-2"
                        @click="showPassword = !showPassword"
                    >
                        {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                </template>
            </v-text-field>
            <div class="community-login-input-label mt-3">
                {{ $t('passwordConfirm') }}
            </div>
            <v-form ref="pwForm">
                <v-text-field
                    v-model="pswdCnConfirm"
                    :rules="[rules.required, confirmPw, rules.password]"
                    :type="showPasswordConfirm ? 'text' : 'password'"
                    height="47"
                    :placeholder="$t('confirmPassword')"
                    class="mt-0 pt-0"
                >
                    <template v-slot:append>
                        <v-icon
                            :aria-label="`${$t('altPasswordCheckIcon') + showPasswordConfirm ? $t('altNotLook') : $t('altLook')}`"
                            class="mt-2"
                            @click="showPasswordConfirm = !showPasswordConfirm"
                        >
                            {{ showPasswordConfirm ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                    </template>
                </v-text-field>
            </v-form>
        </v-form>
        <v-row
            no-gutters
            class="pwd-actions justify-end"
        >
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    @click="changePwd"
                >
                    {{ $t('change') }}
                </v-btn>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="alertResult"
            :title="title"
            :content="content"
            @agree="routerLogin"
        />
    </div>
</template>

<script>
import { rules } from '@/commons/resources.js';
import AlertDialog from '../com/AlertDialog.vue'

export default{
    name:'CommunityResetPwd',
    components:{
        AlertDialog
    },
    data(){
        return{
            pswdCnConfirm: '',
            showPassword: false,
            showPasswordConfirm: false,
            alertResult: false,
            title: '',
            content: '',
            successChange: false,
            rules,
        }
    },
    computed: {
        signUpUser() {
            return this.$store.getters.getSignUpUser;
        },
        pswdCn: {
            get() {
                return this.$store.state.user.pswdCn;
            },
            set(value) {
                this.$store.commit('setUserPswdCn', value);
            }
        }
    },
    methods:{
        /**
         * @method confirmPw
         * @note 비밀번호 확인 함수
         * @email gy.yang@naviworks.com
         */
        confirmPw(){
            return this.pswdCn == this.pswdCnConfirm || this.$t('passwordNotMatch')
        },
        /**
         * @method changePwd
         * @note 비밀번호를 변경하는 함수
         * @email sh.hong@naviworks.com
         */
        changePwd(){
            const validate = this.$refs.pwForm.validate();
            if(validate) {
                if (!this.$store.state.user.emlCertifed) {
                    this.$store.dispatch('resetPwd', this.signUpUser)
                    .then(() => {
                        this.title=this.$t('changePwTitle');
                        this.content=this.$t('changePw');
                        this.successChange = true;
                        this.alertResult = true;
                    }).catch((err) => {
                        if(err.response.data.errorMsg == '이전 비밀번호와 같습니다.<br>It is the same as the previous password') {
                            this.title=this.$t('changeNotPwTitle');
                            this.content=this.$t('changeNotPw');
                            this.alertResult = true;
                        }
                    });
                } else {
                    this.$store.dispatch('emlUpdatePswd', this.signUpUser)
                    .then(() => {
                        this.title=this.$t('changePwTitle');
                        this.content=this.$t('changePw');
                        this.successChange = true;
                        this.alertResult = true;
                    }).catch((err) => {
                        if(err.response.data.errorMsg == '이전 비밀번호와 같습니다.<br>It is the same as the previous password') {
                            this.title=this.$t('changeNotPwTitle');
                            this.content=this.$t('changeNotPw');
                            this.alertResult = true;
                        } else if(err.response.data.errorMsg == '이메일 인증 여부를 확인해주세요.<br>Please check the email verification status.') {
                            this.title = '이메일 인증';
                            this.content = '링크를 통해 이메일 주소를 인증해 주세요'
                            this.alertResult = true;
                        }
                    })
                }
            }
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerLogin(){
            if(this.successChange) {
                this.$router.push({
                    name: 'communityLogin'
                })
            }
        }
    }
}
</script>

<style scoped>
.pwd-actions {
    margin-top: 34px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .pwd-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>