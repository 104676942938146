<!--
   [2022/12/13 se.yoo]
   CommunitySignUpInfoPwd.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(비밀번호)
-->
<template>
    <div class="mt-4">
        <v-form ref="pwdForm">
            <div class="community-login-input-label mt-9">
                {{ $t('password') }}
            </div>
            <v-text-field
                v-model="pswdCn"
                :rules="[rules.required, rules.password]"
                :type="showPassword ? 'text' : 'password'"
                height="47"
                title="비밀번호"
                :placeholder="$t('pwCondition')"
                class="community-signup-input mt-0 pt-0"
            >
                <template v-slot:append>
                    <v-icon
                        :aria-label="`${$t('altPasswordLookIcon') + showPassword ? $t('altNotLook') : $t('altLook')}`"
                        class="mt-2"
                        @click="showPassword = !showPassword"
                    >
                        {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                </template>
            </v-text-field>
            <div class="community-login-input-label mt-3">
                {{ $t('passwordConfirm') }}
            </div>
            <v-text-field
                v-model="pswdCnConfirm"
                :rules="[rules.required, confirmPw]"
                :type="showPasswordConfirm ? 'text' : 'password'"
                :placeholder="$t('confirmPassword')"
                height="47"
                title="비밀번호 확인"
                class="community-signup-input mt-0 pt-0"
            >
                <template v-slot:append>
                    <v-icon
                        :aria-label="`${$t('altPasswordCheckIcon') + showPasswordConfirm ? $t('altNotLook') : $t('altLook')}`"
                        class="mt-2"
                        @click="showPasswordConfirm = !showPasswordConfirm"
                    >
                        {{ showPasswordConfirm ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                </template>
            </v-text-field>
        </v-form>
        <v-row
            no-gutters
            class="pwd-actions justify-end"
        >
            <!-- <v-col
                cols="5"
                class="pr-3"
            >
                <v-btn
                    block
                    outlined
                    height="60"
                    class="scm-community-button-outlined community-login-btn"
                    aria-label="회원가입 이전 페이지 이동 버튼"
                    @click="$emit('prevPage')"
                >
                    {{ $t('back') }}
                </v-btn>
            </v-col> -->
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="`회원가입 다음 페이지 이동 버튼"
                    @click="nextPage"
                >
                    {{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { rules, signUpStep } from '@/commons/resources.js';

export default{
    name:'CommunitySignUpInfoPwd',
    data(){
        return{
            pswdCnConfirm: '',
            showPassword: false,
            showPasswordConfirm: false,
            rules,
        }
    },
    computed: {
        pswdCn: {
            get() {
                return this.$store.state.user.pswdCn;
            },
            set(value) {
                this.$store.commit('setUserPswdCn', value);
            }
        }
    },
    methods:{
        /**
         * @method confirmPw
         * @note 비밀번호 확인 함수
         * @email gy.yang@naviworks.com
         */
        confirmPw(){
            return this.pswdCn == this.pswdCnConfirm || this.$t('passwordNotMatch')
        },
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            const validate = this.$refs.pwdForm.validate();

            if(!validate) return;

            this.$emit('nextPage', signUpStep.InfoNickName);
        }
    }
}
</script>

<style scoped>
.pwd-actions {
    margin-top: 34px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .pwd-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>