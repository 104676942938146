import Vue from 'vue';
import VueRouter from 'vue-router';
import PolicyDetail from '../views/policy/PolicyDetail.vue';

Vue.use(VueRouter);

const contestRoutes = [
    {
        path: '/',
        redirect: {
            name: 'policyDetail',
            params: { 
                sn: 1
            }
        }
    },
    {
        path: ':sn',
        name: 'policyDetail',
        component: PolicyDetail
    }
];

export default contestRoutes;