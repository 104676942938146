<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoId.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(아이디)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc"/>
            {{ $t('enterAge') }}
        <v-form ref="ageForm">
            <v-row>
                <v-col cols="6">
                    <div class="community-login-input-label mt-9 mb-5">
                        {{ $t('dateOfBirth') }}
                    </div>
                    <v-text-field
                        ref="pickerField"
                        :value="brdt"
                        dense
                        outlined
                        readonly
                        hide-details
                        height="29"
                        placeholder="YYYY-MM-DD"
                        title="기간"
                        class="search-form-input mt-0 pt-0"
                        @focus.stop="(datePicker = true)"
                    />
                    <v-date-picker
                        v-show="datePicker"
                        ref="picker"
                        v-model="brdt"
                        :rules="[rules.required]"
                        :header-date-format="headerDateFormat"
                        no-title
                        scrollable
                        :max="today"
                        color="primary"
                        class="mt-1 search-date-picker"
                        @input="(datePicker = false)"
                    />
                </v-col>
                <v-col cols="6">
                    <div class="community-login-input-label mt-9 mb-7">
                        {{ $t('gender')}}
                    </div>
                    <v-radio-group
                        v-model="gender"
                        row
                        mandatory
                        class="scm-community-form-radio-group"
                    >
                        <v-radio
                            :value="1"
                            :label="$t('male')"
                        />
                        <v-radio
                            :value="0"
                            :label="$t('female')"
                        />
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-form>
        <v-row
            no-gutters
            class="id-actions justify-end"
        >
            <!-- <v-col
                cols="5"
                class="pr-3"
            >
                <v-btn
                    block
                    outlined
                    height="60"
                    class="scm-community-button-outlined community-login-btn"
                    aria-label="회원가입 이전 페이지 이동 버튼"
                    @click="$emit('prevPage')"
                >
                    {{ $t('back') }}
                </v-btn>
            </v-col> -->
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    :disabled="disabledNext"
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="회원가입 다음 페이지 이동 버튼"
                    @click="nextPage"
                >
                    {{$t('next')}}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { rules, signUpStep } from '@/commons/resources.js';

export default{
    name:'CommunitySignUpInfoAge',
    components:{
    }, 
    data(){
        return{
            disabled: true,
            disabledNext: true,
            rules,
            brdt: '',
            datePicker: false,
            gender: 1,
            today: '',
        }
    },
    methods:{
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            const brdt = this.brdt.split('-').join('');
            this.$store.commit('setUserBrdt', brdt)
            this.$store.commit('setUserGndrCd', this.gender)
            this.$emit('nextPage', signUpStep.InfoPwd);
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        },
        /**
         * @method deleteDate
         * @note 날짜를 지우는 함수
         * @email gy.yang@naviworks.com
         */
        deleteDate() {
            this.brdt = '';
            this.datePicker = false;
        },
        /**
         * @method headerDateFormat
         * @param {string} date 날짜
         * @returns {string} 년-월
         * @note 달력 헤더에 나타낼 문자 반환 함수
         * @email se.yoo@naviworks.com
         */
         headerDateFormat(date){
            let dateSplit = date.split('-');
            if(1 === dateSplit.length){
                return dateSplit[0];
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
    },
    created() {
        this.today = new Date().toISOString().substr(0, 10);
    },
    watch:{
        brdt(){
            const validate = this.$refs.ageForm.validate();
            if(!validate){
                this.disabledNext = true;
            } else{
                this.disabledNext = false;
            }
        },
    }
}
</script>

<style scoped>
.id-actions {
    margin-top: 76px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .id-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}

.search-form-input {
    border-radius: 5px !important;
}

.search-form-input::v-deep .v-select__selection,
.search-form-input::v-deep .v-text-field__slot input {
    font-size: 15px;
    line-height: 100%;
    margin: 0;
}

.search-form-input::v-deep .v-input__slot {
    min-height: 40px !important;
}

.search-form-input::v-deep fieldset {
    border: 1px solid #D9D9D9;
}
.search-date-picker{
    position: absolute !important;
    z-index: 1;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
</style>