import { axiosService } from "@/commons/api";

const state =
{
    ntcSn: 0,
    ntcTtlNm: '',
    crtDt: '',
    nickNm: '',
    emlAddr: '',
    cn: '',
    imgUrlAddr: '',
    extrlUrlAddr: '',
    srvcCgCd: null,
    langCd: null,

    posts: []
};

const actions=
{
    resetPost(context) {
        const emptyPost = {};

        context.commit('setPost', emptyPost);
    },
    getNoticePostList(context) {
        return new Promise(function(resolve, reject)
        {
            context.dispatch('selectPostList', {
                srvcCgCd: state.srvcCgCd, 
                langCd: state.langCd
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    selectPostList(context, {srvcCgCd, langCd}) {
        return new Promise(function(resolve, reject)
        {
            const params = {
                srvcCgCd: srvcCgCd,
                langCd: langCd
            };

            axiosService
            .get('scm/mgt/user/noti', { params: params })
            .then(res => {
                context.commit('setPosts', res.data.notices);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    },
    selectPostDetail(context, ntcSn) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`scm/mgt/noti/${ntcSn}`)
            .then(res => {
                context.commit('setPost', res.data.notice);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    }
};

const getters=
{
    getPost: function (state) {
        return {
            ntcSn: state.ntcSn,
            ntcTtlNm: state.ntcTtlNm,
            crtDt: state.crtDt,
            nickNm: state.nickNm,
            emlAddr: state.emlAddr,
            cn: state.cn,
            imgUrlAddr: state.imgUrlAddr,
            extrlUrlAddr: state.extrlUrlAddr,
            srvcCgCd: state.srvcCgCd,
            langCd: state.langCd
        };
    },
    getPosts: function (state) {
        return _.cloneDeep(state.posts);
    }
};

const mutations= 
{
    setPost(state, post) {
        state.ntcSn = post.ntcSn || 'new';
        state.ntcTtlNm = post.ntcTtlNm || '';
        state.crtDt = post.crtDt || '';
        state.nickNm = post.nickNm || '';
        state.emlAddr = post.emlAddr || '';
        state.cn = post.cn || '';
        state.imgUrlAddr = post.imgUrlAddr || '';
        state.extrlUrlAddr = post.extrlUrlAddr || '';
        state.srvcCgCd = post.srvcCgCd || null;
    },
    setPosts(state, posts) {
        state.posts = posts;
    }
};

export default
{
    state, actions, getters, mutations
};
