<!--
   [2022/10/12 se.yoo]
   CommunityNav.vue
   @note 시민 커뮤니티 웹 페이지 메뉴(모바일)
-->
<template>
    <v-navigation-drawer
        v-model="show"
        fixed
        temporary
    >
        <v-list class="pa-0">
            <v-card
                tile
                color="#0A0A0A"
                elevation="0"
                height="80"
                class="d-flex align-center px-6 mb-3"
            >
                <v-img
                    :src="require('@/assets/images/scm-community-logo.svg')"
                    :alt="$t('altLogoImg')"
                    contain
                    max-width="150"
                    class="cursor-pointer"
                    @click="routerHome"
                />
            </v-card>
            <template v-for="(menu, i) in menus">
                <v-list-group
                    v-if="!menu.hidden && menu.sub != null"
                    :key="i"
                    v-model="groupOpen"
                    no-action
                    color="grey darken-1"
                    :append-icon="menu.sub == null? null : '$expand'"
                >
                    <template v-slot:activator>
                        <v-list-item class="px-5"
                        >
                            {{ $i18n.t(menu.name) }}
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="(subMenu, index) in menu.sub"
                        :key="index"
                        :to="subMenu.to"
                        link
                    >
                        <v-list-item>
                            {{ $i18n.t(subMenu.name) }}
                        </v-list-item>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                    v-else-if="!menu.hidden && menu.sub == null"
                    :key="i"
                    :to="menu.to"
                    link
                    class="px-9"
                    @click="groupOpen = false"
                >
                    {{ $i18n.t(menu.name) }}
                </v-list-item>
            </template>
            <!-- <v-list-item class="nav-actions">
                <v-btn
                    v-if="loggedIn"
                    id="logout"
                    rounded
                    outlined
                    tabindex="1"
                    @click="logout"
                >
                    {{ $t('logout') }}
                </v-btn>
                <v-btn
                    v-else
                    id="login"
                    rounded
                    outlined
                    tabindex="2"
                    @click="routerLogin"
                >
                    {{ $t('login') }}
                </v-btn>
            </v-list-item> -->
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { menus } from '@/commons/menus.js';

export default {
    name: 'CommunityNav',
    props: {
        showNav: {
            type: Boolean
        }
    },
    data() {
        return {
            menus,
            groupOpen: false
        };
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        loggedIn() {
            return this.$cookies.get('user_token')
        },
        show: {
            get() {
                return this.showNav;
            },
            set(value) {
                this.$emit('update:show-nav', value);
            }
        }
    },
    methods: {
        /**
         * @method routerHome
         * @note 메인화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerHome() {
            if(this.$route.name != 'communityHome') {
                this.$router.push({
                    name: 'communityHome'
                });
            }
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        // routerLogin(){
        //     this.$router.push({
        //         name: 'communityLogin'
        //     });
        // },
        /**
         * @method logout
         * @note 로그아웃하는 함수
         * @email se.yoo@naviworks.com
         */
        // logout() {
        //     this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
        //     this.$store.dispatch('resetUser');
        //     location.href = '/';
        // }
    }
}
</script>

<style scoped>
.nav-actions {
    width: 100%;
    padding: 12px 24px;
    justify-content: end;
}
</style>