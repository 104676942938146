<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="12" 
                md="6"
            >
                <v-row>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="crtDt"
                            label="crtDt"
                            :disabled="disabled"
                            type="disabled"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="name"
                            label="name"
                            disabled
                            type="disabled"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="brdt"
                            label="brdt"
                            disabled
                            type="datepicker"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="email"
                            label="email"
                            disabled
                            type="disabled"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-if="!isChange"
                            type="btn"
                            label="rcvryEmail"
                            :text="$t('registration')"
                            @clickBtn="changeRcvryEmail"
                        />
                        <input-field
                            v-else
                            v-model="originRcvryEmail"
                            label="rcvryEmail"
                            :text="$t('change')"
                            type="inputBtn"
                            @clickBtn="changeRcvryEmail"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col 
                cols="12" 
                md="6"
            >
                <v-row>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="nickName"
                            label="nickName"
                            :text="$t('change')"
                            type="inputBtn"
                            @clickBtn="openNickNameDialog"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="gender"
                            label="gender"
                            disabled
                            type="radio"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-if="mblTelno"
                            v-model="mblTelno"
                            label="mblTelno"
                            disabled
                            type="disabled"
                        />
                        <input-field
                            v-else
                            type="btn"
                            label="mblTelno"
                            :text="$t('registration')"
                            @clickBtn="fnPopup"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            v-model="sttsMsgCn"
                            label="sttsMsgCn"
                            :text="$t('change')"
                            type="inputBtn"
                            @clickBtn="openSttsMsgCnDialog"
                        />
                    </v-col>
                    <v-col 
                        cols="12"
                        class="py-0 py-md-3"
                    >
                        <input-field
                            type="btn"
                            label="password"
                            :text="$t('change')"
                            @clickBtn="changePwd"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <div
            class="secession"
        >
            <v-btn
                plain
                class="secession_btn"
                @click="deleteUser"
            >
                {{ $t('deleteUser') }}
            </v-btn>
        </div>

        
        <alert-dialog
            v-model="alertDialog"
            :show-disagree="showDisagree"
            :title="alertTitle"
            :content="alertContent"
            :disagree="$t('cancel')"
            @agree="alertAgree"
            @keydown.enter="alertAgree"
        />

        <community-email-certified-dialog
            v-model="dialogEmail"
            menu="mypage"
            @emlCertifiedComplete="emlCertifiedComplete"
        />

        <com-dialog
            v-model="dialogRcvryEmail"
            closable-btn
            width="600"
            :title="rcvryEmailTitle"
            :footer="rcvryEmailBtn"
            @btnClick="registerRcvryEmail"
            @cancelClick="cancelRegisterRcvryEmail"
        >
            <template v-slot:content>
                <v-text-field
                    v-model="rcvryEmail"
                    :rules="[rules.email, rules.required]"
                    disabled
                    height="47"
                    class="mt-0 pt-0"
                    @keydown.enter="registerRcvryEmail"
                />
            </template>
        </com-dialog>

        <com-dialog
            v-model="dialogNickName"
            closable-btn
            :title="$t('nickName') + ' ' + $t('change')"
            :footer="$t('change')"
            @btnClick="changeNickName"
        >
            <template v-slot:content>
                <v-text-field
                    v-model="changeNickNm"
                    :rules="[rules.required]"
                    height="47"
                    class="mt-0 pt-0"
                    @keydown.enter="changeNickName"
                />
            </template>
        </com-dialog>

        <com-dialog
            v-model="dialogSttsMsgCn"
            closable-btn
            :title="$t('sttsMsgCn') + ' ' + $t('change')"
            :footer="$t('change')"
            @btnClick="changeSttsMsgCn"
        >
            <template v-slot:content>
                <v-text-field
                    v-model="changeSttsMsg"
                    :rules="[rules.required]"
                    height="47"
                    class="mt-0 pt-0"
                    @keydown.enter="changeSttsMsgCn"
                />
            </template>
        </com-dialog>

        <com-dialog
            v-model="dialogCurPwd"
            closable-btn
            width="600"
            :title="$t('passwordConfirm')"
            :footer="$t('complete')"
            @btnClick="checkPwd(curPwd)"
        >
            <template v-slot:content>
                <v-text-field
                    v-model="curPwd"
                    :rules="[rules.required, rules.password]"
                    :type="showPassword ? 'text' : 'password'"
                    height="47"
                    class="mt-0 pt-0"
                    @keydown.enter="checkPwd(curPwd)"
                >
                    <template v-slot:append>
                        <v-icon
                            :aria-label="`${$t('altPasswordCheckIcon') + showPassword ? $t('altNotLook') : $t('altLook')}`"
                            class="mt-2"
                            @click="showPassword = !showPassword"
                        >
                            {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                    </template>
                </v-text-field>
            </template>
        </com-dialog>

        
        <com-dialog
            v-model="showDialog"
            closable-btn
            width="600"
            :title="dialogTitle"
            :footer="dialogTitle"
            @btnClick="updatePwd"
        >
            <template v-slot:content>
                <v-row class="mt-3">
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('passwordBefore') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="oldPwd"
                            :rules="[rules.required]"
                            :type="showPasswordBefore ? 'text' : 'password'"
                            :error-messages="oldPwd_msg"
                            dense
                            filled
                            rounded
                            height="60"
                            class="input-field rounded-lg"
                            @blur="checkPwd(oldPwd)"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPasswordBefore ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPasswordBefore = !showPasswordBefore"
                                >
                                    {{ showPasswordBefore ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('password') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="pswdCn"
                            :rules="[rules.required, rules.password]"
                            :type="showPassword ? 'text' : 'password'"
                            dense
                            filled
                            rounded
                            height="60"
                            class="input-field rounded-lg"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPassword ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPassword = !showPassword"
                                >
                                    {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-3">
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('passwordConfirm') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="pswdCnConfirm"
                            :rules="[rules.required, confirmPw, rules.password]"
                            :type="showPasswordConfirm ? 'text' : 'password'"
                            dense
                            filled
                            rounded
                            height="60"
                            class="input-field rounded-lg"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPasswordConfirm ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPasswordConfirm = !showPasswordConfirm"
                                >
                                    {{ showPasswordConfirm ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </com-dialog>


        <nice-dialog ref="niceModule"/>
    </v-container>
</template>

<script>
import InputField from '../com/InputField.vue';
import { toStringByFormatting } from '@/commons/functions';
import ComDialog from '../com/ComDialog.vue';
import AlertDialog from '../com/AlertDialog.vue';
import { rules } from '@/commons/resources.js';
import CommunityEmailCertifiedDialog from '@/components/login/CommunityEmailCertifiedDialog.vue';
import NiceDialog from '@/components/nice/NiceDialog.vue';

export default {
    name: 'MyPageInfo',
    props: {
        value: {
            type: Object,
            required: true
        },
        userCrtDt: {
            type: String,
            default: 'crtDt',
        },
        userNm: {
            type: String,
            default: 'userNm',
        },
        userBrdt: {
            type: String,
            default: 'brdt',
        },
        userNickNm: {
            type: String,
            default: 'nickNm',
        },
        userEmlAddr: {
            type: String,
            default: 'emlAddr',
        },
        userRcvryEmlAddr: {
            type: String,
            default: 'rcvryEmlAddr',
        },
        userGndrCd: {
            type: String,
            default: 'gndrCd',
        },
        userMblTelno: {
            type: String,
            default: 'mblTelno',
        },
        userSttsMsgCn: {
            type: String,
            default: 'sttsMsgCn',
        },
    },
    data() {
        return {
            disabled: true,
            userInfo: {},
            showDialog: false,
            rules,
            dialogEmail: false,
            dialogRcvryEmail: false,
            isChange: false,
            alertDialog: false,
            alertTitle: '',
            alertContent: '',
            dialogCurPwd: false,
            curPwd: '',
            showDisagree: false,
            originRcvryEmail: '',
            dialogNickName: false,
            dialogSttsMsgCn: false,
            changeNickNm: '',
            changeSttsMsg: '',
            oldPwd: '',
            pswdCn: '',
            pswdCnConfirm: '',
            showPasswordBefore: false,
            showPassword: false,
            showPasswordConfirm: false,
            oldPwd_msg: [],
            pwdConfirm: false,
        }
    },
    components: {
        InputField,
        ComDialog,
        CommunityEmailCertifiedDialog,
        AlertDialog,
        NiceDialog,
    },
    computed: {
        user: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value);
            }
        },
        crtDt: {
            get() {
                return this.convertDate(this.user[this.userCrtDt]);
            },
            set(value) {
                this.$set(this.user, this.userCrtDt, value);
            }
        },
        name: {
            get() {
                return this.user[this.userNm];
            },
            set(value) {
                this.$set(this.user, this.userNm, value);
            }
        },
        brdt: {
            get() {
                return this.convertDate(this.user[this.userBrdt]);
            },
            set(value) {
                this.$set(this.user, this.userBrdt, value);
            }
        },
        nickName: {
            get() {
                return this.user[this.userNickNm];
            },
            set(value) {
                this.$set(this.user, this.userNickNm, value);
            }
        },
        email: {
            get() {
                return this.user[this.userEmlAddr];
            },
            set(value) {
                this.$set(this.user, this.userEmlAddr, value);
            }
        },
        rcvryEmail: {
            get() {
                return this.user[this.userRcvryEmlAddr];
            },
            set(value) {
                this.$set(this.user, this.userRcvryEmlAddr, value);
            }
        },
        gender: {
            get() {
                return String(this.user[this.userGndrCd]);
            },
            set(value) {
                this.$set(this.user, this.userGndrCd, value);
            }
        },
        mblTelno: {
            get() {
                return this.user[this.userMblTelno] ? this.convertNumber(this.user[this.userMblTelno]) : null;
            },
            set(value) {
                this.$set(this.user, this.userMblTelno, value);
            }
        },
        sttsMsgCn: {
            get() {
                return this.user[this.userSttsMsgCn];
            },
            set(value) {
                this.$set(this.user, this.userSttsMsgCn, value);
            }
        },
        dialogTitle() {
            return  this.$t('changePassword');
        },
        rcvryEmailTitle() {
            return !this.isChange ? 
                this.$t('rcvryEmail') + ' ' + this.$t('registration') : 
                this.$t('rcvryEmail') + ' ' + this.$t('change')
        },
        rcvryEmailBtn() {
            return !this.isChange ? 
                this.$t('registration') : 
                this.$t('change')
        },
    },
    methods: {
        convertDate(date) {
            return toStringByFormatting(new Date(date));
        },
        convertNumber(phone) {
            if(phone.startsWith('02')){
                if(phone.length < 10){
                    return phone.substring(0, 2) + '-' + '***' + '-' + phone.substring(5);
                } else{
                    return phone.substring(0, 2) + '-' + '****' + '-' + phone.substring(6);
                }
            } else {
                if(phone.length < 11) {
                    return phone.substring(0, 3) + '-' + '***' + '-' + phone.substring(6);
                } else {
                    return phone.substring(0, 3) + '-' + '****' + '-' + phone.substring(7);
                }
            }
        },
        fnPopup() {
            this.$refs.niceModule.fnPopup()
            .then((res) => {
                const params = {
                    enc: res
                }
                const headers = {
                    Authorization: 'Bearer ' + this.$cookies.get('user_token')
                }
                this.$store.dispatch('changeEmailToPhone', {params, headers})
                .then((res) => {
                    this.$store.commit('setUserMblTelNo', res.mblTelno);
                    this.$store.commit('setUserNm', res.userNm);
                    this.$store.commit('setUserBrdt', res.brdt);
                    this.alertDialog = true;
                    this.alertTitle = this.$t('save') + ' ' + this.$t('success');
                    this.alertContent = this.$t('mblTelnoSuccess');
                })
                .catch((err) => {
                    this.alertDialog = true;
                    this.alertTitle = this.$t('save') + ' ' + this.$t('fail')
                    this.alertContent = err.response.data.errorMsg.split('<br>').join('\n')
                })
            })
        },
        modifyMode() {
            this.disabled = !this.disabled;
        },
        cancelInfo() {
            this.disabled = !this.disabled;
            this.$store.commit('setUser', this.userInfo); 
        },
        modifyInfo() {
            this.disabled = !this.disabled;
        },
        changeRcvryEmail() {
            this.dialogEmail = true;
        },
        emlCertifiedComplete(rcvryEmail) {
            this.rcvryEmail = rcvryEmail
            this.dialogRcvryEmail = true;
        },
        registerRcvryEmail() {
            const params = {
                rcvryEmlAddr: this.rcvryEmail,
            }
            const headers = {
                Authorization: 'Bearer ' + this.$cookies.get('user_token')
            }
            this.$store.dispatch('updateRcvryEmail', {params, headers})
            .then(() => {
                this.originRcvryEmail = this.rcvryEmail;
                this.isChange = true;
                this.dialogRcvryEmail = false;
            })
            .catch(err => {
                this.$set(this.user, this.userRcvryEmlAddr, "");
                this.dialogRcvryEmail = false;
            })
        },
        cancelRegisterRcvryEmail() {
            this.$store.commit('setUserRcvryEmlAddr', this.originRcvryEmail);
        },
        openNickNameDialog() {
            this.dialogNickName = true;
        },
        openSttsMsgCnDialog() {
            this.dialogSttsMsgCn = true;
        },
        changeNickName() {
            const params = {
                nickNm: this.changeNickNm,
            }
            const headers = {
                Authorization: 'Bearer ' + this.$cookies.get('user_token')
            }
            this.$store.dispatch('updateProfile', {params, headers})
            .then(() => {
                this.$store.commit('setUserNickNm', this.changeNickNm);
                this.dialogNickName = false;
                this.alertDialog = true;
                this.alertTitle = '닉네임 변경'
                this.alertContent = '닉네임이 변경되었습니다.'
            })
            .catch(err => {
                this.$set(this.user, this.userNickNm, "");
                this.dialogNickName = false;
            })
        },
        changeSttsMsgCn() {
            const params = {
                sttsMsgCn: this.changeSttsMsg,
            }
            const headers = {
                Authorization: 'Bearer ' + this.$cookies.get('user_token')
            }
            this.$store.dispatch('updateProfile', {params, headers})
            .then(() => {
                this.$store.commit('setUserSttsMsgCn', this.changeSttsMsg);
                this.dialogSttsMsgCn = false;
                this.alertDialog = true;
                this.alertTitle = '상태메세지 변경'
                this.alertContent = '상태메세지가 변경되었습니다.'
            })
            .catch(err => {
                this.$set(this.user, this.userSttsMsgCn, "");
                this.dialogNickName = false;
            })
        },
        deleteUser() {
            this.showDisagree = true;
            this.alertDialog = true;
            this.alertTitle = this.$t('secession') + " " + this.$t('check');
            this.alertContent = this.$t('secessionContent');
        },
        alertAgree() {
            // 이미 휴대폰 인증 사용자 입니다 일 경우 else if 처리 해주기
            if(this.alertTitle === this.$t('secession') + " " + this.$t('check')) {
                this.dialogCurPwd = true;
            } else if(this.alertTitle === this.$t('successSecession')) {
                const headers = {
                    Authorization: 'Bearer ' + this.$cookies.get('user_token')
                }
                this.$store.dispatch('deleteUser', {headers})
                .then(() => {
                    this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
                    this.$store.dispatch('resetUser');
                    location.href = '/';
                })
                .catch(()=>{

                });
            } else {
                this.alertDialog = false; 
            }
        },        
        confirmPw(){
            //새비밀번호, 비밀번호 확인 일치 판단
            return this.pswdCn == this.pswdCnConfirm || this.$t('passwordNotMatch')
        },
        changePwd() {
            //비밀번호 변경 버튼 클릭시
            this.showDialog = true;
            this.oldPwd = '';
            this.pswdCn = '';
            this.pswdCnConfirm = '';
            this.pwdConfirm = false;
        },
        checkPwd(pwd) {
            //비밀번호 일치 여부 판단
            const params = {
                pswdCn: pwd
            }
            const headers = {
                Authorization: 'Bearer ' + this.$cookies.get('user_token')
            }
            this.$store.dispatch('checkPwd', {params, headers})
            .then(() => {
                this.curPwd = '';
                this.dialogCurPwd = false;
                this.showDisagree = false;
                this.pwdConfirm = true;
                this.alertDialog = true;
                
                if(this.alertTitle === this.$t('secession') + " " + this.$t('check')) {
                    this.alertTitle = this.$t('successSecession');
                    this.alertContent = this.$t('successSecessionContent')
                } else {
                    this.alertTitle = this.$t('match');
                    this.alertContent = this.$t('passwordMatch');
                }
            })
            .catch(() => {
                this.curPwd = '';
                this.dialogCurPwd = false;
                this.showDisagree = false;
                this.pwdConfirm = false;
                this.alertDialog = true;
                this.alertTitle = this.$t('notMatch');
                this.alertContent = this.$t('passwordNotMatch');
                // this.alertTitle = this.$t('successSecession');
                // this.alertContent = this.$t('successSecessionContent')
            })
        },
        updatePwd() {
            //비밀번호 변경
            if(this.confirmPw() === true && this.pwdConfirm === true) {
                const params = {
                    emlAddr: this.user.emlAddr,
                    pswdCn: this.pswdCn,
                }
                const headers = {
                    Authorization: 'Bearer ' + this.$cookies.get('user_token')
                }
                this.$store.dispatch('updatePwd', {params, headers})
                .then(() => {
                    this.showDialog = false;
                    this.alertDialog = true;
                    this.alertTitle = this.$t('changePwdTitle') + ' ' + this.$t('success');
                    this.alertContent = this.$t('changePwdContent');
                })
                .catch(() => {
                    this.alertDialog = true;
                    this.alertTitle = this.$t('changePwdTitle') + ' ' + this.$t('fail');
                    this.alertContent = this.$t('notChangePwdContent');
                })
            }
        }
    },
    created() {
        if(this.rcvryEmail) this.isChange = true;
        this.originRcvryEmail = this.rcvryEmail;
        this.changeNickNm = this.nickName;
        this.changeSttsMsg = this.sttsMsgCn;
    }
}
</script>

<style scoped>
.my-page-label {
    font-weight: 500;
    font-size: 20px;
}

.search-form-input::v-deep {
    background-color: #F7F7F7;
}

.my-page-info-btns::v-deep .col-auto{
    padding: 0
}


.secession {
    display: flex;
    justify-content: flex-end;
}

.secession_btn:hover {
    text-decoration: underline;
}

.cancel::v-deep {
    border: 1px solid #0096EB !important;
}
.cancel::v-deep .v-btn__content{
    color: #0096EB !important;
}

.pwDialog::v-deep .v-dialog.com-dialog-card{
    background-color: #0096EB !important;
}

.scm-community-button-primary, .scm-community-button-outlined {
    width: 185px;
}
@media(max-width: 959px) {
    .scm-community-button-primary, .scm-community-button-outlined {
        width: 85px;
    }
}
</style>