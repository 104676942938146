<!--
   [2022/12/22 sh.hong]
   PoliceDetail.vue
   @note 시민 커뮤니티 웹 페이지 약관 화면
-->
<template>
    <v-container
        fluid
        class="px-10"
    >
        <v-card-title>
            <v-layout>
                <v-flex class="admin-terms-title">
                    {{ termTitle }}
                </v-flex>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <h3 
                class="admin-textarea"
                style="width: 100%;"
                v-html="termContent"
            />
        </v-card-text>
    </v-container>
</template>

<script>
import { terms } from '@/commons/resources.js';
import i18n from '@/locales/i18n';

export default{
    components: { },
    name:'PolicyDetail', 
    data(){
        return {
            termKeys: []
        };
    },
    computed: {
        policySn() {
            return this.$route.params.sn - 1;
        },
        policyKey() {
            return this.termKeys[this.policySn];
        },
        termTitle() {
            return i18n.t(terms[this.policyKey].title);
        }, 
        termContent() {
            return i18n.t(terms[this.policyKey].content);
        }
    },
    methods:{
        /**
         * @method selectTermKeys
         * @param {String} key 
         * @note 약관 상세글 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectTermKeys() {
            this.termKeys = Object.keys(terms);
        }
    },
    created() {
        this.selectTermKeys();
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>

</style>