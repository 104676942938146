<!--
   [2024/01/02 sb.hwang]
   PcDownloadService.vue
   @note 다운로드 화면
-->
<template>
    <v-container fluid>
        <v-img
            :src="require('@/assets/images/download_banner.png')"
            cover
            height="140"
            :alt="$t('altDownloadBannerImg')"
            class="scm-img-gradient-filter-primary full-width-in-container"
        />

        <download-service-app />
        <download-service-pc />
        <download-service-tool />
    </v-container>
</template>

<script>
import DownloadServiceApp from '@/components/download/DownloadServiceApp.vue';
import DownloadServicePc from '@/components/download/DownloadServicePc.vue';
import DownloadServiceTool from '@/components/download/DownloadServiceTool.vue';

export default {
    name: 'DownloadService',
    components: { 
        DownloadServiceApp,
        DownloadServicePc,
        DownloadServiceTool
    },
}
</script>

<style scoped>
</style>