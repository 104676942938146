<!--
   [2024/04/02 sb.hwang]
   VerifyEmail.vue
   @note 이메일 인증 화면
-->
<template>
    <v-app>
        <v-main class="admin-main-wrap d-flex align-center">
            <v-layout justify-center>
                <v-flex 
                    xs10
                    sm10
                    md7
                    xl7
                >
                    <v-img
                        :src="require('@/assets/images/scm-community-logo.svg')"
                        :alt="$t('altLogoImg')"
                        contain
                        max-width="200"
                        class="mb-3"
                    />
                    <v-card
                        tile
                        class="admin-verify-email-wrap"
                    >   
                        <div id="cert"/>
                        <div id="msg"/>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-main>
    </v-app>
</template>

<script type="module">
import { initializeApp } from "firebase/app";
import { applyActionCode, AuthErrorCodes, getAuth } from "firebase/auth";

export default {
    name: 'VerifyEmail',
    components: {
    },
    data() {
        return {
            // msg: '이메일 인증 화면'
        };
    },
    created(){
        this.$nextTick(() => {
            this.firebase();
        });
    },
    methods: {
        firebase() {
            // const modeText = document.getElementById("mode");
            const msgText = document.getElementById("msg");
            const certText = document.getElementById("cert");

            // Get the action to complete.
            const mode = this.getParameterByName("mode");
            // Get the one-time code from the query parameter.
            const actionCode = this.getParameterByName("oobCode");
            // (Optional) Get the continue URL from the query parameter if available.
            const continueUrl = this.getParameterByName("continueUrl");
            // (Optional) Get the language code if available.
            const lang = this.getParameterByName("lang") || "en";

            // Configure the Firebase SDK.
            // This is the minimum configuration required for the API to be used.
            const firebaseConfig = 
                process.env.VUE_APP_API_URL == "https://metaverse.eseoul.go.kr" ? 
                    // 메타버스 서울 실제 서비스(scm2) 설정 정보.
                    {
                        apiKey: "AIzaSyDN194YVTKT5y0YG68AqXoxSjj5MD1Vp0g",
                        authDomain: "scm2-fa8e3.firebaseapp.com",
                        projectId: "scm2-fa8e3",
                        storageBucket: "scm2-fa8e3.appspot.com",
                        messagingSenderId: "720749713520",
                        appId: "1:720749713520:web:f884fe9f19dca6b2e83cb5",
                        measurementId: "G-MCNRN32LLG"
                    } : 
                    // 테스트 프로젝트(scm2-test) 설정 정보.
                    {
                        apiKey: "AIzaSyD1BuajOYYhJIR6uboBe-e9wQB0cPJ57ug",
                        authDomain: "scm2-test.firebaseapp.com",
                        projectId: "scm2-test",
                        storageBucket: "scm2-test.appspot.com",
                        messagingSenderId: "913930691687",
                        appId: "1:913930691687:web:3f1eb62be13fc60d48e0c2",
                        measurementId: "G-SQKY8NT38L",
                    };


            const app = initializeApp(firebaseConfig);
            const auth = getAuth(app);

            // Handle the user management action.
            switch (mode) {
                case "resetPassword":
                    // modeText.innerText = "resetPassword";
                    break;
                case "recoverEmail":
                    // modeText.innerText = "recoverEmail";
                    break;
                case "verifyEmail":
                    this.handleVerifyEmail(
                        auth,
                        actionCode,
                        continueUrl,
                        lang
                    );
                    break;
                default:
                    certText.innerText = "인증 실패"
                    msgText.innerText = "유효하지 않거나 이미 사용된 링크입니다.";
                    break;
            }
        },
        getParameterByName(param) {
            const queryString = window.location.search.substring(1); // Remove the '?' at the start
            const params = queryString.split("&");
            for (let i = 0; i < params.length; i++) {
                const pair = params[i].split("=");
                if (pair[0] === param) {
                    return pair[1] === undefined
                        ? true
                        : decodeURIComponent(pair[1]);
                }
            }
            return null;
        },
        handleVerifyEmail(auth, actionCode, continueUrl, lang) {
            const msgText = document.getElementById("msg");
            const certText = document.getElementById("cert");
            // const actionCodeText = document.getElementById("actionCode");
            // const continueUrlText = document.getElementById("continueUrl");

            // actionCodeText.innerText = actionCode;
            // continueUrlText.innerText = continueUrl;

            applyActionCode(auth, actionCode)
                .then((resp) => {
                    certText.innerText = "인증 성공"
                    if (continueUrl.includes("/join-user")) {
                        msgText.innerText =
                            "인증이 완료되었습니다. 다음 단계를 진행해주세요.";
                    } else if (continueUrl.includes("/find-id")) {
                        msgText.innerText =
                            "인증이 완료되었습니다. 다음 단계를 진행해주세요.";
                    } else if (continueUrl.includes("/find-password")) {
                        msgText.innerText =
                            "인증이 완료되었습니다. 다음 단계를 진행해주세요.";
                    } else if (continueUrl.includes("/registerRecoverEmail")) {
                        msgText.innerText = "인증이 완료되었습니다.";
                    }
                    return;
                })
                .catch((error) => {
                    certText.innerText = "인증 실패"
                    switch (error.code) {
                        case "auth/invalid-action-code":
                            msgText.innerText =
                                "유효하지 않거나 이미 사용된 링크입니다.";
                            break;
                        default:
                            msgText.innerText = "기타 에러입니다.";
                            break;
                    }
                    return;
                });
        }
    }
}
</script>

<style scoped>
.admin-main-wrap {
    background: linear-gradient(107.56deg, #1EA7DA 0%, #824ADC 100%);
}

.admin-verify-email-wrap {
    background: #FBFBF8;
    box-shadow: 0px 10px 40px #33569C;
    margin: auto;
    padding: 80px 0;
    text-align: center;
}

.admin-logo {
    display: flex;
    justify-content: end;
    font-weight: bold;
}

#cert {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}
</style>