<!--
   [2022/08/01 sy.jeon]
   niceSuccess.vue
   @note 나이스 인증 성공 시 redirection
-->
<template>
    <v-container fluid>
        {{encData}}
    </v-container>
</template>

<script>

export default {
    name: 'NiceSuccess',
    components: {
    },
    data() {
        return {
            encData: ""
        };
    },
    created(){
        this.encData = this.$route.query.EncodeData;
        window.opener.resolve(this.encData);
        window.close();
    },
    methods: {
        
    }
}
</script>

<style scoped>
</style>
