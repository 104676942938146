import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store/store';
import vuetify from './plugins/vuetify';
import VueCookies from "vue-cookies";
import i18n from './locales/i18n';

Vue.config.productionTip = false;
// 패치 시 false 변경 ↓
Vue.config.devtools = true;

Vue.use(VueCookies);

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')