<template>
    <menu-detail
        ref="detail"
        :title="$t('contestList')"
        :hide-search-type="true"
        :hide-search-date="false"
        :hide-sort-type="false"
        :item-order="orderItem"
        @reverse="reverse"
    >
        <template v-slot:content>
            <contest-items-wrap
                id="list"
                :items="filterContests"
                item-image="pmtImgFileSn"
                item-title="ctstNm"
                item-date="ctstRegDt"
                item-component="ContestListItem"
                class="mt-3"
                @clickItem="routerDetail"
            />
        </template>
    </menu-detail>
</template>

<script>
import MenuDetail from '@/components/com/MenuDetail.vue';
import ContestItemsWrap from '@/components/contest/ContestItemsWrap.vue';
import { filterItems } from '@/commons/functions';
import { mapGetters } from 'vuex';

export default {
    name: 'ContestList',
    components: { 
        MenuDetail,
        ContestItemsWrap,
    },
    data() {
        return {
            orderItem: false
        }
    },
    computed: {
        ...mapGetters({
            contests: 'getContests',
            searchInfo: 'getSearchInfo',
            searchPayload: 'getSearchPayload'
        }),
        filterContests() { 
            // 공모전 필터
            return filterItems(this.order, {
                ...this.searchInfo,
                itemDate: 'ctstRegDt'
            });
        },
        order() {
            const contests = this.contests
            if(this.orderItem) {
                contests.sort((a, b) => {
                    return a.ctstRegDt.localeCompare(b.ctstRegDt);
                })
            } else {
                contests.sort((a, b) => {
                    return b.ctstRegDt.localeCompare(a.ctstRegDt);
                })
            }
            return contests;
        }
    },
    methods: {
        /**
         * @method routerDetail
         * @note 상세 조회 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerDetail(item) {
            this.$router.push({
                name: 'contestEntries',
                params: { sn: item.ctstSn }
            });
        },
        /**
         * @method getContestList
         * @note 공모전 목록을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        getContestList(){
            this.$store.dispatch('selectContestList')
            .catch(err => {
            });
        },
        reverse() {
            this.orderItem = !this.orderItem;
            this.order;
        }
    },
    created(){
        this.getContestList();
    }
}
</script>

<style scoped>
.row {
    margin: 0px;
}
</style>