<!--
   [2022/10/21 se.yoo]
   ContestEntries.vue
   @note 공모전 출품작 조회 화면
-->
<template>
    <v-container
        fluid
    >
        <contest-entries-info
            :contest="contest"
        />
        <contest-filter
            id="filter"
            type-key="awdType"
            :type-items="awdType"
            class="mt-6"
        />
        <contest-items-wrap
            id="list"
            :items="filterEntries"
            item-title="etrNm"
            item-image="etrTubnlFileSn"
            item-component="ContestEntriesItem"
            class="mt-3"
            @clickItem="routerDetail"
        />
        <alert-dialog
            v-model="alertError"
            title="오류 발생"
            content="데이터가 존재하지 않습니다. 메인 화면으로 돌아갑니다."
            @agree="routerHome"
        />
    </v-container>
</template>

<script>
import ContestEntriesInfo from '@/components/contest/ContestEntriesInfo.vue';
import ContestItemsWrap from '@/components/contest/ContestItemsWrap.vue';
import ContestFilter from '@/components/contest/ContestFilter.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';
import { filterItems } from '@/commons/functions';
import { awdType } from '@/commons/resources';
import { mapGetters } from 'vuex';

export default {
    name: 'ContestEntries',
    components: {
        ContestEntriesInfo,
        ContestFilter,
        ContestItemsWrap,
        AlertDialog
    },
    data() {
        return {
            alertError: false
        }
    },
    computed: {
        ...mapGetters({
            contest: 'getContest',
            entries: 'getEntries',
            searchInfo: 'getSearchInfo',
            searchPayload: 'getSearchPayload'
        }),
        filterEntriesByType() {
            // 출품작 종류 필터
            return ( 
                this.searchPayload.awdType ? 
                    this.entries.filter(contest => contest.awdNm == this.searchPayload.awdType)
                    : this.entries
            );
        },
        filterEntries() {
            // 출품작 필터
            return filterItems(this.filterEntriesByType, {
                ...this.searchInfo,
                itemTitle: 'etrNm',
                itemName: 'nickNm',
                itemDate: 'rcptDt'
            });
        },
        title() {
            return `${this.contest.ctstNm} ${this.$t('entries')}`;
        },
        ctstSn() {
            return this.$route.params.sn;
        },
        awdType(){
            return [
                { text: this.$t('all'), value: '' },
                { text: this.$t('grandAward'), value: awdType.GRAND },
                { text: this.$t('topAward'), value: awdType.TOP },
                { text: this.$t('excellenceAward'), value: awdType.EXCELLENCE },
                { text: this.$t('participationAward'), value: awdType.PARTICIPATION }
            ]
        }
    },
    methods: {
        /**
         * @method getContest
         * @note 공모전 상세 정보 불러오기 함수
         * @email gy.yang@naviworks.com
         */
        getContest(){
            this.$store.dispatch('selectContestDetail', this.ctstSn)
            .catch(err => {
                this.alertError = true;
            });
        },
        /**
         * @method getEntryList
         * @note 공모전 출품작 목록을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        getEntryList(){
            this.$store.dispatch('selectEntryList', this.ctstSn)
            .catch(err => {
            });
        },
        /**
         * @method routerDetail
         * @param {object} item 출품작 정보
         * @note 출품작 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        routerDetail(item) {
            this.$router.push({
                name: 'contestEntriesDetail',
                params: { 
                    ctstSn: this.ctstSn,
                    etrSn: item.etrSn
                }
            });
        },
        /**
         * @method routerHome
         * @note 메인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerHome() {
            this.$router.push({ name: 'communityHome' });
        }
    },
    created(){
        this.getContest();
        this.getEntryList();
    }
}
</script>

<style scoped>
</style>