/**
 * [2023/10/05 sb.hwang]
 * loading.js
 * @note 로딩 상태 store
 */
const state =
{
    loadingStatus: true
};
 
const actions=
{

};
 
const getters=
{
    
};
 
const mutations= 
{
    startSpinner(state){
        state.loadingStatus = true;
    },
    endSpinner(state){
        state.loadingStatus = false;
    },
};

export default
{
    state, actions, getters, mutations
};