<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoCert.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(본인인증)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc-wrap"
            v-html="agreeRepresentative"
        />
        <v-container class="px-0 px-sm-3">
            <div
                class="d-flex mt-3"
            >
                <v-checkbox
                    v-model="selectedTerms"
                    hide-details
                    class="community-terms-checkbox"
                    title="약관 동의 체크 박스_법정 대리인 동의"
                >
                    <template v-slot:label>
                        {{ $t('agreeRepresentativeTitle') }}
                        <span class="grey--text">
                            {{ $t('essential') }}
                        </span>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-btn
                    text
                    icon
                    aria-label="약관 자세히 보기 버튼_법정 대리인 동의"
                    @click="selectTerms"
                >
                    <v-icon
                        size="30"
                        color="#6C6C6C"
                        aria-label="약관 자세히 보기 버튼 아이콘_법정 대리인 동의"
                    >
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
            </div>
            

            <v-form ref="form">
                <div class="community-login-input-label mt-9">
                    {{ $t('representative') }}
                </div>
                <div 
                    class="mt-5" 
                    style="display:grid; grid-template-columns: 10fr 1fr 10fr;"
                >
                    <span>
                        <div class="community-login-desc mb-3">
                            {{ $t('representativeName')}}
                        </div>
                        <v-text-field
                            v-model="representativeNm"
                            :rules="[rules.required]"
                            dense
                            outlined
                            hide-details
                            height="40"
                            :placeholder="$t('representativeName')"
                            class="search-form-input mt-5"
                        />
                    </span>
                    <div/>
                    <span>
                        <div class="community-login-desc mb-3">
                            {{ $t('representativeTelno')}}
                        </div>
                        <v-text-field
                            v-model="representativeTelno"
                            :rules="[rules.required, rules.phone]"
                            dense
                            outlined
                            hide-details
                            height="40"
                            :placeholder="$t('representativeTelno')"
                            class="search-form-input mt-5"
                        />
                    </span>
                </div>
                <div class="community-login-desc mt-3 mb-3">
                    {{ $t('customRepresentative')}}
                </div>
                <div 
                    class="mt-5"
                    style="display:grid; grid-template-columns: 10fr 1fr 10fr;"
                >
                    <v-select
                        v-model="representative"
                        :items="representativeItems"
                        :menu-props="{
                            offsetY: true
                        }"
                        :title="$t('type')"
                        :placeholder="$t('customRepresentative')"
                        dense
                        outlined
                        hide-details
                        height="29"
                        class="search-form-input"
                    />
                    <div/>
                    <v-text-field
                        v-if="representative == '기타' || representative == 'Etc.'"
                        v-model="customRepresentative"
                        :rules="[rules.required]"
                        dense
                        outlined
                        hide-details
                        height="40"
                        :placeholder="$t('customRepresentative')"
                        class="search-form-input"
                    />
                </div>
                <div class="community-login-input-label mt-9">
                    {{ $t('applicant') }} 
                </div>
                <div 
                    class="mt-5" 
                    style="display:grid; grid-template-columns: 10fr 1fr 10fr;"
                >
                    <span>
                        <div class="community-login-desc mb-3">
                            {{ $t('representativeName')}}
                        </div>
                        <v-text-field
                            v-model="minorNm"
                            :rules="[rules.required]"
                            dense
                            outlined
                            hide-details
                            height="40"
                            :placeholder="$t('representativeName')"
                            class="search-form-input mt-5"
                        />
                    </span>
                    <div/>
                    <span>
                        <div class="community-login-desc mb-3">
                            {{ $t('representativeTelno')}}
                        </div>
                        <v-text-field
                            v-model="minorTelno"
                            :rules="[rules.required, rules.phone]"
                            dense
                            outlined
                            hide-details
                            height="40"
                            :placeholder="$t('representativeTelno')"
                            class="search-form-input mt-5"
                        />
                    </span>
                </div>
                <div class="community-login-desc mt-3 mb-3">
                    {{ $t('dateOfBirth') }}
                </div>
                <div 
                    class="mt-5"
                    style=""
                >
                    <v-text-field
                        ref="pickerField"
                        :value="brdt"
                        dense
                        outlined
                        readonly
                        hide-details
                        height="29"
                        placeholder="YYYY-MM-DD"
                        title="기간"
                        class="search-form-input mt-0 pt-0"
                        @focus.stop="(datePicker = true)"
                    />
                    <v-date-picker
                        v-show="datePicker"
                        ref="picker"
                        v-model="brdt"
                        :rules="[rules.required]"
                        :header-date-format="headerDateFormat"
                        no-title
                        scrollable
                        :max="today"
                        :min="under14Day"
                        color="primary"
                        class="mt-1 search-date-picker"
                        @input="(datePicker = false)"
                    />
                </div>
            </v-form>
        </v-container>
        <v-row
            no-gutters
            class="cert-actions justify-end"
        >
            <v-col
                cols="11"
                class="mb-3"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="본인인증 버튼"
                    title="새 창 열림"
                    :disabled="!selectedTerms || representative == '' || ((representative == '기타' || representative == 'Etc.') && customRepresentative == '') || representativeNm == '' || representativeTelno == '' || brdt == '' || minorNm == '' || minorTelno == ''"
                    @click="fnPopup"
                >
                    {{ $t('representativeNice') }}
                </v-btn>
            </v-col>
        </v-row>
        <nice-dialog ref="niceModule"/>
        <alert-dialog
            v-model="errorSignUp"
            :title="errorTitle"
            :content="errorContent"
            agree="확인"
        /> 
        <community-email-certified-dialog
            v-model="dialogEmail"
            @emlCertifiedComplete="emlCertifiedComplete"
        />
        <com-dialog
            v-model="dialogTerms"
            closable
            width="1200"
            :title="termsTitle"
        >
            <template v-slot:content>
                <v-card
                    ref="terms"
                    tile
                    outlined
                    class="mt-4 mt-sm-6 pa-3 terms-content"
                >
                    <span v-html="termsContent" />
                </v-card>
            </template>
        </com-dialog>
    </div>
</template>

<script>
import AlertDialog from '../com/AlertDialog.vue'
import NiceDialog from '@/components/nice/NiceDialog.vue';
import CommunityEmailCertifiedDialog from '@/components/login/CommunityEmailCertifiedDialog.vue';
import ComDialog from '../com/ComDialog.vue';
import { rules } from '@/commons/resources.js';
import { signUpStep, findStep } from '@/commons/resources.js';
import { accountMenu, accountMenuInfo } from '@/commons/resources';
import i18n from '@/locales/i18n';

export default {
    name:'CommunitySignUpInfoUnderAge',
    components: {
        NiceDialog,
        AlertDialog,
        CommunityEmailCertifiedDialog,
        ComDialog
    },
    props:{
        menu: {
            type: String,
            default: accountMenu.SIGN_UP
        }
    },
    data() {
        return {
            rules,
            accountMenu,
            errorSignUp: false,
            errorTitle: '',
            errorContent: '',
            dialogEmail: false,
            emlCertified: false,
            mobileCertifed: false,
            dialogTerms: false,
            selectedTerms: false,
            disabled: true,
            representative: '',
            customRepresentative: '',
            representativeNm: '',
            representativeTelno: '',
            minorNm: '',
            minorTelno: '',
            brdt: '',
            datePicker: false,
            today: '',
        };
    },
    computed: {
        signUpUser() {
            return this.$store.getters.getSignUpUser;
        },
        menuInfo() {
            return accountMenuInfo.find(item => 
                item.value === this.menu
            );
        },
        agreeRepresentative() {
            return i18n.t('agreeRepresentative').replaceAll("\n", "<br>");
        },
        menuText() {
            return this.menuInfo? i18n.t(this.menuInfo.text) : '';
        },
        termsTitle() {
            return i18n.t('agreeRepresentativeTitle');
        },
        termsContent() {
            return i18n.t('agreeRepresentativeContent');
        },
        under14Day() {
            const currentDate = new Date();
            return new Date(currentDate.getFullYear() - 14, currentDate.getMonth(), currentDate.getDate()+2).toISOString().substr(0, 10);
        },
        representativeItems() {
            return [i18n.t('father'), i18n.t('mother'), i18n.t('grandfather'), i18n.t('grandmother'), i18n.t('etc')]
        }
    },
    methods:{
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            this.$emit('nextPage', signUpStep.InfoCert);
        },
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
         nextPageUnder14(){
            this.$emit('nextPage', signUpStep.InfoCert);
        },
        /**
         * @method fnPopup
         * @note 본인인증 함수
         * @email gy.yang@naviworks.com
         */
        fnPopup(){
            const validate = this.$refs.form.validate();
            if (validate) {
                this.$refs.niceModule.fnPopup()
                    .then((data) => {
                        let params = {};
                        if(this.menu == accountMenu.SIGN_UP) {
                            params = {
                                enc: data,
                                name: this.representativeNm,
                                mobileNo: this.representativeTelno,
                                relation: this.representative,
                            };
                            if (this.representative == '기타' || this.representative == 'Etc.') {
                                params.relation = this.customRepresentative
                            }
                            this.$store.dispatch('checkOver', params)
                            .then((res) => {
                                if (!res.result.isOver19) {
                                    this.errorSignUp = true;
                                    this.errorTitle = i18n.t('error');
                                    this.errorContent = i18n.t('checkBirth');
                                } else if (!res.result.nameTf) {
                                    this.errorSignUp = true;
                                    this.errorTitle = i18n.t('error');
                                    this.errorContent = i18n.t('checkName');
                                } else if (!res.result.mobileNoTf) {
                                    this.errorSignUp = true;
                                    this.errorTitle = i18n.t('error');
                                    this.errorContent = i18n.t('checkPhone');
                                } else {
                                    this.$store.commit('setRepresentative', params);
                                    this.$store.commit('setMinor', { name: this.minorNm, mobileNo: this.minorTelno, birthDate: this.brdt.replace(/-/g, '') });
                                    this.$emit('nextPage', signUpStep.Terms);
                                }
                            })
                            .catch((err) => {
                                this.errorSignUp = true;
                                this.errorTitle = i18n.t('error');
                                this.errorContent = i18n.t('askAdmin');
                            })
                        }
                    })
                    .catch((e) => {
                        this.encData = e;
                        alert("실패");
                    })
            } else {
                this.errorSignUp = true;
                this.errorTitle = i18n.t('error');
                this.errorContent = i18n.t('askAdmin');
            }
        },
        /**
         * @method emlCertifiedComplete
         * @note 이메일 본인인증이 완료된 후 실행되는 함수
         * @email jake.jeon@naviworks.com
         */
        emlCertifiedComplete() {
            this.emlCertified = true;
            this.nextPage();
        },
        /**
         * @method emailPopup
         * @note 본인인증 (이메일) 함수
         * @email jake.jeon@naviworks.com
         */
        emailPopup(){
            this.dialogEmail = true;
        },
        /**
         * @method goLogin
         * @note 로그인 화면으로 돌아가는 함수
         * @email sh.hong@naviworks.com
         */
        goLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        },
        selectTerms() {
            this.dialogTerms = true;
        },
        /**
         * @method deleteDate
         * @note 날짜를 지우는 함수
         * @email gy.yang@naviworks.com
         */
        deleteDate() {
            this.brdt = '';
            this.datePicker = false;
        },
        /**
         * @method headerDateFormat
         * @param {string} date 날짜
         * @returns {string} 년-월
         * @note 달력 헤더에 나타낼 문자 반환 함수
         * @email se.yoo@naviworks.com
         */
         headerDateFormat(date){
            let dateSplit = date.split('-');
            if(1 === dateSplit.length){
                return dateSplit[0];
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
    },
    created() {
        this.today = new Date().toISOString().substr(0, 10);
    },
}
</script>

<style lang="scss" scoped>
.cert-actions {
    margin-top: 10px;
}

.community-login-desc-wrap {
    border: 1px solid #c6d1dc;
    padding: 20px;
    background: #f8faff;
    padding-top: 30px;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) { 
    .cert-actions {
        margin-top: 210px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .cert-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}

.community-terms-checkbox {
    margin-top: 0;
    padding-top: 0;
    align-items: center;

    &::v-deep .v-label {
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #14110F;
        margin-left: 13px;
    }

    &::v-deep.v-input--checkbox .v-input--selection-controls__input {
        margin: 0;
    }

    &::v-deep.v-input--checkbox .v-icon::before {
        content: '';
        width: 100%;
        height: 100%;
        min-width: 24px;
        min-height: 24px;
        display: block;
        background-size: 100%;
        background-position: center;
        background-image: url('~@/assets/images/unchecked.svg');
    }

    &::v-deep.v-input--checkbox.v-input--is-label-active .v-icon::before {
        background-image: url('~@/assets/images/checked.svg');
    }
}

.terms-content {
    height: calc(100vh - 80px - 24px - 36px);
    max-height: 470px;
    overflow-y: auto;
    white-space: pre-wrap;
}

.terms-actions {
    margin-top: 60px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .community-terms-checkbox {
        &::v-deep .v-label {
            font-size: 16px;
            margin-left: 1rem;
        }

        &.header::v-deep .v-label {
            font-size: 20px;
        }
    }
    
    .terms-content {
        max-height: calc(100vh - 32px - 24px - 36px);
        height: calc(100vh - 32px - 24px - 36px);
    }

    .terms-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>