<!--
   [2022/10/06 se.yoo]
   CommunityHome.vue
   @note 시민 커뮤니티 웹 페이지 홈 화면
-->
<template>
    <v-container fluid>
        <community-home-banner-link/>
        <community-home-banner-main
            class="full-width-in-container home-section"
        />
        <community-home-introduction />
        <community-home-banner-youtube/>
        <community-home-banner-down class="full-width-in-container" />
        <community-home-quickmenu class="home-section" />

        <popup-dialog
            v-model="openDialog"
            closable
            checkable
            closable-btn
            :title="$t('endTitle')"
            :content="$t('endContent')"
            :label="$t('stopWatching')"
            :footer="$t('check')"
        />
    </v-container>
</template>

<script>
import PopupDialog from '@/components/com/PopupDialog.vue';
import CommunityHomeBannerMain from '@/components/home/CommunityHomeBannerMain.vue';
import CommunityHomeBannerLink from '@/components/home/CommunityHomeBannerLink.vue';
import CommunityHomeIntroduction from '@/components/home/CommunityHomeIntroduction.vue';
import CommunityHomeBannerYoutube from  '@/components/home/CommunityHomeBannerYoutube.vue';
import CommunityHomeBannerDown from '@/components/home/CommunityHomeBannerDown.vue';
import CommunityHomeQuickmenu from '@/components/home/CommunityHomeQuickmenu.vue';
import { getImageSrc } from '@/commons/functions.js';

export default {
    name: 'CommunityHome',
    components: { 
        CommunityHomeBannerMain,
        CommunityHomeBannerLink,
        CommunityHomeIntroduction,
        CommunityHomeBannerYoutube,
        CommunityHomeBannerDown,
        CommunityHomeQuickmenu,
        PopupDialog
    },
    data() {
        return{
            getImageSrc,
            openDialog: false,
        }
    },
    created() {
        this.openPopup();
    },
    methods: {
        getCookie(name) {
            let cookie = document.cookie;

            if(document.cookie !== '') {
                let cookie_array = cookie.split('; ');
                for(let i in cookie_array) {
                    let cookie_name = cookie_array[i].split('=');

                    if(cookie_name[0] === name) {
                        return cookie_name[1];
                    }
                }
            }

            return ;
        },
        openPopup() {
            let cookieCheck = this.getCookie("popupYN");
            if(cookieCheck !== 'N') {
                this.openDialog = true;
            }
        }
    }
}
</script>

<style scoped>
.home-section {
    height: 76vh;
    min-height: 600px;
}
::v-deep .home-section-content {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #303030;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    ::v-deep .home-section-content {
        font-size: 16px;
    }
}
</style>