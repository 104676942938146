<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoCert.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(본인인증)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            <template v-if="menu == accountMenu.SIGN_UP">
                {{ $t('personaAuthenticationSeoul') }}
            </template>
            <template v-else>
                {{  $t('personaAuthentication') }}
            </template>
        </p>
    
        <v-row
            no-gutters
            class="cert-actions justify-end"
        >
            <v-col
                cols="7"
                class="mb-3"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="본인인증 버튼"
                    title="새 창 열림"
                    @click="fnPopup"
                >
                    {{ $t('nice') }}
                </v-btn>
            </v-col>
            <v-col
                cols="7"
                class="mb-3"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="본인인증 버튼"
                    title="새 창 열림"
                    @click="emailPopup"
                >
                    {{ $t('emailPass') }}
                </v-btn>
            </v-col>
        </v-row>
        <nice-dialog ref="niceModule"/>
        <alert-dialog
            v-model="errorSignUp"
            :title="errorTitle"
            :content="errorContent"
            agree="확인"
            @agree="routerSignup"
        /> 
        <community-email-certified-dialog
            v-model="dialogEmail"
            :menu="menu"
            @emlCertifiedComplete="emlCertifiedComplete"
        />
    </div>
</template>

<script>
import AlertDialog from '../com/AlertDialog.vue'
import NiceDialog from '@/components/nice/NiceDialog.vue';
import CommunityEmailCertifiedDialog from '@/components/login/CommunityEmailCertifiedDialog.vue';
import { signUpStep, findStep } from '@/commons/resources.js';
import { accountMenu, accountMenuInfo } from '@/commons/resources';
import i18n from '@/locales/i18n';

export default {
    name:'CommunitySignUpInfoUnderAgeCert',
    components: {
        NiceDialog,
        AlertDialog,
        CommunityEmailCertifiedDialog
    },
    props:{
        menu: {
            type: String,
            default: accountMenu.SIGN_UP
        }
    },
    data() {
        return {
            accountMenu,
            errorSignUp: false,
            errorTitle: '',
            errorContent: '',
            dialogEmail: false,
            emlCertified: false,
            mobileCertifed: false,
        };
    },
    computed: {
        signUpUser() {
            return this.$store.getters.getSignUpUser;
        },
        menuInfo() {
            return accountMenuInfo.find(item => 
                item.value === this.menu
            );
        },
        menuText() {
            return this.menuInfo? i18n.t(this.menuInfo.text) : '';
        }
    },
    methods:{
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            if(this.menu == accountMenu.SIGN_UP && this.mobileCertified) {
                this.$emit('nextPage', signUpStep.InfoId);
            } else if (this.menu == accountMenu.SIGN_UP && this.emlCertified) {
                this.$emit('nextPage', signUpStep.InfoAge);
            } else if(this.menu == accountMenu.SEARCH_ID) {
                this.$emit('nextPage', findStep.FindId);
            } else if(this.menu == accountMenu.SEARCH_PW) {
                this.$emit('nextPage', findStep.FindPwd);
            }
        },
        /**
         * @method fnPopup
         * @note 본인인증 함수
         * @email gy.yang@naviworks.com
         */
        fnPopup(){
            this.$refs.niceModule.fnPopup()
            .then((data) => {
                let params = {};
                if(this.menu == accountMenu.SIGN_UP) {
                    params = {
                        enc: data,
                        name: this.$store.state.user.minor.name,
                        mobileNo: this.$store.state.user.minor.mobileNo,
                        birthDate: this.$store.state.user.minor.birthDate,
                    };
                    this.$store.dispatch('checkUnder', params)
                    .then((res) => {
                        if (!res.result.isUnder14) {
                            this.errorSignUp = true;
                            this.errorTitle = i18n.t('error');
                            this.errorContent = '생년 월일을 확인해 주세요.'
                        } else if (!res.result.nameTf) {
                            this.errorSignUp = true;
                            this.errorTitle = i18n.t('error');
                            this.errorContent = '이름을 확인해 주세요.'
                        } else if (!res.result.mobileNoTf) {
                            this.errorSignUp = true;
                            this.errorTitle = '오류 발생';
                            this.errorContent = '전화번호를 확인해 주세요.'
                        } else {
                            this.$store.commit('setMinor', { name: this.mnorNm, mobileNo: this.minorTelno, birthDate: this.brdt });
                            this.$store.commit('setUser', { enc: data });
                            this.$emit('nextPage', signUpStep.InfoId);
                        }
                    })
                    .catch((err) => {
                        this.errorSignUp = true;
                        this.errorTitle = '이미 등록된 사용자';
                        this.errorContent = '확인 버튼 클릭 시 로그인 페이지로 이동합니다.'
                    })
                }
            })
            .catch( (e) => {
                this.encData = e;
                alert("실패");
            })
        },
        emailPopup(){
            this.dialogEmail = true;
        },
        /**
         * @method routerSignup
         * @note 회원가입 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerSignup(){
            this.$router.push({
                name: 'communitySignUp'
            })
        },
        /**
         * @method emlCertifiedComplete
         * @note 이메일 본인인증이 완료된 후 실행되는 함수
         * @email jake.jeon@naviworks.com
         */
        emlCertifiedComplete() {
            this.emlCertified = true;
            this.nextPage();
        },
    }
}
</script>

<style scoped>
.cert-actions {
    margin-top: 210px;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) { 
    .cert-actions {
        margin-top: 210px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .cert-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>