<template>
    <v-overlay 
        v-if="loading"
        class="loadingOverLay" >
        <div class="spinner">
            <div/>
            <div/> 
            <div/>
        </div>
    </v-overlay>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true 
        }
    } 
}
</script>
<style>
.loadingOverLay {
    z-index: 2001 !important;
}

.spinner {
    z-index:500;
    display: inline-block;
    position: absolute;
    width: 66px;
    height: 66px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.spinner div {
    display: inline-block;
    position: absolute;
    left: -11px;
    width: 22px;
    border-radius: 50%;
    background: rgb(0, 0, 0);
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.spinner div:nth-child(1) {
    left: -11px;
    animation-delay: -0.24s;
    background: rgb(0, 150, 235);
}
.spinner div:nth-child(2) {
    left: 22px;
    animation-delay: -0.12s;
    background: rgb(112, 119, 237);

}
.spinner div:nth-child(3) {
    left: 55px; 
    animation-delay: 0s;
    background: rgb(131, 0, 235);

}
@keyframes spinner {
    0% {
        top: 6px;
        height: 51px;
    }
    50%, 100% {
        top: 19px;
        height: 25px;
    }
}
</style>