import axios from 'axios';
import { store } from '../store/store.js';

const createAxiosService = baseURL => {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        timeout: 7000,
    });

    instance.interceptors.request.use(
        config => {
            store.commit('startSpinner');
            return config;
        },
        error => {
            store.commit('startSpinner');
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        response => {
            store.commit('endSpinner');
            return response;
        },
        error => {
            store.commit('endSpinner');
            
            const code = error.code;
            if(code === 'ECONNABORTED') {
                window.location.href = "/404";
            }
            return Promise.reject(error);
        }
    );

    return instance;
}

export const axiosService = createAxiosService();
