/**
 * [2024/01/25 sb.hwang]
 * youtube.js
 * @note 유튜브링크목록 store
 */

import { axiosService } from "@/commons/api";

const state =
{
    sn: null,
    ytbCtgryMm: '',

    ytbVdoUrlAddr: '',
    ytbVdoDscpCn: '',
};
 
const actions=
{
    /**
     * @method selectYoutubeList
     * @returns {Promise} 결과 데이터
     * @param {object} context 컨텍스트 객체
     * @note 유튜브 목록을 반환하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectYoutubeList({}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`scm/youtube/link`)
            .then(res => {
                resolve(res.data.result);
            }).catch(err => {
                reject(err);
            });
        });
    },

};
 
const getters=
{
    
};
 
const mutations= 
{
};

export default
{
    state, actions, getters, mutations
};