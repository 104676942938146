/**
 * [2022/08/01 se.yoo]
 * beforeInfo.js
 * @note 사전 정보 store
 */
import { axiosService } from "@/commons/api";

const state =
{
    
};

const actions=
{
    /**
     * @method selectFileInfo
     * @returns {Promise} 결과 데이터
     * @param {object} context 컨텍스트 객체
     * @param {object} params 파일 정보
     * @note 파일 정보를 가져오는 함수
     * @email sh.hong@naviworks.com
     */
    selectFileInfo({}, fileSn) {
    return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`/scm/storage`, { params: fileSn })
            .then(res => {
                resolve(res.data.fileInfo);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method selectStaticInfo
     * @returns {Promise} 결과 데이터
     * @param {object} context 컨텍스트 객체
     * @param {object} params 파일 정보
     * @note 스태틱 파일 정보를 가져오는 함수
     * @email jake.jeon@naviworks.com
     */
    selectStaticInfo({}, fileSn) {
    return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`/scm/static`, { params: fileSn })
            .then(res => {
                resolve(res.data.staticFileInfo);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method downloadPc
     * @returns {Promise} 결과 데이터
     * @param {object} context 컨텍스트 객체
     * @param {object} params 파일 정보
     * @note PC버전 다운로드 로그를 기록하는 함수
     * @email jake.jeon@naviworks.com
     */
    downloadPc({}) {
    return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/log/desktop/download`)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};

const getters=
{
    
};

const mutations= 
{

};

export default
{
    state, actions, getters, mutations
};