<!--
   [2022/12/14 se.yoo]
   CommunityFindId.vue
   @note 아이디 찾기 결과 컴포넌트
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            <template v-if="success">
                {{ $t('findId') }}<br />
                <span class="primary--text">
                    {{ emlAddr }}
                </span>
            </template>
            <template v-else>
                {{ $t('notSignUp') }}
            </template>
        </p>
        <v-row
            no-gutters
            class="find-actions justify-end"
        >
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    v-if="success"
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    @click="routerLogin"
                >
                    {{ $t('login') }}
                </v-btn>
                <v-btn
                    v-else
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    @click="routerSignup"
                >
                    {{ $t('signUp') }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'CommunityFindId',
    components: {
    },
    data() {
        return {
            success: false,
            emlAddr: '',
        };
    },
    computed: {
        user() {
            return this.$store.getters.getSignUpUser;
        },
        rcvryEmlAddr() {
            return this.$store.getters.getRcvryEml;
        }
    },
    methods: {
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        },
        /**
         * @method routerSignup
         * @note 회원가입 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerSignup(){
            this.$router.push({
                name: 'communitySignUp'
            })
        }
    },
    created() {
        if(this.user.enc != '') {
            this.$store.dispatch('findId', this.user)
            .then(res => {
                this.emlAddr = res.emlAddr;
                this.success = true;
            }).catch(err => {
                this.success = false;
            });
        } else if(this.rcvryEmlAddr != '') {
            this.$store.dispatch('findIdRcvryEmail', this.rcvryEmlAddr)
            .then((res) => {
                this.emlAddr = res.emails[0];
                this.success = true;
            }).catch(err => {
                this.success = false;
            })
        } else {
            this.success = false;
        }
    }
}
</script>

<style scoped>
.find-actions {
    margin-top: 210px;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) { 
    .find-actions {
        margin-top: 210px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .find-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>