const state =
{
    menuNm: '',
    type: '',
    keyword: '',
    date: [],
    page: 1,
    payload: {} 
};

const actions=
{
    /**
     * @method resetSearch
     * @param {object} context 컨텍스트 객체
     * @note 검색 state를 초기화 하는 함수
     * @email se.yoo@naviworks.com
     */
    resetSearch(context) {
        const emptySearch = {};

        context.commit('setSearch', emptySearch);
    },
    /**
     * @method checkSearchReset
     * @param {Object} context 컨텍스트 객체
     * @param {String} routerNm 라우터 이름
     * @note 메뉴를 확인하고 검색 정보를 초기화 하는 함수
     * @email se.yoo@naviworks.com
     */
    checkSearchReset(context, routerNm) {
        if(!context.getters.getMenuNm || !routerNm.includes(context.getters.getMenuNm)) {
            const emptySearch = {};

            const menuNm = routerNm.replace('Detail', '').replace('Edit', '');
            context.commit('setSearch', emptySearch);
            context.commit('setSearchMenuNm', menuNm);
        }
    }
};

const getters=
{
    getMenuNm: function(state) {
        return state.menuNm;
    },
    getSearchInfo: function(state) {
        return {
            type: state.type,
            keyword: state.keyword,
            date: state.date,
            payload: state.payload
        };
    },
    getSearchPage: function(state) {
        return state.page;
    },
    getSearchPayload: function(state) {
        return _.cloneDeep(state.payload);
    }
};

const mutations= 
{
    setSearch(state, search) {
        state.menuNm = search.menuNm || '';
        state.type = search.type || '';
        state.keyword = search.keyword || '';
        state.date = search.date || [];
        state.page = search.page || 1;
        state.payload = search.payload || {};
    },
    setSearchMenuNm(state, menuNm) {
        state.menuNm = menuNm;
    },
    setSearchType(state, type) {
        state.type = type;
    },
    setSearchKeyword(state, keyword) {
        state.keyword = keyword;
    },
    setSearchDate(state, date) {
        state.date = date;
    },
    setSearchPage(state, page) {
        state.page = page;
    },
    setSearchPayload(state, payload) {
        state.payload = payload;
    }
};

export default
{
    state, actions, getters, mutations
};