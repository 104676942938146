import { render, staticRenderFns } from "./BlackIconBtn.vue?vue&type=template&id=01cf438c&scoped=true&"
import script from "./BlackIconBtn.vue?vue&type=script&lang=js&"
export * from "./BlackIconBtn.vue?vue&type=script&lang=js&"
import style0 from "./BlackIconBtn.vue?vue&type=style&index=0&id=01cf438c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01cf438c",
  null
  
)

export default component.exports