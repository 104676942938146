import { render, staticRenderFns } from "./BoardNotice.vue?vue&type=template&id=504a0454&scoped=true&"
import script from "./BoardNotice.vue?vue&type=script&lang=js&"
export * from "./BoardNotice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504a0454",
  null
  
)

export default component.exports