<template>
    <v-container class="pa-0">
        <div class="privacy-policy-items mb-3">
            <privacy-policy-item 
                v-for="(item, i) in privacyPolicyItems"
                :key="i"
                :class="[`privacy-policy-item-${i}`]"
                :item="item" 
            />
            </div>
    </v-container>
</template>

<script>
import PrivacyPolicyItem from './PrivacyPolicyItem.vue';

export default {
    name: 'PrivacyPolicyItems',
    components: {
        PrivacyPolicyItem
    },
    data() {
        return {
            privacyPolicyItems: [
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_1.png')
                    ],
                    title: 'termsPrivacyTitle1',
                    content: 'termsPrivacyContent1',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_2.png'),
                        require('../../assets/images/privacy_labeling/labeling_2_2.png'),
                    ],
                    title: 'termsPrivacyTitle2',
                    content: 'termsPrivacyContent2',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_3.png'),
                    ],
                    title: 'termsPrivacyTitle3',
                    content: 'termsPrivacyContent3',
                },
                {
                    imgUrls: [
                    ],
                    title: 'termsPrivacyTitle4',
                    content: 'termsPrivacyContent4',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_5.png'),
                    ],
                    title: 'termsPrivacyTitle5',
                    content: 'termsPrivacyContent5',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_6.png'),
                    ],
                    title: 'termsPrivacyTitle6',
                    content: 'termsPrivacyContent6',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_7.png'),
                    ],
                    title: 'termsPrivacyTitle7',
                    content: 'termsPrivacyContent7',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_8.png'),
                        require('../../assets/images/privacy_labeling/labeling_8_2.png'),
                    ],
                    title: 'termsPrivacyTitle8',
                    content: 'termsPrivacyContent8',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_9.png'),
                    ],
                    title: 'termsPrivacyTitle9',
                    content: 'termsPrivacyContent9',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_10.png'),
                    ],
                    title: 'termsPrivacyTitle10',
                    content: 'termsPrivacyContent10',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_11.png'),
                    ],
                    title: 'termsPrivacyTitle11',
                    content: 'termsPrivacyContent11',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_12.png'),
                    ],
                    title: 'termsPrivacyTitle12',
                    content: 'termsPrivacyContent12',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_13.png'),
                    ],
                    title: 'termsPrivacyTitle13',
                    content: 'termsPrivacyContent13',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_14.png'),
                    ],
                    title: 'termsPrivacyTitle14',
                    content: 'termsPrivacyContent14',
                },
                {
                    imgUrls: [
                        require('../../assets/images/privacy_labeling/labeling_15.png'),
                    ],
                    title: 'termsPrivacyTitle15',
                    content: 'termsPrivacyContent15',
                },
            ]
        }
    }

}
</script>

<style scoped>
</style>