<template>
    <v-container class="pa-0">
        <div class="privacy-table">
            <div class="privacy-table-title py-2">{{ $t('privacyLabelingTable') }}</div>
            <div class="privacy-table-items">
                <privacy-labeling-item 
                    v-for="(item, i) in privacyLabelingItems"
                    :key="i"
                    :item="item" 
                />
            </div>
        </div>
    </v-container>
</template>

<script>
import PrivacyLabelingItem from './PrivacyLabelingItem.vue';

export default {
    name: 'PrivacyLabelingTable',
    components: {
        PrivacyLabelingItem
    },
    data() {
        return {
            privacyLabelingItems: [
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_1.png'),
                    title: 'privacyLabelingTitle1',
                    content: 'privacyLabelingContent1',
                },
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_2.png'),
                    title: 'privacyLabelingTitle2',
                    content: 'privacyLabelingContent2',
                },
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_3.png'),
                    title: 'privacyLabelingTitle3',
                    content: 'privacyLabelingContent3',
                },
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_4.png'),
                    title: 'privacyLabelingTitle4',
                    content: 'privacyLabelingContent4',
                },
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_5.png'),
                    title: 'privacyLabelingTitle5',
                    content: 'privacyLabelingContent5',
                },
                {
                    iconUrl: require('../../assets/images/privacy_labeling/main_labeling_6.png'),
                    title: 'privacyLabelingTitle6',
                    content: 'privacyLabelingContent6',
                },
            ]
        }
    },

}
</script>

<style scoped>
.privacy-table {
    border-top: 2px solid #333;
    width: 100%;
    border-bottom: 1px solid #767676;
}

.privacy-table .privacy-table-title {
    font-weight: bold; 
    background: #F4F6F7; 
    border-bottom: 1px solid #ccc; 
    text-align: center;
}

.privacy-table .privacy-table-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
}
</style>