<!--
   [2022/10/17 gy.yang]
   CommunitySignUpTerms.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(약관)
-->
<template>
    <div class="mt-6 mt-sm-12">
        <v-container class="px-0 px-sm-3">
            <v-checkbox
                v-model="selectedTermsAll"
                hide-details
                :label="$t('agreeAllTerms')"
                class="community-terms-checkbox"
                title="약관 동의 체크 박스_모두 동의"
            />
        </v-container>
        <v-divider class="mt-1 mb-n2" />
        <v-container class="px-0 px-sm-3">
            <div
                v-for="(item, key) in terms"
                :key="key"
                class="d-flex mt-3"
            >
                <v-checkbox
                    v-model="selectedTerms"
                    :value="key"
                    hide-details
                    class="community-terms-checkbox"
                    :title="`약관 동의 체크 박스_${item.title}`"
                >
                    <template v-slot:label>
                        {{ $t(item.title) }}
                        <span class="grey--text">
                            {{ $t('essential') }}
                        </span>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-btn
                    text
                    icon
                    :aria-label="`약관 자세히 보기 버튼_${item.title}`"
                    @click="selectTerms(key)"
                >
                    <v-icon
                        size="30"
                        color="#6C6C6C"
                        :aria-label="`약관 자세히 보기 버튼 아이콘_${item.title}`"
                    >
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
            </div>
        </v-container>

        <v-row
            no-gutters
            class="justify-end terms-actions"
        >
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    :disabled="disabled"
                    block
                    width="190"
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="회원가입 다음 페이지 이동 버튼"
                    @click="nextPage"
                >
                    {{$t('next')}}
                </v-btn>
            </v-col>
        </v-row>
        <com-dialog
            v-model="dialogTerms"
            closable
            width="1200"
            :title="termsTitle"
        >
            <template v-slot:content>
                <v-card
                    ref="terms"
                    tile
                    outlined
                    class="mt-4 mt-sm-6 pa-3 terms-content"
                >
                <community-privacy-content v-if="privacy"/>
                <span 
                    v-else 
                    v-html="termsContent" 
                />
                </v-card>
            </template>
        </com-dialog>
    </div>
</template>

<script>
import { terms, signUpStep, privacyTerms } from '@/commons/resources.js';
import CommunityPrivacyContent from '../privacy/CommunityPrivacyContent.vue';
import _ from 'lodash';
import ComDialog from '../com/ComDialog.vue';
import i18n from '@/locales/i18n';

export default{
    name:'CommunitySignUpTerms', 
    components: {
        ComDialog,
        CommunityPrivacyContent
    },
    data(){
        return {
            checkTerms: Object.keys(terms),
            selectedTerms: [],
            termsAll: false,
            disabled: true,
            dialogTerms: false,
            terms,
            privacyTerms,
            termsTitle: '',
            termsContent: '',
            privacy: false,
        };
    },
    computed: {
        selectedTermsAll: {
            get() {
                return this.checkTerms.length === this.selectedTerms.length;
            },
            set(value) {
                this.selectedTerms = value ? this.checkTerms : [];
            },
        },
    },
    methods:{
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            if (this.$store.state.user.minor.name) {
                this.$emit('nextPage', signUpStep.InfoUnderAgeCert);
            } else {
                this.$emit('nextPage', signUpStep.InfoCert);
            }
        },
        /**
         * @method selectTerms
         * @param {String} key 
         * @note 약관 상세글 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectTerms(key) {
            this.privacy = false;
            this.dialogTerms = true;
            this.termsTitle = i18n.t(this.terms[key].title);

            if(key === 'privacy') {
                this.privacy = true;
            } else {
                this.termsContent = i18n.t(this.terms[key].content);
            }
        },
        /**
         * @method scrollTopTerms
         * @note 이용약관 팝업 스크롤을 최상단으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        scrollTopTerms() {
            this.$refs.terms.$el.scrollTop = 0;
        },
        changePolicy() {
            this.termsTitle = i18n.t(this.privacyTerms.privacy1.title).replace("\n", "<br>");
            this.termsContent = i18n.t(this.privacyTerms.privacy1.content);
            this.scrollTopTerms();
        }
    },
    watch: {
        selectedTerms() {
            if(!(_.xor(this.checkTerms, this.selectedTerms).some(x => this.checkTerms.includes(x)))) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        dialogTerms(value) {
            if(value) {
                this.$nextTick(() => {
                    this.scrollTopTerms();
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.community-terms-checkbox {
    margin-top: 0;
    padding-top: 0;
    align-items: center;

    &::v-deep .v-label {
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #14110F;
        margin-left: 13px;
    }

    &::v-deep.v-input--checkbox .v-input--selection-controls__input {
        margin: 0;
    }

    &::v-deep.v-input--checkbox .v-icon::before {
        content: '';
        width: 100%;
        height: 100%;
        min-width: 24px;
        min-height: 24px;
        display: block;
        background-size: 100%;
        background-position: center;
        background-image: url('~@/assets/images/unchecked.svg');
    }

    &::v-deep.v-input--checkbox.v-input--is-label-active .v-icon::before {
        background-image: url('~@/assets/images/checked.svg');
    }
}

.terms-content {
    height: calc(100vh - 80px - 24px - 36px);
    max-height: 470px;
    overflow-y: auto;
    white-space: pre-wrap;
}

.terms-actions {
    margin-top: 60px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .community-terms-checkbox {
        &::v-deep .v-label {
            font-size: 16px;
            margin-left: 1rem;
        }

        &.header::v-deep .v-label {
            font-size: 20px;
        }
    }
    
    .terms-content {
        max-height: calc(100vh - 32px - 24px - 36px);
        height: calc(100vh - 32px - 24px - 36px);
    }

    .terms-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>