<!--
   [2022/12/08 se.yoo]
   CommunityHomeBannerMain.vue
   @note 시민 커뮤니티 홈 메인 배너 컴포넌트
-->
<template>
    <v-img
        id="main"
        :src="require('@/assets/images/main_banner.png')"
        cover
        :alt="$t('altHomeBannerImg')"
        class="home-main-banner scm-img-gradient-filter-primary"
    >
        <h1 class="home-main-banner-title">
            Metaverse <br v-if="$vuetify.breakpoint.smAndDown" />
            Seoul
        </h1>
    </v-img>
</template>

<script>
export default {
    name: 'CommunityHomeBannerMain'
}
</script>

<style scoped>
.home-main-banner {
    align-items: center;
}

.home-main-banner-title {
    font-weight: 700;
    font-size: 50px;
    line-height: 72px;
    color: #FFFFFF;
    text-align: center;
    cursor: default;
}
</style>