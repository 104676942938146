import i18n from '../locales/i18n';

export const rules = {
    required: value => !!value || i18n.t('essentialMessage'),
    email: value => {
        const pattern = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/;
        // 이메일 값 없을때도 통과 (필수 아닐 시)
        return value == '' || value == undefined || pattern.test(value) || i18n.t('idCondition');
    },
    password: value => {
        const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
        return pattern.test(value) || i18n.t('pwCondition');
    },
    nickName: value => {
        const pattern = /^[ㄱ-ㅎ|가-힣]{2,8}$|^[a-z|A-Z|]{2,16}$/;
        return pattern.test(value) || i18n.t('nickNameCondition');
    },
    phone: value => {
        const pattern = /^[0-9]{11}$/;
        return value == '' || value == undefined || pattern.test(value) || i18n.t('phoneCondition');
    },
};

export const signUpStep = {
    Terms: 'CommunitySignUpTerms',
    InfoAgeCheck: 'CommunitySignUpInfoAgeCheck',
    InfoUnderAge: 'CommunitySignUpInfoUnderAge',
    InfoUnderAgeCert: 'CommunitySignUpInfoUnderAgeCert',
    InfoCert: 'CommunitySignUpInfoCert',
    InfoId: 'CommunitySignUpInfoId',
    InfoPwd: 'CommunitySignUpInfoPwd',
    InfoNickName: 'CommunitySignUpInfoNickName',
    End: 'CommunitySignUpEnd',
    InfoAge: 'CommunitySignUpInfoAge',
}

export const findStep = {
    InfoCert: 'CommunitySignUpInfoCert',
    FindId: 'CommunityFindId',
    FindPwd: 'CommunityFindPwd',
    ResetPwd: 'CommunityResetPwd'
}

export const terms = {
    service: {
        title: 'termsService',
        content: `termsServiceContent`
    },
    privacy: {
        title: 'termsPrivacy',
        content:'termsPrivacyContentV2'
    },
    ethics: {
        title: 'termsEthics',
        content: 'termsEthicsContent'
    }

}

export const privacyTerms = {
    privacy1: {
        content: "termsPrivacyContentV1"
    },
    privacy2: {
        content: 'termsPrivacyContentV2'
    },
}

export const accountMenu = {
    SEARCH_ID: 'id',
    SEARCH_PW: 'pwd',
    SIGN_UP: 'signup',
};

export const accountMenuInfo = [
    { text: 'forgotId', value: accountMenu.SEARCH_ID },
    { text: 'forgotPassword', value: accountMenu.SEARCH_PW },
    { text: 'signUp', value: accountMenu.SIGN_UP },
];

export const etrType = {
    PHOTO: 1,
    VIDEO: 2,
    PLACE: 3
};

export const etrTypeInfo = [
    { text: i18n.t('photo'), value: etrType.PHOTO },
    { text: i18n.t('video'), value: etrType.VIDEO }, 
    { text: i18n.t('place'), value: etrType.PLACE }
];

export const awdType = {
    GRAND: "대상",
    TOP: "최우수상",
    EXCELLENCE: "우수상",
    PARTICIPATION: "장려상"
};


export const langCd = [
    { text: 'all', value: 'xx-XX'},
    { text: 'ko', value: 'ko-KR' },
    { text: 'en', value: 'en-US' },
    { text: 'ja', value: 'ja-JP' },
    { text: 'zh', value: 'zh-CN' },
];

export const srvcCgCd = {
    0: '해당 없음',
    1: 'seoulPlaza',
    2: 'seoulCityHall',
    3: 'businessSupportCenter',
    4: 'civilServices',
    5: 'avatarCounselingRoom',
    6: 'taxSquare',
    7: 'mayorsOffice',
    8: 'contest',
    11: 'fintechLab',
    15: 'worldyCitizenLand',
    24: 'realEstate',
    26: 'ddp',
};