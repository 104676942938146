<!--
   [2024/05/31 sb.hwang]
   DownloadServiceTool.vue
   @note 다운로드 페이지 공모전 저작도구 컴포넌트
-->
<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg6
            >
                <h2 
                    v-if="loggedIn" 
                    class="scm-community-section-title mt-4 mb-2"
                >
                    {{ $t('download2') }}
                </h2>
                <v-row
                    v-if="loggedIn"
                    justify="start"
                    class="py-5"
                >
                    <v-col>
                        <black-icon-btn
                            icon="mdi-microsoft-windows"
                            btn-name="Windows"
                            label="공모전 저작도구"
                            @click="clickRequestTool"
                        />
                        <div class="text-body-2 mt-4">
                        MD5 Checksum : 
                            <code>
                                {{ staticFileChksmCn }}
                            </code>
                        </div>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>                
            
        <alert-dialog
            v-model="showNotAuth"
            show-disagree
            :title="$t('goLogin')"
            :content="$t('moveLogin')"
            :agree="$t('login')"
            :disagree="$t('cancel')"
            @agree="routerLogin"
        />
    </v-container>
</template>

<script>
import AlertDialog from '../com/AlertDialog.vue';
import BlackIconBtn from '../com/BlackIconBtn.vue';
import { downloadFile } from '@/commons/functions';

export default {
    name: 'DownloadServiceTool',
    components:{
        AlertDialog,
        BlackIconBtn
    },
    data() {
        return {
            showNotAuth: false,
            staticFileChksmCn: '',
            staticFileUrlAddr: '',
        }
    },
    computed: {
        loggedIn() {
            return this.$cookies.isKey('user_token');
        }
    },
    methods: {
        /**
         * @method clickRequest
         * @note 저작도구 다운로드 버튼 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        clickRequestTool() {
            if(this.loggedIn) {
                downloadFile(this.staticFileUrlAddr);
            } else {
                this.showNotAuth = true;
            }
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            });
        },
        /**
         * @method selectToolUrl
         * @note 정적 파일 최신버전 정보를 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectToolUrl() {
            if(this.loggedIn) {
                const params = {
                    staticFileClsfNm: 'content_space_maker_installer'
                }

                this.$store.dispatch('selectStaticFile', {params})
                .then((res) => {
                    this.staticFileChksmCn = res.staticFileChksmCn;
                    this.staticFileUrlAddr = res.staticFileUrlAddr;
                })
            }
        }
    },
    created() {
        this.selectToolUrl();
    }
}
</script>

<style scoped>

.download-section-button {
    background: #0A0A0A !important;
    border-radius: 10px !important;
    width: 235px !important;
    height: 70px !important;
    color: white !important;
    box-shadow: none !important;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
}

.download-table {
    text-align: center;
}
.download-table th {
    font-weight: bold;
}

table th + th,
table td + td { 
    border-left:1px solid #dddddd; 
}


.theme--light.v-data-table td:nth-child(2),
.theme--light.v-data-table td:nth-child(3) {
    width: 40%;
}
</style>