<!--
   [2022/12/09 se.yoo]
   AboutServiceTop.vue
   @note 서비스 소개 상단 컴포넌트
-->
<template>
    <v-row
        align="center"
        justify="center"
        class="py-15 about-section-wrap"
    >
        <v-col
            cols="11"
            sm="10"
            lg="8"
        >
            <h2 class="scm-community-section-title text-center">
                {{ $t('metaverseSeoul') }}
            </h2>
            <div 
                class="about-section-content mt-5 text-center"
                v-html="$t('aboutServiceTopContent')"
            />
            <v-img
                :src="require('@/assets/images/about_top_image.png')"
                :height="$vuetify.breakpoint.mdAndUp ? 470 : 270"
                :alt="$t('altIntroductionImg')"
                cover
                class="rounded-xl mt-13"
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'AboutServiceTop'
};
</script>

<style scoped>
.about-section-wrap {
    height: 100vh;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .about-section-wrap {
        height: unset !important;
    }
}
</style>