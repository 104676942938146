<!--
   [2022/10/17 gy.yang]
   CommunitySearch.vue
   @note 시민 커뮤니티 웹 페이지 찾기 화면
-->
<template>
    <div>
        <h1 class="community-login-title">{{ menuText }}</h1>
        <component 
            :is="page"
            :menu="menu"
            @prevPage="backPage"
            @nextPage="movePage"
        />
        <alert-dialog
            v-model="alertError"
            title="오류 발생"
            content="유효하지 않은 주소입니다. 메인 화면으로 돌아갑니다."
            @agree="routerHome"
        />
    </div>
</template>

<script>
import CommunityFindId from '@/components/login/CommunityFindId.vue';
import CommunityFindPwd from '@/components/login/CommunityFindPwd.vue';
import CommunitySignUpInfoCert from '@/components/login/CommunitySignUpInfoCert.vue';
import CommunityResetPwd from '@/components/login/CommunityResetPwd.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';
import { findStep, accountMenuInfo } from '@/commons/resources.js';
import i18n from '@/locales/i18n';

export default {
    name:'CommunitySearch',
    components: {
        CommunityFindId,
        CommunityFindPwd,
        CommunitySignUpInfoCert,
        CommunityResetPwd,
        AlertDialog
    },
    data(){
        return {
            page: findStep.InfoCert,
            alertError: false
        }
    },
    computed: {
        menu() {
            return this.$route.params.menu;
        },
        menuInfo() {
            return accountMenuInfo.find(item => 
                item.value === this.menu
            );
        },
        menuText() {
            if(this.page === findStep.ResetPwd) { 
                return this.menuInfo? i18n.t('changePassword') : '';
            }

            return this.menuInfo? i18n.t(this.menuInfo.text) : '';
        }
    },
    methods:{
        /**
         * @method backPage
         * @note 회원가입 화면 변경(이전) 함수
         * @email se.yoo@naviworks.com
         */
        backPage() {
            let prev = '';

            if(this.page === findStep.ResetPwd) {
                prev = findStep.FindPwd;
            }

            if(prev == '') {
                this.$router.go(-1);
            } else {
                this.movePage(prev);
            }
        },
        /**
         * @method movePage
         * @param {String} newPage 
         * @note 회원가입 화면 변경 함수
         * @email gy.yang@naviworks.com
         */
        movePage(newPage){
            this.page = newPage;
        },
        /**
         * @method routerHome
         * @note 메인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerHome() {
            this.$router.push({ name: 'communityHome' });
        }
    },
    created() {
        this.$store.dispatch('resetUser');

        if(!this.menuInfo) {
            this.alertError = true;
        }
    }
}
</script>

<style scoped>
.community-back-btn {
    position: absolute;
    left: 60px;
    top: 60px;
}

.community-close-btn {
    position: absolute;
    right: 50px;
    top: 50px;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .community-back-btn {
        left: 20px;
        top: 20px;
    }

    .community-close-btn {
        right: 20px;
        top: 20px;
    }
}
</style>
