<!--
   [2022/12/13 se.yoo]
   ComDialog.vue
   @note 다이얼로그 컴포넌트
-->
<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs"
        :max-width="width"
        content-class="elevation-0"
    >
        <v-card
            rounded="lg"
            elevation="0"
            class="com-dialog-card pa-10 d-flex justify-center flex-column"
        >
            <p v-if="menu=='mypage'" 
                class="community-login-desc scm-community-section-title">
                {{ $t('rcvryEmailPass') }}
            </p>
            <p v-else
                class="community-login-desc scm-community-section-title">
                {{ $t('emailPass') }}
            </p>
            <div v-if="menu=='mypage'"
                class="community-login-input-label"
            >
                {{ $t('rcvryEmailWarn') }}
            </div>
            <div 
                class="community-login-input-label mt-9"
            >
                {{ $t('email') }}
            </div>
            <v-form ref="idForm">
                <v-text-field
                    v-model="emlAddr"
                    :disabled="this.sendYn"
                    :rules="[rules.email, rules.required]"
                    placeholder="ex) metaseoul@meta.co.kr"
                    height="47"
                    class="mt-0 pt-0"
                    @keydown.enter="emlCertifySend"
                />
            </v-form>
            <v-row
                no-gutters
                class="pwd-actions justify-end"
            >
                <v-col
                    cols="6"
                    xl="5"
                    class="pr-5"
                >
                    <v-btn
                        v-if="this.sendYn"
                        block
                        height="40"
                        class="scm-community-button-outlined community-login-btn"
                        :disabled="disabledAgain"
                        @click="emlCertifySend"
                    >
                        {{ $t('sendAgain') }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="6"
                    xl="5"
                >
                    <v-btn
                        v-if="!this.sendYn"
                        block
                        height="40"
                        class="scm-community-button-primary community-login-btn"
                        @click="emlCertify"
                    > 
                        {{  $t('sendMail') }}
                    </v-btn>
                    <v-btn
                        v-if="this.sendYn"
                        block
                        height="40"
                        class="scm-community-button-primary community-login-btn"
                        @click="emlCertify"
                    > 
                        {{  $t('completion') }}
                    </v-btn>
                    
                </v-col>
                <v-col 
                    cols="12"
                >
                    <div class="scm-community-form-rule mt-1">{{error}}</div>
                </v-col>
            </v-row>
        </v-card>
        <alert-dialog
            v-model="alertResult"
            :title="alertTitle"
            :content="alertContent"
            @agree="routerLogin"
        />
    </v-dialog>
</template>

<script>
import { rules } from '@/commons/resources.js';
import { accountMenu } from '@/commons/resources';
import AlertDialog from '../com/AlertDialog.vue'
export default {
    name: 'CommunityEmailCertifiedDialog',
    props: {
        value: {
            type: Boolean
        },
        title: {
            type: String,
            default: '제목'
        },
        content: {
            type: String,
            default: '내용'
        },
        width: {
            type: String,
            default: '595'
        },
        closable: {
            type: Boolean,
            default: false
        },
        menu: {
            type: String,
            default: accountMenu.SIGN_UP
        },
    },
    components:{
        AlertDialog
    },
    data() {
        return {
            emlAddr: '',
            rules,
            sendYn: false,
            disabledNext: false,
            error: '',
            alertTitle: '',
            alertContent: '',
            alertResult: false,
            continueUrl: '',
            disabledAgain: false,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        emlCertify() {
            if (!this.sendYn) {
                this.emlCertifySend()
            } else {
                this.emlCertifyCheck()
            }
        },
        /**
         * @method emlCertifySend
         * @note 이메일 본인인증 함수 
         * @email jake.jeon@naviworks.com
         */
        emlCertifySend(){
            const validate = this.$refs.idForm.validate();
            if (validate) {
                const params = {
                    emlAddr: this.emlAddr,
                }
                if (this.menu == accountMenu.SIGN_UP ) {
                    this.sendYn = true
                    this.$store.dispatch('overlapCheck', params)
                    .then((res) => {
                        if(res.errorMsg == '사용 가능한 아이디 입니다.<br>The email is available'){
                            const emlParams = {
                                emlAddr: this.emlAddr,
                                continueUrl: 'https://metaverseseoul.kr/__/join-user'
                            }
                            this.$store.dispatch('emlCertifySend', emlParams)
                            .then(() => {
                            }).catch(err => { 
                            })
                        }
                    }).catch(err => {
                        if (err.response.data.errorMsg == '이미 등록된 아이디 입니다<br>The email is already registered') {
                            this.alertTitle = this.$t('idDuplicate');
                            this.alertContent = this.$t('duplicateCi');
                            this.alertResult = true;
                        }
                    })
                } else if(this.menu == 'mypage' || this.menu == accountMenu.SEARCH_ID || this.menu == accountMenu.SEARCH_PW) {
                    this.sendYn = true
                    const emlParams = {
                        emlAddr: this.emlAddr,
                        continueUrl: this.menu === accountMenu.SEARCH_ID ? 
                                        'https://metaverseseoul.kr/__/find-id' : 
                                            (this.menu === accountMenu.SEARCH_PW ? 
                                                'https://metaverseseoul.kr/__/find-password' : 'https://metaverseseoul.kr/__/register-recover-email')
                    }
                    this.$store.dispatch('emlCertifySend', emlParams)
                    .then(() => {
                        this.disabledAgain = true;
                        this.error = '1분 후에 다시 시도가능합니다';
                        setTimeout(() => {
                            this.disabledAgain = false;
                            this.error = '';
                        }, 60000)
                    }).catch(err => {
                    })
                } else {
                    this.sendYn = true
                    this.$store.dispatch('emlCheckId', params)
                    .then((res) => {
                        if (!res.isEmailAccount) {
                            this.$emit('notEmailAccount')
                            this.dialog = false
                        }
                    }).catch(err => {
                        this.$emit('notExistedId')
                        this.dialog = false
                    })
                }
            }
        },
        emlCertifyCheck() {
            const params = {
                emlAddr: this.emlAddr,
            }
            this.$store.dispatch('emlCertifyCheck', params)
            .then((res) => {
                if(!res.verificationResult) {
                    this.error = '링크를 통해 이메일 주소를 인증해 주세요'
                } else {
                    if(this.menu=='mypage' || this.menu == accountMenu.SEARCH_ID) {
                        this.$store.commit('setUserRcvryEmlAddr', this.rcvryEmlAddr);
                        this.$store.commit('setUserRcvryEmlCertifed', true);
                        this.$emit('emlCertifiedComplete', this.emlAddr)
                    } else {
                        this.$store.commit('setUserEmlAddr', this.emlAddr);
                        this.$store.commit('setUserEmlCertifed', true);
                        this.$emit('emlCertifiedComplete')
                    }
                    this.dialog = false
                }
            }).catch(err => {

            })
        },
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        },
    }
}
</script>

<style scoped>
::v-deep .v-dialog--fullscreen .com-dialog-card.v-card {
    padding: 16px !important;
}


.scm-community-form-rule {
    font-weight: 400;
    padding: 1px;
    font-size: 14px;
    line-height: 20px;
    color: #ff0000;
    margin-bottom: 16px;
}
</style>