<template>
    <v-container class="mt-n8 px-0 px-sm-3">
        <template v-if="items && items.length > 0">
            <board-list-item
                v-for="(item, i) in pageList"
                :key="i"
                :item="item"
                :item-sn="itemSn"
                :item-title="itemTitle"
                :item-lock="itemLock"
                :item-category="itemCategory"
                :item-date="itemDate"
                :item-lang="itemLang"
                :date-shorten="dateShorten"
                @clickItem="$emit('clickItem', $event)"
            >
                <template v-slot:info="{ slotItem }">
                    <slot 
                        name="info"
                        :slot-item="slotItem"
                    />
                </template>
            </board-list-item>
        </template>
        <no-data v-else />
        <div class="text-center my-8">
            <slot name="actions"/>
            <v-pagination
                v-model="page"
                :length="pageCount"
                :next-aria-label="$t('altNextPage')"
                :previous-aria-label="$t('altBackPage')"
                :page-aria-label="`${$t('altMovePage') + '{0}' + $t('altPage')}`"
                class="scm-community-pagination"
            />
        </div>
    </v-container>
</template>

<script>
import { getPageCount, getPageList, windowScrollTop } from '@/commons/functions.js';
import BoardListItem from './BoardListItem.vue';
import NoData from '../com/NoData.vue';

export default {
    components: { BoardListItem, NoData },
    name: 'BoardList',
    props: {
        items: {
            type: Array
        },
        rows: {
            type: Number,
            default: 5
        },
        itemSn: {
            type: String,
            default: 'sn'
        },
        itemLock: {
            type: String,
            default: 'lock'
        },
        itemTitle: {
            type: String,
            default: 'title'
        },
        itemCategory: {
            type: String,
            default: 'category'
        },
        itemDate: {
            type: String,
            default: 'date'
        },
        itemLang: {
            type: String,
            default: 'lang'
        },
        dateShorten: {
            type: Boolean,
            default: true
        },
        noPullUp: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            page: 1
        };
    },
    computed: {
        pageCount(){
            return getPageCount(this.items.length, this.rows);
        },
        pageList() {
            return getPageList(this.page, this.items, this.rows);
        }
    },
    watch: {
        page() {
            windowScrollTop();
        }
    }
}
</script>

<style scoped>

</style>
