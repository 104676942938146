<!--
   [2022/10/17 gy.yang]
   CommunitySignUp.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면
-->
<template>
    <div>
        <h1 class="community-login-title">
            {{ $t('signUp') }}
        </h1>
        <component 
            :is="page"
            class="community-signup-component"
            @prevPage="backPage"
            @nextPage="movePage"
        />
    </div>
</template>

<script>
import CommunitySignUpTerms from '@/components/login/CommunitySignUpTerms.vue';
import CommunitySignUpInfoCert from '@/components/login/CommunitySignUpInfoCert.vue';
import CommunitySignUpInfoAgeCheck from '@/components/login/CommunitySignUpInfoAgeCheck.vue';
import CommunitySignUpInfoUnderAge from '@/components/login/CommunitySignUpInfoUnderAge.vue';
import CommunitySignUpInfoUnderAgeCert from '@/components/login/CommunitySignUpInfoUnderAgeCert.vue';
import CommunitySignUpInfoId from '@/components/login/CommunitySignUpInfoId.vue';
import CommunitySignUpInfoPwd from '@/components/login/CommunitySignUpInfoPwd.vue';
import CommunitySignUpInfoNickName from '@/components/login/CommunitySignUpInfoNickName.vue';
import CommunitySignUpInfoAge from '@/components/login/CommunitySignUpInfoAge.vue';
import CommunitySignUpEnd from '@/components/login/CommunitySignUpEnd.vue';
import { signUpStep } from '@/commons/resources.js';
import { windowScrollTop } from '@/commons/functions';

export default {
    name:'CommunitySignUp',
    components: {
        CommunitySignUpTerms,
        CommunitySignUpInfoAgeCheck,
        CommunitySignUpInfoUnderAge,
        CommunitySignUpInfoUnderAgeCert,
        CommunitySignUpInfoCert,
        CommunitySignUpInfoId,
        CommunitySignUpInfoPwd,
        CommunitySignUpInfoNickName,
        CommunitySignUpInfoAge,
        CommunitySignUpEnd,
    },
    data(){
        return{
            page: signUpStep.InfoAgeCheck
        }
    },
    methods:{
        /**
         * @method backPage
         * @note 회원가입 화면 변경(이전) 함수
         * @email se.yoo@naviworks.com
         */
        backPage() {
            let prev = '';

            for(const step in signUpStep) {
                if(signUpStep[step] == this.page) {
                    break;
                } else {
                    prev = signUpStep[step];
                }
            }
            if(prev == '') {
                this.$router.go(-1);
            } else {
                this.movePage(prev);
            }
        },
        /**
         * @method movePage
         * @param {String} newPage 
         * @note 회원가입 화면 변경 함수
         * @email gy.yang@naviworks.com
         */
        movePage(newPage){
            this.page = newPage;
        }
    },
    created() {
        this.$store.dispatch('resetUser');
        this.$store.dispatch('resetRepresentative');
        this.$store.dispatch('resetMinor');
    },
    watch: {
        page() {
            windowScrollTop();
        }
    }
}
</script>

<style scoped>
.community-signup-component::v-deep .community-signup-input .v-text-field__slot input{
    line-height: 18px;
    padding: 10px 0;
    height: 48px;
}

.community-back-btn {
    position: absolute;
    left: 60px;
    top: 60px;
}

.community-close-btn {
    position: absolute;
    right: 50px;
    top: 50px;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .community-back-btn {
        left: 20px;
        top: 20px;
    }

    .community-close-btn {
        right: 20px;
        top: 20px;
    }
}
</style>
