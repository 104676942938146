<!--
   [2022/08/01 sy.jeon]
   NiceDialog.vue
   @note 나이스 인증 모듈
-->
<template>
    <form 
        ref="checkForm" 
        method="post"
    >
        <input
            type="hidden"
            name="m"
            value="checkplusService"
        />
        <input
            type="hidden"
            name="EncodeData"
            :value="encData"
        />
        <input 
            type ="hidden" 
            name="recvMethodType"
            value ="get"
        /> 
    </form>
</template>

<script>

export default {
    name: 'NiceDialog',
    components: {
    },
    data() {
        return {
            encData: ''
        };
    },
    methods: {
        /**
         * @method fnPopup
         * @returns {Promise} 결과 데이터 
         * @note 나이스 인증 팝업 전시
         * @email sy.jeon@naviworks.com
         */
        fnPopup() {
            return new Promise((resolve, reject) => {
                window.resolve = (data) => {
                    resolve(data);
                }
                
                window.reject = (data) => {
                    reject(data);
                }
                
                this.$store.dispatch('selectNiceEncData')
                .then(res => {
                    this.encData = res.result.sEncData;
                    setTimeout( () => {
                        window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                        this.$refs.checkForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                        this.$refs.checkForm.target = "popupChk";
                        this.$refs.checkForm.submit();
                    }, 1);
                }).catch(err => {
                });
            })
        }
    }
}
</script>

<style scoped>
</style>