<!--
   [2022/10/19 gy.yang]
   ContestEntriesDetail.vue
   @note 공모전 출품작 상세 화면
-->
<template>
    <v-container
        id="content"
        fluid
        class="pa-0"
    >
        <template v-if="loadded && !alertError">
            <h3 class="detail-sub-title mb-1">
                {{ contest.ctstNm }}
            </h3>
            <h2 class="scm-community-title d-flex align-center">
                {{ entry.etrNm }}
                <v-chip
                    v-if="entry.awdNm"
                    color="#C9CEFF"
                    text-color="#252CD2"
                    class="detail-awd-chip ml-2"
                >
                    {{ awdNmContent }}
                </v-chip>
                <v-spacer />
                <v-btn
                    text
                    class="detail-router-btn custom-mouse-style"
                    @click="routerContestEntries"
                >
                    <v-icon
                        size="18"
                        class="mr-1"
                    >
                        mdi-format-list-bulleted
                    </v-icon>
                    {{ $t('list') }}
                </v-btn>
            </h2>
            <v-divider class="scm-community-divider my-4" />
            <div class="detail-info-label">
                {{ $t('registrationDate') }} :
                <span class="detail-info-value">{{ toStringByFormattingTime(new Date(entry.rcptDt)) }}</span>
            </div>
            <div
                v-if="loggedIn"
                class="detail-info-label mt-3"
            >
                다운로드 :
                <v-btn
                    v-if="entry.etrFileSn"
                    icon
                    small
                    :aria-label="$t('altFileDownload')"
                    @click="clickDownload"
                >
                    <v-icon
                        size="18"
                        color="#45404C"
                        :aria-label="$t('altFileDownloadIcon')"
                    >
                        mdi-download
                    </v-icon>
                </v-btn>
                <span v-else>
                    {{ $t('attachNot') }}   
                </span>
            </div>
            <image-print
                :src="entry.etrTubnlFileSn"
                :alt="`${$t('altEntryImg') + entry.etrNm}`"
                cover
                hidden-empty
                position="center"
                class="my-10"
            />
            <div class="detail-desc mb-15">
                <span v-html="etrDscpCnContent" />
            </div>
            <v-divider class="scm-community-divider mt-4" />
            <div
                v-if="entries && entries.length > 0"
                class="d-flex justify-center align-center detail-pagination"
            >
                <v-btn
                    icon
                    :disabled="page == 0"
                    :aria-label="$t('altBackPage')"
                    @click="routerOtherEntry(page - 1)"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="detail-pagination-page">
                    <span class="detail-pagination-active">
                        {{ page + 1 }}
                    </span>
                    <span>
                        /
                    </span>
                    <span>
                        {{ pageCount }}
                    </span>
                </div>
                <v-btn
                    icon
                    :disabled="page == entries.length - 1"
                    :aria-label="$t('altNextPage')"
                    @click="routerOtherEntry(page + 1)"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </template>
        <alert-dialog
            v-model="alertError"
            title="오류 발생"
            content="데이터가 존재하지 않습니다. 메인 화면으로 돌아갑니다."
            @agree="routerHome"
        />
    </v-container>
</template>

<script>
import ImagePrint from '@/components/com/ImagePrint.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';
import { downloadFile, toStringByFormattingTime } from '@/commons/functions';
import { mapGetters } from 'vuex';

export default {
    name: 'ContestEntriesDetail',
    components: {
        ImagePrint,
        AlertDialog
    },
    data() {
        return {
            alertError: false,
            loadded: false,
            toStringByFormattingTime
        };
    },
    computed: {
        ...mapGetters({
            contest: 'getContest',
            entries: 'getEntries',
            entry: 'getEntry'
        }),
        ctstSn() {
            return this.$route.params.ctstSn;
        },
        etrSn() {
            return this.$route.params.etrSn;
        },
        page() {
            return this.entries.findIndex(item => item.etrSn == this.etrSn);
        },
        pageCount() {
            return this.entries.length;
        },
        loggedIn() {
            return this.$cookies.isKey('user_token');
        },
        title() {
            return `${this.contest.ctstNm} ${this.$t('entries')}`;
        },
        types() {
            return [
                { text: this.$t('all'), value: '' },
                { text: this.$t('title'), value: 'ntcTtlNm' }
            ]
        },
        infos() {
            return [
                { text: this.$t('registrationDate'), value: 'rcptDt' },
                { 
                    text: this.$t('download'),
                    value: 'etrFileSn', 
                    isFile: true
                }
            ]
        },
        etrDscpCnContent() {
            return this.entry.etrDscpCn.replaceAll("<", "&lt;").replaceAll(">", "&gt;")
        },
        awdNmContent() {
            switch(this.entry.awdNm) {
                case '대상': 
                    return this.$t('grandAward')
                case '최우수상': 
                    return this.$t('topAward')
                case '우수상': 
                    return this.$t('excellenceAward')
                case '장려상':
                    return this.$t('participationAward')
                default:
                    return ''
            } 
        }
    },
    methods: {
        /**
         * @method getContest
         * @note 공모전 상세 정보 불러오기 함수
         * @email gy.yang@naviworks.com
         */
        getContest(){
            this.$store.dispatch('selectContestDetail', this.ctstSn)
            .catch(err => {
                this.alertError = true;
            });
        },
        /**
         * @method getEntryList
         * @note 공모전 출품작 목록을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        getEntryList(){
            this.$store.dispatch('selectEntryList', this.ctstSn)
            .catch(err => {
                this.alertError = true;
            });
        },
        /**
         * @method getEntryDetail
         * @note 공모전 출품작 상세 정보 불러오기 함수
         * @email gy.yang@naviworks.com
         */
        getEntryDetail(){
            this.loadded = false;

            this.$store.dispatch('selectEntryDetail', this.etrSn)
            .then( () => {
                this.loadded = true;
            }).catch(err => {
                this.alertError = true;
            });
        },
        /**
         * @method routerContestEntries
         * @note 공모전 상세 화면으로 돌아가는 함수
         * @email gy.yang@naviworks.com
         */
        routerContestEntries() {
            this.$router.push({
                name: 'contestEntries',
                params: { sn: this.entry.ctstSn }
            });
        },
        /**
         * @method routerOtherEntry
         * @param {Number} index 이동할 출품작 순서
         * @note 이전/다음 출품작으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerOtherEntry(index) {
            const target = this.entries[index];

            this.$router.push({
                name: 'contestEntriesDetail',
                params: { 
                    ctstSn: this.ctstSn,
                    etrSn: target.etrSn
                }
            });
        },
        /**
         * @method routerHome
         * @note 메인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerHome() {
            this.$router.push({ name: 'communityHome' });
        },
        /**
         * @method clickDownload
         * @note 첨부파일 다운로드 함수
         * @email sh.hong@naviworks.com
         */
        clickDownload() {
            downloadFile(this.entry.etrFileSn);
        }
    },
    created(){
        if(!this.contest.ctstSn) {
            this.getContest();
            this.getEntryList();
        }

        this.getEntryDetail();
    },
    watch: {
        etrSn() {
            this.getEntryDetail();
        }
    }
}
</script>

<style scoped>
.detail-sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #303030;
}

.detail-awd-chip {
    height: 29px;
}

.detail-router-btn {
    align-self: flex-end;
    height: auto !important;
    padding: 0 !important;
}

.detail-router-btn::v-deep .v-btn__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #6C6C6C;
}

.detail-router-btn:hover::v-deep .v-btn__content,
.detail-router-btn:focus::v-deep .v-btn__content {
    color: #0096EB;
}

.detail-info-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #6C6C6C;
}

.detail-info-value {
    color: #303030;
}

.detail-desc {
    white-space: pre-line;
}

.detail-pagination {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #6C6C6C;
    margin-top: 30px;
    margin-bottom: 180px;
}

.detail-pagination-page span {
    display: inline-block;
    height: 34px;
    min-width: 25px;
    padding: 0 5px;
    text-align: center;
    line-height: 34px;
}

.detail-pagination-active {
    font-weight: 600;
    color: #303030;
}

.detail-pagination::v-deep .v-btn{
    color: #6C6C6C !important;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 34px;
    width: 32px;
    margin: 5px;
}

.detail-pagination::v-deep .v-btn.v-btn--disabled .v-icon {
    color: #6C6C6C !important;
    opacity: 0.2;
}

.detail-pagination::v-deep .v-btn:hover::before,
.detail-pagination::v-deep .v-btn:hover .v-ripple__container {
    opacity: 0 !important;
}
</style>