<!--
   [2022/12/22 sh.hong]
   PolicyLayout.vue
   @note 약관 화면 레이아웃
-->
<template>
    <v-app>
        <v-main class="admin-main-wrap d-flex align-center">
            <v-layout justify-center>
                <v-flex 
                    xs12
                    sm10
                    md9
                    xl7
                >
                    <v-card
                        tile
                        class="admin-login-wrap"
                    >   
                        <router-view />
                    </v-card>
                </v-flex>
            </v-layout>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'AdminLoginLayout'
}
</script>

<style scoped>
.admin-main-wrap {
    background: linear-gradient(107.56deg, #1EA7DA 0%, #824ADC 100%);
}

.admin-login-wrap {
    background: #FBFBF8;
    box-shadow: 0px 10px 40px #33569C;
    margin: auto;
    padding: 80px 0 60px 0;
}

.admin-login-wrap::v-deep .admin-login-title {
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    color: #3491DA;
}

.admin-login-wrap::v-deep .admin-terms-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 49.03px;
}

.admin-login-wrap::v-deep .admin-login-input {
    padding-top: 0 !important;
}

.admin-login-wrap::v-deep .admin-login-input .v-input__slot{
    background: #FBFBF8;
    border: 1px solid #376B7E;
}

.admin-login-wrap::v-deep .admin-login-input .v-input__slot:before {
    border-color: transparent !important;
}

.admin-login-wrap::v-deep .admin-login-input .v-input__slot input {
    padding: 24px 27px;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    max-height: 70px;
    color: #376B7E;
}

.admin-login-wrap::v-deep .admin-login-input .v-input__icon {
    margin: 18px;
}

.admin-login-wrap::v-deep .scm-admin-button-gradient {
    height: 80px;
}

.admin-login-wrap::v-deep .scm-admin-button-gradient .v-btn__content {
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    color: #FBFBF8 !important;
}

.admin-login-wrap::v-deep .admin-login-actions .v-btn__content {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #626375;
}

.admin-login-wrap::v-deep .admin-login-actions .col {
    position: relative;
    text-align: center;
}

.admin-login-wrap::v-deep .admin-login-actions .col:not(:first-child)::before{
    content: '';
    display: inline-block;
    width: 1px;
    height: 16px;
    background: #626375;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.admin-login-wrap::v-deep .admin-textarea {
    width: 1000px;
    height: 600px;
    font-size: 18px;
    padding: 24px 24px 12px 24px;
    overflow: auto;
    color: #14110F;
    outline: #C9CECB solid 1px;
    line-height: 1.5em;
    background: #FDFDFD;
    white-space: pre-wrap;
}

.admin-login-wrap::v-deep .admin-close {
    margin-left: auto;
    transform: scale(1.5);
}

.admin-login-wrap::v-deep .admin-checkbox.header {
    transform: scale(1.6665);
}

.admin-login-wrap::v-deep .admin-checkbox {
    transform: scale(1.5);
}

.admin-login-wrap::v-deep .admin-checkbox .v-icon.notranslate.mdi.mdi-checkbox-blank-outline {
    color: #C9CECB;
}

.admin-login-wrap::v-deep .admin-checkbox .v-icon.notranslate.mdi.mdi-minus-box {
    color: #295EFF;
}

.admin-login-wrap::v-deep .admin-backBtn {
    transform: scale(1.6665);
}

.admin-login-wrap::v-deep .admin-detail {
    margin-left: auto;
    transform: scale(1.6665);
}

.admin-login-wrap::v-deep .border {
    border-bottom: 1px solid #C9CECB;
}

.admin-login-wrap::v-deep .admin-checkbox-text.header {
    height: 48.25px;
    font-size: 24px;
}

.admin-login-wrap::v-deep .admin-text {
    height: 33px;
    color: #626375;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}

.admin-login-wrap::v-deep .admin-overlapCheck {
    float: right;
    width: 141px;
    height: 45px;
    color: #FFFFFF;
    font-size: 22px;
    background: linear-gradient(107.56deg, #1EA7DA 0%, #824ADC 100%);
    margin-top: -80px;
    margin-right: 20px;
}


.admin-login-wrap::v-deep .admin-overlapCheck.v-btn--disabled {
    float: right;
    width: 141px;
    height: 45px;
    color: #FFFFFF;
    font-size: 22px;
    background: #C9CECB;
    margin-top: -80px;
    margin-right: 20px;
}
.admin-login-wrap::v-deep .admin-cert {
    height: 430px;
    width: 430px;
    margin: auto;
    background: #FFFFFF;
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    color: #3491DA;
}

.admin-login-wrap::v-deep .admin-cert-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 49.03px;
    margin: 0px 100px;
    color: #3491DA;
}

.admin-login-wrap::v-deep .v-image {
    margin: auto;
}

.admin-login-wrap::v-deep .admin-checkbox-text {
    margin-left: 10px;
    height: 37.53px;
    font-size: 20px;
}

.admin-login-wrap::v-deep .admin-checkbox-text::after {
    margin-bottom: 5px;
}

.admin-login-wrap::v-deep .scm-admin-button-gradient.v-btn.v-btn--block.v-btn--disabled {
    background: #B2B2B0;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) {
    .admin-login-wrap {
        padding: 40px 0 20px 0;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .admin-login-wrap::v-deep .admin-login-actions .col {
        padding-left: 0;
        padding-right: 0;
    }

    .admin-login-wrap::v-deep .admin-login-actions .v-btn__content {
        font-size: 16px;
    }
}
</style>