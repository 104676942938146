<!--
   [2022/12/13 se.yoo]
   LoginLayout.vue
   @note 
-->
<template>
    <v-container fluid>
        <v-row
            :class="{'align-content-center': $vuetify.breakpoint.smAndDown}"
            class="login-wrap align-md-center justify-center mt-0 mt-md-n3"
        >
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="4"
            >
                <v-img
                    alt="로그인 배너 이미지"
                    :src="require('@/assets/images/login_image.png')"
                    :height="$vuetify.breakpoint.mdAndUp ? 375 : 270"
                    cover
                    class="rounded-lg"
                />
            </v-col>
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="4"
                class="login-form-wrap py-6"
            >
                <router-view />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'LoginLayout'
}
</script>

<style scoped>
.login-wrap {
    min-height: 600px;
}

.login-form-wrap {
    min-height: 400px;
    padding-left: 120px;
}

.login-form-wrap::v-deep .community-login-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    color: #303030;
}

.login-form-wrap::v-deep .community-login-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    color: #303030;
}

.login-form-wrap::v-deep .community-login-input-label {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #303030;
}

.login-form-wrap::v-deep .community-login-input {
    border-radius: 5px !important;
}

.login-form-wrap::v-deep .community-login-input .v-input__slot {
    padding: 0 24px !important;
}

.login-form-wrap::v-deep .community-login-input .v-text-field__slot input {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.login-form-wrap::v-deep .community-login-input fieldset {
    border: 1px solid #D9D9D9;
}

.login-form-wrap::v-deep .community-login-btn .v-btn__content {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
}

/* vuetify lg(<1904px) */
@media (max-width: 1903px) { 
    .login-form-wrap {
        padding-left: 40px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .login-wrap {
        min-height: calc(100vh - 80px);
        height: auto;
    }

    .login-form-wrap {
        min-height: unset;
        padding-left: 12px;
    }
}
</style>