<!--
   [2022/12/08 se.yoo]
   CommunityHomeIntroduction.vue
   @note 시민 커뮤니티 홈 소개 컴포넌트
-->
<template>
    <v-row
        align="center"
        justify="center"
        class="py-15 introduction-wrap"
    >
        <v-col
            cols="11"
            sm="10"
            md="5"
            lg="4"
        >
            <v-img
                :src="require('@/assets/images/main_introduction_image.png')"
                :height="$vuetify.breakpoint.mdAndUp ? 375 : 270"
                cover
                max-width="560"
                :alt="$t('altIntroductionImg')"
                class="rounded-xl"
            />
        </v-col>
        <v-col
            cols="11"
            sm="10"
            md="5"
            lg="4"
            class="pl-md-8 pl-lg-6"
        >
            <h2 class="scm-community-section-title">
                {{ $t('metaverseSeoulLocale') }}
            </h2>
            <div class="mt-5 home-section-content">
                {{ $t('metaverseSeoulIntroduction') }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CommunityHomeIntroduction'
}
</script>

<style scoped>
.introduction-wrap {
    min-height: 83vh;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .introduction-wrap {
        min-height: unset !important;
    }
}
</style>