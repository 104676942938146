import i18n from '../locales/i18n';

export const menus = {
    about: {
        to: '/user/about',
        name: 'aboutService',
        // sub: {
        //     about: {
        //         to: '/user/about',
        //         name: 'about',
        //     },
        //     tutorial: {
        //         to: '/user/tutorial',
        //         name: 'tutorial',
        //     }
        // }
    },
    contest: {
        to: '/user/contest',
        name: 'contest',
        subMenus: {
            entries: {
                name: 'contestEntries',
                subMenus: {
                    detail: {
                        name: 'entries',
                    }
                }
            }
        }
    }, 
    board: {
        to: '/user/board',
        name: 'notice',
        subMenus: {
            detail: {
                name: 'detail',
            }
        },
        hidden: false,
    },
    download: {
        to: '/user/download',
        name: 'download'
    }
}