<!--
   [2022/12/09 se.yoo]
   AboutServiceBottom.vue
   @note 서비스 소개 하단 컴포넌트
-->
<template>
    <v-row
        align="center"
        justify="center"
        class="py-15 about-section-wrap"
    >
        <v-col
            cols="11"
            sm="10"
            lg="8"
        >
            <v-img
                :src="require('@/assets/images/about_bottom_image.png')"
                :height="$vuetify.breakpoint.mdAndUp ? 470 : 270"
                :alt="$t('altIntroductionImg')"
                cover
                class="rounded-xl py-10 px-3 img-gradient-filter"
            >
                <h2 class="scm-community-section-title text-center">
                    {{ $t('aboutServiceBottomTitle') }}
                </h2>
                <div 
                    class="about-section-content mt-5 text-center"
                    v-html="$t('aboutServiceBottomContent')"
                />
            </v-img>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'AboutServiceBottom'
}
</script>

<style scoped>
.about-section-wrap {
    height: 81vh;
}

.about-section-content {
    color: #000000 !important;
}

.img-gradient-filter::v-deep .v-image__image:before {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background: linear-gradient(180deg, rgba(206, 232, 255, 0.83) 27.63%, rgba(206, 232, 255, 0.5) 41%, rgba(206, 232, 255, 0) 59.33%);
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .about-section-wrap {
        height: unset !important;
    }
}
</style>