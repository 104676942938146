<template>
    <v-container fluid>
        <v-img
            :src="require('@/assets/images/download_banner.png')"
            cover
            height="140"
            :alt="$t('altDownloadBannerImg')"
            class="scm-img-gradient-filter-primary full-width-in-container"
        />
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg8
            >
                <v-breadcrumbs
                    :items="menus"
                    divider=">"
                    class="board-breadcrumbs"
                />
                <tutorial-service-tab/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import TutorialServiceTab from '@/components/about/TutorialServiceTab.vue';
export default {
    name: 'TutorialService',
    data() {
        return {
        };
    },
    computed: {
        menus() {
            return [
                {
                    text: 'HOME',
                    exact: true,
                    to: { name: 'communityHome' }
                },
                {
                    text: this.$i18n.t('aboutService'),
                },
                {
                    text: this.$i18n.t('tutorial'),
                    exact: true,
                    to: { name: 'tutorialService'}
                },
            ];
        }
    },
    components: {
        TutorialServiceTab
    }
}
</script>

<style scoped>
.board-breadcrumbs,
.board-breadcrumbs::v-deep .v-breadcrumbs__item {
    font-size: 15px;
    line-height: 20px;
    color: #6C6C6C !important;
}

.board-breadcrumbs {
    margin-top: 6em;
    margin-bottom: 4em;
    padding: 0;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .board-breadcrumbs {
        margin-top: 3em;
        margin-bottom: 2em;
    }
}
</style>