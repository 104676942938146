import Vue from 'vue';
import VueRouter from 'vue-router';
import contestRoutes from './contest';
import loginRoutes from './login';
import policyRoutes from './policy';
import boardRoutes from './board';
import CommunityLayout from '../layouts/CommunityLayout.vue';
import LoginLayout from '../layouts/login/LoginLayout.vue';
import ContestLayout from '../layouts/contest/ContestLayout.vue';
import PolicyLayout from '../layouts/policy/PolicyLayout.vue';
import BoardLayout from '../layouts/board/BoardLayout.vue';
import CommunityHome from '../views/home/CommunityHome.vue';
import AboutService from '../views/about/AboutService.vue';
import TutorialService from '../views/about/TutorialService.vue';
import DownloadService from '../views/download/DownloadService.vue';
import MyPage from '../views/mypage/MyPage.vue';
import NiceSuccess from '../views/nice/NiceSuccess.vue';
import NiceFail from '../views/nice/NiceFail.vue';  
import NiceApp from '../views/nice/NiceApp.vue';
import NotFound from '../views/NotFound.vue';
import VerifyEmail from '../views/verify/VerifyEmail.vue';
import { getCurrMenuTitle, windowScrollTop } from '@/commons/functions.js';
import { store } from '@/store/store';

Vue.use(VueRouter);

const checkAuth = async function(requireAuth) {
    // 로그인 상태에 상관없는 페이지일 경우 undefined
    // undefined는 리다이렉트 안하겠다는 의미, 호출한 url로 이동
    if(requireAuth === undefined) 
        return undefined;

    // 현재 상태에 대한 리다이렉트 정의
    // 현재 로그인 상태 => 로그인 필요 페이지는 undefined, 로그아웃 필요 페이지의 경우 home
    // 현재 로그아웃 상태 => 로그인 필요 페이지는 login, 로그아웃 필요 페이지의 경우 undefined
    const loginRedirect = requireAuth ? undefined : { name: 'communityHome' };
    const logoutRedirect = requireAuth ? { name: 'communityLogin' } : undefined;

    if(!Vue.prototype.$cookies.isKey('user_token')) {
        return logoutRedirect;
    } else {
        return loginRedirect;
    }
};

const routes = [
    {
        // 임시 이동
        path: '/',
        redirect: {
            name: 'communityHome'
        }
    },
    {
        // PC버전 잘못된 URL 리다이렉트
        path: '/download',
        redirect: {
            name: 'downloadService'
        }
    },
    {
        // nice 인증 성공 화면
        path: '/nice/success',
        name: 'niceSuccess',
        component: NiceSuccess
    },
    {
        // nice 인증 실패 화면
        path: '/nice/fail',
        name: 'niceFail',
        component: NiceFail
    },
    {
        // nice인증 테스트용 임시 이동(앱)
        path: '/nice/app',
        name: 'niceApp',
        component: NiceApp
    },
    {
        path: '/policy',
        component: PolicyLayout,
        children: policyRoutes,
    },
    {
        path: '/verify-email',
        name: 'verifyEmail',
        component: VerifyEmail
    },
    {
        path: '/user',
        component: CommunityLayout,
        children: [
            {
                path: '/',
                name: 'communityHome',
                component: CommunityHome
            },
            {
                path: 'login',
                component: LoginLayout,
                children: loginRoutes,
                meta: { requireAuth: false }
            },
            {
                path: 'about',
                name: 'aboutService',
                component: AboutService
            },
            {
                path: 'tutorial',
                name: 'tutorialService',
                component: TutorialService
            },
            {
                path: 'contest',
                component: ContestLayout,
                children: contestRoutes
            },
            {
                path: 'download',
                name: 'downloadService',
                component: DownloadService
            },
            {
                path: 'board',
                component: BoardLayout,
                children: boardRoutes
            },
            {
                path: 'mypage',
                name: 'myPage',
                component: MyPage,
                meta: { requireAuth: true } 
            }
        ]
    },
    {
        path: '*',
        component: CommunityLayout,
        children: [
            {
                path: '*',
                name: 'notFound',
                component: NotFound
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    // 라우터 들어가기 전 로딩 스피너 시작
    store.commit('startSpinner');

    let requireAuth = to.matched.some(item => item.meta.requireAuth);

    if(!requireAuth) {
        // 로그아웃 상태 필수(false) 값이 있는지 확인
        const isNotUndefined = to.matched.some(item => item.meta.requireAuth === false);
        requireAuth = isNotUndefined? false : undefined;
    }

    const redirect = await checkAuth(requireAuth);

    if(redirect) {
        setTimeout(() => {
            next(redirect);
        }, 1);
    } else {        
        setTimeout(() => {
            next();
        }, 1);
    }
});

router.afterEach((to, from) => {
    // 라우터 들어가면 로딩 스피너 종료
    store.commit('endSpinner');

    windowScrollTop();
    document.body.tabIndex = 0;
    document.body.focus();
    document.body.tabIndex = undefined;
    document.title = `${getCurrMenuTitle(to)}`;
});

export default router;