<!--
   [2024/05/31 sb.hwang]
   DownloadServiceApp.vue
   @note 다운로드 페이지 앱 다운로드 컴포넌트
-->
<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg6
            >
                <h2 id="main"
                    class="scm-community-section-title mt-15 mb-2">
                    {{ $t('download') }}
                </h2>
                <v-row
                    justify="start"
                    class="py-5"
                >
                    <v-col
                        xl="3"
                        lg="4"
                        cols="12"
                    >
                        <black-icon-btn
                            url="https://play.google.com/store/apps/details?id=kr.go.seoul.metaverse"
                            icon="mdi-android"
                            btn-name="Google Play"
                            label="구글 플레이"
                        />
                    </v-col>
                    <v-col
                        xl="3"
                        lg="4"
                        cols="12"
                    >
                        <black-icon-btn
                            url="https://apps.apple.com/kr/app/id1660175436"
                            icon="mdi-apple"
                            btn-name="App Store"
                            label="앱 스토어"
                        />
                    </v-col>
                </v-row>

                <v-divider/>
                
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import BlackIconBtn from '@/components/com/BlackIconBtn.vue';

export default {
    name: 'DownloadServiceApp',
    components:{
        BlackIconBtn
    },
    data() {
        return {
        }
    },
    methods: { 
    },
    created() {
    }
}
</script>

<style scoped>

.download-section-button {
    background: #0A0A0A !important;
    border-radius: 10px !important;
    width: 235px !important;
    height: 70px !important;
    color: white !important;
    box-shadow: none !important;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
}

.download-table {
    text-align: center;
}
.download-table th {
    font-weight: bold;
}

table th + th,
table td + td { 
    border-left:1px solid #dddddd; 
}


.theme--light.v-data-table td:nth-child(2),
.theme--light.v-data-table td:nth-child(3) {
    width: 40%;
}
</style>