<template>
    <v-container fluid>
        <slot name="header">
            <v-row align="center">
                <v-col class="pl-0 pl-sm-3">
                    <h1 class="scm-community-title">
                        {{ title || $t('title') }}
                    </h1>
                </v-col>
                <v-col
                    v-if="!hide"
                    cols="12"
                    md="7"
                    lg="7"
                    class="px-0 px-md-3"
                >
                    <search-form
                        ref="searchForm"
                        :types="searchTypes"
                        :hide-type="hideSearchType"
                        :hide-date="hideSearchDate"
                        @search="$emit('search', $event)"
                    />
                </v-col>
            </v-row>
        </slot>
        <v-divider
            v-if="!hide"
            class="scm-community-divider mb-2" 
        />
        <sort-item
            class="mb-2"
            :hide-type="hideSortType"
            :item-order="itemOrder"
            @reverse="$emit('reverse')"
        />
        <v-container class="scm-comunity-content-wrap px-0 px-sm-3">
            <slot name="content">
                {{ $t('noData') }}
            </slot>
        </v-container>
    </v-container>
</template>

<script>
import SearchForm from './SearchForm.vue';
import SortItem from './SortItem.vue';

export default {
    name: 'MenuDetail',
    props: {
        title: {
            // 메뉴 제목
            type: String
        },
        hide: {
            // 검색폼 여부
            type: Boolean,
            default: false
        },
        hideSearchDate: {
            // 검색폼의 기간 숨김 여부
            type: Boolean,
            default: false
        },
        hideSearchType: {
            // 검색폼의 타입 숨김 여부
            type: Boolean,
            default: false
        },
        searchTypes: {
            // 검색 타입 목록
            type: Array,
            default: () => { return []; }
        },
        itemOrder: {
            type: Boolean,
            default: false
        },
        hideSortType: {
            type: Boolean,
            default: false
        }
    },
    components: { 
        SearchForm,
        SortItem
    }
}
</script>

<style scoped>
.row {
    margin: 0px;
}
</style>
