<!--
   [2022/10/06 se.yoo]
   CommunityHeader.vue
   @note 시민 커뮤니티 웹 페이지 헤더
-->
<template>
    <v-app-bar
        app
        dark
        height="80"
        elevation="0"
        class="community-header"
    >
        <v-app-bar-nav-icon
            v-if="isMobile"
            id="navIcon"
            aria-label="navIcon"
            @click="$emit('onControlMenu')"
        />
        <v-row
            :no-gutters="isMobile"
            align="center"
            class="col-lg-8 col-4 ma-auto pa-0"
        >
            <v-col
                v-show="!isMobile"
                cols="auto"
            >
                <v-img
                    :src="require('@/assets/images/scm-community-logo.svg')"
                    :alt="$t('altLogoImg')"
                    contain
                    height="30"
                    max-width="190"
                    class="cursor-pointer"
                    tabindex="0"
                    @click="routerHome"
                />
            </v-col>
            <v-col
                v-show="!isMobile"
                cols="auto"
            >
                <ul class="community-menus">
                    <template v-for="(menu, i) in menus">
                        <li
                            v-if="!menu.hidden"
                            :key="`community-menus-item-${i}`"
                            class="community-menus-item"
                        >
                            <v-menu
                                open-on-hover
                                offset-y
                                dark
                                nudge-left="3"
                            >
                                <template v-slot:activator="{on, attrs}">
                                    <v-list-item
                                        link
                                        :to="menu.to"
                                        tabindex="0"
                                        class="custom-mouse-style"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ $i18n.t(menu.name) }}
                                    </v-list-item>
                                </template>

                                <v-list 
                                    v-if="menu.sub != null" 
                                >
                                    <v-list-item
                                        v-for="(subMenu, index) in menu.sub"
                                        :key="`community-submenus-item-${index}`"
                                        class="community-submenus-item"
                                        link
                                        :to="subMenu.to"
                                    >
                                        <v-list-item-title>
                                            {{ $i18n.t(subMenu.name) }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </li>
                    </template>
                </ul>
            </v-col>
        </v-row>
        <div style="display: flex; align-items: center;">
            <div style="display: flex; justify-content: flex-end;">
                <v-select
                    v-model="translation"
                    :items="translations"
                    :menu-props="{
                        offsetY: true,
                        contentClass: 'community-header-select-menu',
                        openOnFocus: true
                    }"
                    dense
                    rounded
                    outlined
                    hide-details
                    height="30"
                    class="community-header-select"
                    :aria-label="$t('hompage')+' '+$t('language')"
                    @change="changeLocale()"
                />
            </div>
            <v-menu 
                v-if="loggedIn"
                open-on-hover
                offset-y 
                dark
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon size="40">
                            mdi-account-circle
                        </v-icon>
                        <span class="d-sr-only">마이페이지 및 로그아웃</span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="routerMyPage">
                        <v-list-item-title class="py-2"> {{ $t('userinfo') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-title class="py-2"> {{ $t('logout') }} </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn
                v-else
                icon
                @click="routerLogin"
            >
                <v-icon size="40">
                    mdi-account-circle
                </v-icon>
                <span class="d-sr-only">로그인</span>
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>
import { menus } from '@/commons/menus.js';

export default {
    name: 'CommunityHeader',
    data() {
        return{
            translations: [
                { text: '한국어', value: 'ko' },
                { text: 'ENG', value: 'en' }
            ],
            menus
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        loggedIn() {
            return this.$cookies.isKey('user_token');
        },
        isMobile() {
            return this.$vuetify.breakpoint.lgAndDown;
        },
        translation: {
            get() {
                return this.$store.state.user.translation
            },
            set(value) {
                this.$store.commit('setTranslation', value)
            }
        }
    },
    methods:{
        /**
         * @method routerHome
         * @note 메인화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerHome() {
            if(this.$route.name != 'communityHome') {
                this.$router.push({
                    name: 'communityHome'
                }).catch(()=>{});
            }
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            }).catch(()=>{});
        },
        /**
         * @method logout
         * @note 로그아웃하는 함수
         * @email se.yoo@naviworks.com
         */
        logout() {
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            this.$store.dispatch('resetUser');
            location.href = '/';
        },
        /**
         * @method changeLocale
         * @note 한영 번역기 선택 함수
         * @email se.yoo@naviworks.com
         */
        changeLocale(){
            this.$i18n.locale = this.translation;
        },
        /**
         * @method routerMyPage
         * @note 사용자 정보 화면으로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        routerMyPage() {
            this.$router.push({
                name: 'myPage'
            }).catch(()=>{});
        }
    },
    created() {
        this.$i18n.locale = this.translation;
    }
}
</script>

<style scoped>
.community-header {
    background-color: #0A0A0A !important;
    max-width: 100vw;
}

.community-menus-item::v-deep .v-list-item {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: unset;
    color: #D9D9D9 !important;
}

.community-menus {
    display: flex;
}

.community-menus-item {
    list-style: none;
    padding: 0 30px;
}

.community-menus-item::v-deep .v-list-item {
    border-bottom: 1px solid transparent;
}

.community-menus-item:hover .v-list-item,
.community-menus-item .v-list-item:focus {
    color: #FFFFFF !important;
    border-bottom: 1px solid #FFFFFF;
}

.community-menus-item::v-deep .v-list-item--active {
    font-weight: 500;
    color: #FFFFFF !important;
}

.community-header-btn {
    width: 120px;
    height: 40px !important;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
}

.community-header-btn::v-deep .v-btn__content{
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
}

.community-header-select{
    width: 50%;
}

.community-header-select::v-deep input {
    cursor: pointer;
}

.community-header-mypage::v-deep .v-icon::before {
    cursor: pointer;
}

.v-list-item {
    text-align: center !important;
}

.v-list-item::v-deep .v-list-item__title{
    overflow-x: hidden !important;
}
</style>