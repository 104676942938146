<!--
   [2022/10/07 se.yoo]
   CommunityHomeQuickmenu.vue
   @note 시민 커뮤니티 홈 퀵메뉴 컴포넌트
-->
<template>
    <v-layout
        align-center
        justify-center
        class="quick-menu-wrap"
    >
        <v-flex
            id="quick"
            xs11
            sm10
            lg8
            class="d-md-flex flex-wrap justify-space-between"
        >
            <template v-for="(menu, key) in menus">
                <v-card
                    v-if="!menu.hidden"
                    :key="`quick-menu-${menu.name}`"
                    rounded="xl"
                    elevation="0"
                    color="#EAF2F5"
                    class="quick-menu text-center py-6"
                    @click="routerMenu(menu)"
                    @keydown.enter.stop="routerMenu(menu)"
                >
                    <image-print
                        :src="getImage(key)"
                        contain
                        width="150"
                        height="150"
                        class="pt-3 mx-auto quick-menu-image"
                    />
                    <v-card-title class="scm-community-section-title justify-center mt-lg-10 pa-0">
                        {{ $i18n.t(menu.name) }}
                    </v-card-title>
                    <v-card-text class="home-section-content justify-center my-3 px-8">
                        <span v-html="$i18n.t(`${menu.name}Description`)" />
                    </v-card-text>
                </v-card>
            </template>
        </v-flex>
    </v-layout>
</template>

<script>
import ImagePrint from '../com/ImagePrint.vue';
import { menus } from '@/commons/menus.js';

export default {
    name: 'CommunityHomeQuickmenu',
    components: { 
        ImagePrint
    },
    data() {
        return {
            menus,
        }
    },
    methods: {
        /**
         * @method routerMenu
         * @param {object} menu 메뉴 정보
         * @note 해당 메뉴로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerMenu(menu) {
            if(menu.name == 'aboutService') {
                this.$router.push(menu.sub.about.to);
            }
            if(menu.to && menu.to != '') {
                this.$router.push(menu.to);
            }
        },
        /**
         * @method getImage
         * @param {string} key 메뉴 정보(영문명)
         * @returns {string} 이미지 주소
         * @note 해당 메뉴 퀵메뉴 아이콘 이미지를 반환하는 함수
         * @email se.yoo@naviworks.com
         */
        getImage(key) {
            return `assets/images/ICON_${key}.png`;
        }
    }
}
</script>

<style scoped>
.quick-menu {
    max-width: 45%;
    width: 45%;
    margin: 50px 0px;
}

.quick-menu::v-deep .v-card__title {
    word-break: keep-all;
}

.quick-menu-image {
    margin-top: -99px;
}

.home-section-content {
    word-break: keep-all;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .quick-menu-wrap {
        height: unset !important;
        min-height: unset !important;
    }

    .quick-menu {
        max-width: 100%;
        width: 100%;
        margin-top: 99px;
    }
}
</style>