<template>
    <v-layout align-center>
        <v-flex
            v-if="types && !hideType"
            class="px-2"
        >
            <v-select
                v-model="type"
                :title="$t('type')"
                :items="types"
                :item-text="typeItemText"
                :item-value="typeItemValue"
                :menu-props="{
                    offsetY: true
                }"
                dense
                outlined
                hide-details
                height="40"
                class="search-form-input"
            />
        </v-flex>
        <v-flex 
            v-if="!hideDate"
            class="pr-2"
        >
            <v-text-field
                :value="printDates"
                dense
                outlined
                readonly
                hide-details
                height="40"
                placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                :aria-label="$t('search')+$t('period')"
                class="search-form-input"
                @keydown.delete="deleteDate"
                @focus.stop="(datePicker = true)"
            />
            <v-date-picker
                v-show="datePicker"
                v-model="date"
                :header-date-format="headerDateFormat"
                range
                no-title
                scrollable
                color="blue darken-2"
                class="mt-1 search-date-picker"
                @input="inputDate"
            >
                <v-spacer/>
                <v-btn
                    text
                    color="blue darken-2"
                    @click="deleteDate"
                >
                    {{  $t('reset') }}
                </v-btn>
                <v-btn
                    text
                    color="blue darken-2"
                    @click="(datePicker = false)"
                >
                    {{ $t('cancel') }}
                </v-btn>
            </v-date-picker>
        </v-flex>
        <v-flex class="pr-2">
            <v-text-field
                v-model="keyword"
                dense
                :aria-label="$t('keyword')"
                outlined
                hide-details
                height="40"
                :placeholder="$t('keyWord')"
                class="search-form-input"
                @keydown.enter="onSearch"
            />
        </v-flex>
        <v-flex class="pr-2">
            <v-btn
                width="75"
                height="40"
                class="scm-community-button-primary"
                @click="onSearch"
            >
                {{$t('search')}}
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SearchForm',
    props: {
        types: {
            // 검색 타입 목록
            type: Array
        },
        typeItemText: {
            // 검색 타입 이름 속성명
            type: String,
            default: 'text'
        },
        typeItemValue: {
            // 검색 타입 값 속성명
            type: String,
            default: 'value'
        },
        hideType: {
            // type 숨김 여부
            type: Boolean,
            default: false
        },
        hideDate: {
            // 기간 숨김 여부
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            type: '',
            keyword: '',
            date: [],
            datePicker: false
        };
    },
    computed: {
        ...mapGetters({
            search: 'getSearchInfo',
            searchPayload: 'getSearchPayload'
        }),
        sortedDate() {
            return [...this.date].sort();
        },
        printDates() {
            return this.date && this.date.length > 0 ? this.sortedDate.join(' ~ ') : '';
        }

    },
    methods: {
        onSearch() {
            this.$store.commit('setSearchType', this.type);
            this.$store.commit('setSearchKeyword', this.keyword);
            this.$store.commit('setSearchDate', this.date);

            this.$emit('search');
        },
        headerDateFormat(date){
            let dateSplit = date.split('-');
            if(1 === dateSplit.length){
                return dateSplit[0];
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
        deleteDate() {
            this.date = [];
            this.datePicker = false;
        },
        inputDate() {
            if(this.date.length >= 2) this.datePicker = false;
        }
    },
    created() {
        let routerNm = this.$route.name;

        this.$store.dispatch('checkSearchReset', routerNm)
        .then(() => {
            this.keyword = this.search.keyword;
            this.type = this.search.type;
            this.date = this.search.date;
        });
    }
}
</script>

<style scoped>
.flex {
    flex: 3 1 0;
}
.flex:nth-last-child(1) {
    flex: 1 1 0;
}
.search-form-input {
    padding: 0;
    margin: 0;
    background: #FFF;
    border-radius: 5px !important;
}

.search-form-input::v-deep .v-input__slot::before{
    border: 0px;
}

.search-form-input::v-deep .v-select__selection,
.search-form-input::v-deep .v-text-field__slot input {
    font-size: 15px;
    line-height: 100%;
}

.search-form-input::v-deep .v-input__slot {
    /* padding: 5px 9px !important; */
    min-height: 40px !important;
}

.search-form-input::v-deep fieldset {
    border: 1px solid #D9D9D9;
}
.search-form-input::v-deep input {
    padding: 0 !important;
}

.search-form-input::v-deep .v-select__slot input {
    cursor: pointer;
}
.search-form-input::v-deep .v-select__slot .v-input__append-inner {
    align-self: auto;
}

.search-form-input::v-deep .v-input__append-inner {
    margin-top: 0px !important;
}

.search-form-input::v-deep .v-input__append-inner .v-input__icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
}

.search-form-input::v-deep .v-input__append-inner .v-input__icon .v-icon {
    font-size: 20px;
}

.search-date-picker{
    position: absolute !important;
    z-index: 1;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.search-date-picker::v-deep{
    color: red;
}

</style>