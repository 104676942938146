<!--
   [2022/10/06 se.yoo]
   CommunityLayout.vue
   @note 시민 커뮤니티 웹 페이지 레이아웃
-->
<template>
    <v-app>
        <div class="scm-community-skip">
            <a
                v-for="menu in activeSkipMenus"
                :key="menu.id"
                :href="menu.id"
            >
                {{ menu.text }} {{ $t('shortcut')}}
            </a>
        </div>
        <community-header @onControlMenu="showNav = !showNav" />
        <community-nav :show-nav.sync="showNav" />
        <v-main class="community-main-wrap">
            <router-view />
        </v-main>
        <community-footer />
    </v-app>
</template>

<script>
import CommunityHeader from './CommunityHeader.vue';
import CommunityNav from './CommunityNav.vue';
import CommunityFooter from './CommunityFooter.vue';

export default {
    name: 'CommunityLayout',
    components: {
        CommunityHeader,
        CommunityNav,
        CommunityFooter
    },
    data() {
        return {
            showNav: false,
            activeSkipMenus: []
        };
    },
    computed: {
        skipMenus() {
            return [
                { text: this.$t('login'), id: '#login' },
                { text: this.$t('logout'), id: '#logout' },
                { text: this.$t('main'), id: '#main' },
                { text: this.$t('search'), id: '#filter' },
                { text: this.$t('list'), id: '#list' },
                { text: this.$t('content'), id: '#content' }
            ];
        }
    },
    methods: {
        /**
         * @method setActiveSkipMenus
         * @note 현재 활성화 스킵메뉴 목록을 설정하는 함수
         * @email se.yoo@naviworks.com
         */
        setActiveSkipMenus() {
            this.$nextTick(() => {
                this.activeSkipMenus = this.skipMenus.filter(menu => {
                    const menuEle = document.querySelector(menu.id);

                    return menuEle !== null;
                });
            });
        }
    },
    mounted() {
        this.setActiveSkipMenus();
    },
    watch: {
        $route() {
            this.setActiveSkipMenus();
        },
        skipMenus() {
            this.setActiveSkipMenus();
        }
    }
}
</script>

<style scoped>
.community-main-wrap {
    padding-top: 80px !important;
}
</style>