import { render, staticRenderFns } from "./ContestLayout.vue?vue&type=template&id=137a9aea&scoped=true&"
import script from "./ContestLayout.vue?vue&type=script&lang=js&"
export * from "./ContestLayout.vue?vue&type=script&lang=js&"
import style0 from "./ContestLayout.vue?vue&type=style&index=0&id=137a9aea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137a9aea",
  null
  
)

export default component.exports