import { axiosService } from "@/commons/api";
import _ from 'loadsh';

const state =
{
    emlAddr: '',
    brdt: '',
    gndrCd: 0,
    pswdCn: '',
    nickNm: '',
    enc: '',
    token: '',
    emlCertifed: false,
    rcvryEmlCertifed: false,
    translation: 'ko',
    representative: {
        name: null,
        mobileNo: null,
        relation: null,
        enc: '',
    },
    minor: {
        name: '',
        mobileNo: '',
        birthDate : '',
    },
    crtDt: '',
    userNm: '',
    mblTelno: '',
    rcvryEmlAddr: '',
    sttsMsgCn: '',
};

const actions=
{
    /**
     * @method resetUser
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email se.yoo@naviworks.com
     */
    resetUser(context) {
        const emptyUser = {};

        context.commit('setUser', emptyUser);
    },
    /**
     * @method resetUser
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email se.yoo@naviworks.com
     */
    resetRepresentative(context) {
        const emptyRepresentative = {name: null, mobileNo: null, relation: null};

        context.commit('setRepresentative', emptyRepresentative);
    },
    /**
     * @method resetUser
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email se.yoo@naviworks.com
     */
    resetMinor(context) {
        const emptyMinor = {name: '', mobileNo: '', birthDate: ''};

        context.commit('setMinor', emptyMinor);
    },
    /**
     * @method insertAccount
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 계정을 등록하는 함수(회원가입)
     * @email sh.hong@naviworks.com
     */
    insertAccount({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method accountLogin
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보 (아이디, 비밀번호)
     * @note 사용자의 계정을 확인하는 함수(로그인)
     * @email sh.hong@naviworks.com
     */
    accountLogin(context, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/user/login`, params)
            .then(res => {
                context.commit('setUser', res.data.user);
                context.commit('setUserToken', res.data.token);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method overlapCheck
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자가 입력한 정보의 중복을 확인하는 함수
     * @email sh.hong@naviworks.com
     */
    overlapCheck({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`scm/accounts/checkDupId`, { params: params })
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method findId
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 아이디를 검색하는 함수
     * @email sh.hong@naviworks.com
     */
    findId({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/findId`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method checkId
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 아이디를 확인하는 함수
     * @email sh.hong@naviworks.com
     */
    checkId({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/checkId`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },  
    /**
     * @method checkCi
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 CI 중복을 확인하는 함수
     * @email jake.jeon@naviworks.com
     */
    checkCi({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`scm/accounts/check-ci`, { params: params})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },  
    /**
     * @method checkOver
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 19세 이상 여부를 확인하는 함수
     * @email jake.jeon@naviworks.com
     */
    checkOver({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/check-over`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },  
    /**
     * @method checkUnder
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 14세 미만 여부를 확인하는 함수
     * @email jake.jeon@naviworks.com
     */
    checkUnder({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/check-under`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },  
    /**
    * @method checkPwd
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 사용자의 이전 비밀번호를 확인하는 함수
    * @email sb.hwang@naviworks.com
    */
    checkPwd({}, {params, headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`scm/accounts/checkPswdCn`, params, {headers})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
                console.clear();
            });
        });
    },
    /**
     * @method resetPwd
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 사용자의 비밀번호를 변경하는 함수
     * @email sh.hong@naviworks.com
     */
    resetPwd({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`scm/accounts/updatePswd`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method emlCertifySend
     * @param {object} context 컨텍스트 객체
     * @param {object} params 이메일
     * @note 이메일 인증을 진행하는 함수 
     * @email jake.jeon@naviworks.com
     */
    emlCertifySend({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/verification/email/continue-url`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method emlCertifyCheck
     * @param {object} context 컨텍스트 객체
     * @param {object} params 이메일
     * @note 이메일 인증 여부를 확인하는 함수 
     * @email jake.jeon@naviworks.com
     */
    emlCertifyCheck({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/verification/email/check`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method emlCheckId
     * @param {object} context 컨텍스트 객체
     * @param {object} params 이메일
     * @note 비밀번호 찾기를 위한 이메일 인증을 진행하는 함수 
     * @email jake.jeon@naviworks.com
     */
    emlCheckId({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/accounts/email/checkId`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method emlUpdatePswd
     * @param {object} context 컨텍스트 객체
     * @param {object} params 이메일
     * @note 이메일 인증 사용자를 위한 비밀번호 찾기 함수
     * @email jake.jeon@naviworks.com
     */
    emlUpdatePswd({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`/scm/accounts/email/updatePswd`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    
    /**
     * @method insertAccountEmail
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 이메일 인증을 통한 사용자의 계정을 등록하는 함수(회원가입)
     * @email jake.jeon@naviworks.com
     */
    insertAccountEmail({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/accounts/email`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
    * @method updateRcvryEmail
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 복구 이메일을 등록하고 수정하는 함수
    * @email sb.hwang@naviworks.com
    */
    updateRcvryEmail({}, {params, headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`/scm/accounts/recovery-email`, params, {headers})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
    * @method findIdRcvryEmail
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 복구 이메일로 아이디 찾는 함수
    * @email sb.hwang@naviworks.com
    */
    findIdRcvryEmail({}, params) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .post(`/scm/accounts/findId/recovery-email`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
    * @method deleteUser
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 회원 탈퇴하는 함수
    * @email sb.hwang@naviworks.com
    */
    deleteUser({}, {headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .delete(`/scm/accounts`, {headers})
            .then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
    * @method updateProfile
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 상태메시지를 변경하는 함수
    * @email sb.hwang@naviworks.com
    */
    updateProfile({}, {params, headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`/scm/accounts`, params, {headers})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        })
    },
    /**
    * @method updatePwd
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 비밀번호를 변경하는 함수
    * @email sb.hwang@naviworks.com
    */
    updatePwd({}, {params, headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`/scm/accounts/password`, params, {headers})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        })
    },
    /**
    * @method changeEmailToPhone
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 이메일 인증 회원 인증방식 전환 함수
    * @email sb.hwang@naviworks.com
    */
    changeEmailToPhone({}, {params, headers}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .put(`/scm/accounts/nice`, params, {headers})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};

const getters=
{
    getUser: function (state) {
        return {
            crtDt: state.crtDt,
            userNm: state.userNm,
            brdt: state.brdt,
            nickNm: state.nickNm,
            emlAddr: state.emlAddr,
            gndrCd: state.gndrCd,
            mblTelno: state.mblTelno,
            rcvryEmlAddr: state.rcvryEmlAddr,
            sttsMsgCn: state.sttsMsgCn,
            token: state.token,
        };
    },
    getSignUpUser: function (state) {
        return {
            emlAddr: state.emlAddr,
            pswdCn: state.pswdCn,
            nickNm: state.nickNm,
            enc: state.enc,
        }
    },
    getEmailSignUpUser: function (state) {
        return {
            emlAddr: state.emlAddr,
            pswdCn: state.pswdCn,
            nickNm: state.nickNm,
            brdt: state.brdt,
            gndrCd: state.gndrCd,
        }
    },
    getTranslation: function (state) {
        return {
            translation: state.translation
        }
    },
    getEmlCertified: function (state) {
        return {
            emlCertifed: state.emlCertifed
        }
    },
    getRcvryEmlCertifed: function (state) {
        return {
            rcvryEmlCertifed: state.rcvryEmlCertifed
        }
    },
    getToken: function(state) {
        return {
            token: state.token
        }
    },
    getRcvryEml: function(state) {
        return {
            rcvryEmlAddr: state.rcvryEmlAddr,
        }
    },
    getSttsMsgCn: function(state) {
        return {
            sttsMsgCn: state.sttsMsgCn,
        }
    },
};

const mutations= 
{
    setUser(state, user) {
        state.emlAddr = user.emlAddr || '';
        state.brdt = user.brdt || '';
        state.gndrCd = user.gndrCd || 0;
        state.pswdCn = user.pswdCn || '';
        state.nickNm = user.nickNm || '';
        state.enc = user.enc || '';
        state.token = user.token || '';
        state.emlCertifed = user.emlCertifed || false;
        state.crtDt = user.crtDt || '';
        state.userNm = user.userNm || '';
        state.mblTelno = user.mblTelno || '';
        state.rcvryEmlAddr = user.rcvryEmlAddr || '';
        state.sttsMsgCn = user.sttsMsgCn || '';
    },
    setUserEmlAddr(state, emlAddr) {
        state.emlAddr = emlAddr;
    },
    setUserRcvryEmlAddr(state, rcvryEmlAddr) {
        state.rcvryEmlAddr = rcvryEmlAddr;
    },
    setUserSttsMsgCn(state, sttsMsgCn) {
        state.sttsMsgCn = sttsMsgCn;
    },
    setUserGndrCd(state, gndrCd) {
        state.gndrCd = gndrCd
    },
    setUserBrdt(state, brdt) {
        state.brdt = brdt
    },
    setUserPswdCn(state, pswdCn) {
        state.pswdCn = pswdCn;
    },
    setUserNickNm(state, nickNm) {
        state.nickNm = nickNm;
    },
    setUserEnc(state, enc) {
        state.enc = enc;
    },
    setUserEmlCertifed(state, emlCertifed) {
        state.emlCertifed = emlCertifed
    },
    setUserRcvryEmlCertifed(state, rcvryEmlCertifed) {
        state.rcvryEmlCertifed = rcvryEmlCertifed
    },
    setUserToken(state, token) {
        state.token = token;
    },
    setTranslation(state, translation) {
        state.translation = translation;
    },
    setRepresentative(state, representative) {
        state.representative = representative;
    },
    setMinor(state, minor) {
        state.minor = minor;
    },
    setUserMblTelNo(state, mblTelno) {
        state.mblTelno = mblTelno;
    },
    setUserNm(state, userNm) {
        state.userNm = userNm;
    },
};

export default
{
    state, actions, getters, mutations
};