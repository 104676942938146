<!--
   [2022/12/14 se.yoo]
   CommunityFindPwd.vue
   @note 비밀번호 찾기 아이디 확인 컴포넌트
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            {{ $t('enterIdforPassword') }}
        </p>
        <div class="community-login-input-label mt-9">
            {{ $t('id') }}
        </div>
        <v-form ref="idForm">
            <v-text-field
                v-model="emlAddr"
                :rules="[rules.email, rules.required]"
                :disabled="this.$store.state.user.emlCertifed"
                placeholder="ex) metaseoul@meta.co.kr"
                height="47"
                class="mt-0 pt-0"
                @keydown.enter="checkId"
            />
        </v-form>
        <v-row
            no-gutters
            class="pwd-actions justify-end"
        >
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    :disabled="disabledNext"
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    @click="checkId"
                >
                    {{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="alertWarning"
            :title="$t('notFindIdTitle')"
            :content="$t('notFindId')"
        />
    </div>
</template>

<script>
import AlertDialog from '../com/AlertDialog.vue';
import { rules, findStep } from '@/commons/resources.js';

export default{
    name: 'CommunityFindPwd',
    components:{
        AlertDialog
    },
    data(){
        return{
            disabledNext: true,
            alertWarning: false,
            rules,
        }
    },
    computed: {
        user() {
            return this.$store.getters.getSignUpUser;
        },
        emlAddr: {
            get() {
                return this.$store.state.user.emlAddr;
            },
            set(value) {
                this.$store.commit('setUserEmlAddr', value);
            }
        }
    },
    methods:{
        /**
         * @method checkId
         * @note 아이디 확인 함수
         * @email sh.hong@naviworks.com
         */
        checkId(){
            if (!this.$store.state.user.emlCertifed) {
                this.$store.dispatch('checkId', this.user)
                .then(() => {
                    this.nextPage();
                }).catch(err => {
                    this.alertWarning = true;
                })
            } else {
                this.nextPage();
            }
        },
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            this.$emit('nextPage', findStep.ResetPwd);
        }
    },
    mounted() {
        const validate = this.$refs.idForm.validate();

        if(!validate){
            this.disabledNext = true;
        } else{
            this.disabledNext = false;
        }
    },
    watch:{
        emlAddr(){
            const validate = this.$refs.idForm.validate();

            if(!validate){
                this.disabledNext = true;
            } else{
                this.disabledNext = false;
            }
        },
    }
}
</script>

<style scoped>
.pwd-actions {
    margin-top: 76px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .pwd-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>