<template>
    <v-container class="pa-0">
        <div
            v-for="(policy, i) in text"
            :key="i"
            class="cursor-pointer"
            @click="changePolicy(i)"
        >
            {{ $t(policy) }}
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'PrivacyBeforePolicy',
    props: {
        text: Array,
        index: Number
    },
    data() {
        return {
        }
    },
    methods: {
        changePolicy(i) {
            this.$emit('changePolicy', i);
        }
    }

}
</script>

<style scoped>

</style>