<template>
  <v-container class="mt-n8 px-0 px-sm-3">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      show-arrows
      class="mb-3"
    >
      <v-tab
        v-for="(item, index) in items"
        :key="index"
      >
        {{ item.srvcNm }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, index) in items"
        :key="index"
        :transition="false"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
            lg="7"
          >
            <div class="tutorial-video">
              <video
                controls
                :src="item.uploadFileAddr"
                :alt="`튜토리얼 동영상_${item.srvcNm}`"/>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="5"
          >
            <v-row class="fill-height pt-3">
              <v-col
                cols="12"
                md="6"
                lg="12"
                class="pt-0 pt-md-3"
              >
                <div class="scm-community-title tutorial-title">{{ item.srvcNm }} 튜토리얼</div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="12"
                align-self="end"
              >
                <v-img
                  :src="require('@/assets/images/metaverse_key_guide.png')"
                  contain
                  height="265"
                  :alt="$t('tutorialImg')"
                  class="tutorial-key-guide"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        tab: null,
        items: [],
      }
    },
    methods: {
      selectTutorialList() {
        this.$store.dispatch('selectTutorialList')
        .then((res) => {
          this.items = res;
        })
      }
    },
    created() {
      this.selectTutorialList();
    }
  }
</script>

<style scoped>
.tutorial-video {
    height: 428px;
    text-align: center;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.fill-height {
  height: 100%;
}

.tutorial-title {
  word-break: keep-all;
}

.tutorial-key-guide::v-deep .v-image__image {
  background-position: left bottom !important;
}

@media (min-width: 959px) and (max-width: 1263px) { 
  .tutorial-key-guide::v-deep .v-image__image {
    background-position: right bottom !important;
  }
}
@media (max-width: 959px) { 
    .tutorial-video {
        display: table-cell;
        vertical-align: middle;
        height: auto;
    }
    video {
        object-fit: fill;
        height: auto;
    }
}

</style>