import { axiosService } from "@/commons/api";

const state =
{
    ctstSn: 0,
    ctstNm: '',
    ctstRegDt: '',
    rcptBgngDt: '',
    rcptEndDt: '',
    pzwngwrPrsntnDt: '',
    tpcNm: '',
    etrTypeCd: '',
    pmtImgFileSn: '',
    prsetFileSn: '',

    contests: []
};

const actions=
{
    /**
     * @method resetContest
     * @param {object} context 컨텍스트 객체
     * @note 공모전 state를 초기화 하는 함수
     * @email gy.yang@naviworks.com
     */
    resetContest(context) {
        const emptyContest = {};

        context.commit('setContest', emptyContest);
    },
    /**
     * @method selectContestList
     * @param {Object} context 컨텍스트 객체
     * @returns {Promise} 결과 데이터
     * @note 공모전 목록을 반환하는 함수
     * @email gy.yang@naviworks.com
     */
    selectContestList(context) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get('scm/ctst/user')
            .then(res => {
                context.commit('setContests', res.data.contests);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method selectContestDetail
     * @param {Object} context 컨텍스트 객체
     * @param {Number} ctstSn 검색할 공모전 일련번호
     * @returns {Promise} 결과 데이터
     * @note 공모전 상세 정보를 반환하는 함수
     * @email gy.yang@naviworks.com
     */
     selectContestDetail(context, ctstSn) {
        return new Promise(function(resolve, reject)
        {
            const params = {
                ctstSn: ctstSn
            };
            axiosService
            .get('scm/ctst/user', { params: params })
            .then(res => {
                context.commit('setContest', res.data.contests);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    }
};

const getters=
{
    getContest: function (state) {
        return {
            ctstSn: state.ctstSn,
            ctstNm: state.ctstNm,
            ctstRegDt: state.ctstRegDt,
            rcptBgngDt: state.rcptBgngDt,
            rcptEndDt: state.rcptEndDt,
            pzwngwrPrsntnDt: state.pzwngwrPrsntnDt,
            tpcNm: state.tpcNm,
            etrTypeCd: state.etrTypeCd,
            pmtImgFileSn: state.pmtImgFileSn,
            prsetFileSn: state.prsetFileSn
        };
    },
    getContests: function (state) {
        return _.cloneDeep(state.contests);
    }
};

const mutations= 
{
    setContest(state, contest) {
        state.ctstSn = contest.ctstSn || 0;
        state.ctstNm = contest.ctstNm || '';
        state.ctstRegDt = contest.ctstRegDt || '';
        state.rcptBgngDt = contest.rcptBgngDt || '';
        state.rcptEndDt = contest.rcptEndDt || '';
        state.pzwngwrPrsntnDt = contest.pzwngwrPrsntnDt || '';
        state.tpcNm = contest.tpcNm || '';
        state.etrTypeCd = contest.etrTypeCd || '';
        state.pmtImgFileSn = contest.pmtImgFileSn || '';
        state.prsetFileSn = contest.prsetFileSn || '';
    },
    setContests(state, contests) {
        state.contests = contests;
    }
};

export default
{
    state, actions, getters, mutations
};