<template>
    <v-container>
        <slot name="title">
            <div class="article-title">
                {{ item[itemTitle] }}
            </div>
            <v-divider />
        </slot>
        <slot name="info">
            <div class="article-info">
                <template v-for="(info, i) in articleInfos">
                    <div 
                        v-if="info.isFile"
                        :key="i"
                    >
                        {{ info.text }} :
                        <template v-if="item[info.value]">
                            <v-btn
                                v-if="!info.showDetail"
                                icon
                                aria-label="$t('altFileDownload')"
                                @click="$emit('clickDownload')"
                            >
                                <v-icon
                                    size="15"
                                    color="#45404C"
                                    aria-label="$t('altFileDownloadIcon')"
                                >
                                    mdi-download
                                </v-icon>
                            </v-btn>
                            <template v-else>
                                <v-btn
                                    x-small
                                    outlined
                                    class="ml-2 info-download"
                                    @click="$emit('clickDownload')"
                                >
                                    {{ item[info.name] }}
                                </v-btn> 
                                <span>({{ item[info.size] }}KB)</span>
                            </template>
                        </template>
                        <span v-else>
                            {{ $t('attachNot') }}   
                        </span>
                    </div>
                    <div 
                        v-else-if="info.dateShortenToTime"
                        :key="i"
                        class="info-text"
                    >
                        {{ info.text }} : 
                        <span class="black--text">
                            {{ shortenToTime(item[info.value]) }}
                        </span>
                    </div>
                    <div 
                        v-else-if="info.isCategory"
                        :key="i"
                        class="info-text"
                    >
                        {{ info.text }} : 
                        <span class="black--text">
                            {{ category(item[info.value]) }}
                        </span>
                    </div>
                    <div 
                        v-else
                        :key="i"
                        class="info-text"
                    >
                        {{ info.text }} : 
                        <span class="black--text">
                            {{ item[info.value] }}
                        </span>
                    </div>
                </template>
                <div v-if="url">
                    첨부 URL :  
                        <span class="info-text">
                            <a 
                                :href="url" 
                                target="_blank">
                                {{ url }}
                            </a>
                        </span>
                </div>
            </div>
        </slot>
        <slot name="text">
            <div class="pt-4 article-text">
                <v-img
                    v-if="image"
                    :src="image"
                    contain
                    width="252"
                    class="item-image mb-5"
                    :alt="`콘텐츠 이미지_${image}`"/>
                <div
                    v-if="htmlDesc"
                    v-html="content"
                />
                <template v-else>
                    {{ content }}
                </template>
            </div>
        </slot>
        <slot name="actions"/>
    </v-container>
</template>

<script>
import { 
    downByFileUrl,
    toStringByFormattingTime
} from '@/commons/functions';
import { srvcCgCd } from '@/commons/resources';

export default {
    name: 'ArticleDetail',
    props: {
        item: { 
            // 글
            type: Object
        },
        itemTitle: { 
            // 글 제목 속성명
            type: String,
            default: 'title'
        },
        itemDesc: { 
            // 글 설명 속성명
            type: String,
            default: 'description'
        },
        articleInfos: { 
            // 전시할 글 정보 목록
            type: Array
        },
        htmlDesc: {
            // 글 설명이 html 코드인지 여부
            type: Boolean,
            default: false
        },
        itemImg: {
            type: String,
            default: 'imgUrlAddr'
        },
        itemUrl: {
            type: String,
            default: 'extrlUrlAddr'
        }
    },
    data() {
        return {
            downByFileUrl,
            srvcCgCd
        };
    },
    methods: {
        shortenToTime(date) {
            return toStringByFormattingTime(new Date(date));
        },
        category(code) {
            const cate = srvcCgCd[code];
            return this.$t(cate);
        }
    },
    computed: {
        content() {
            return this.item[this.itemDesc].replaceAll("<", "&lt;").replaceAll(">", "&gt;").replaceAll("\n", "<br/>");
        },
        image() {
            return this.item[this.itemImg] !== 'imgUrlAddr' ? this.item[this.itemImg] : '';
        },
        url() {
            return this.item[this.itemUrl] !== 'extrlUrlAddr' ? this.item[this.itemUrl] : '';
        }
    },
    created() {
    }
}
</script>

<style scoped>
.article-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 16px;
    word-break: break-all;
}

.article-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #747474;
    margin: 16px 0;
}

.article-info .info-text {
    display: inline-block;
    padding: 0 16px;
    position: relative;
}

.article-info .info-text::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 13px;
    background: #999999;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.article-info .info-text:first-child {
    padding-left: 0;
}

.article-info .info-text:first-child::before {
    display: none;
}

.article-info > div:not(.info-text) > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
    color: #000000;
}

.info-download {
    text-transform: unset;
    background: rgba(57, 126, 228, 0.2);
    border: 0.5px solid #3172D2;
}

.info-download::v-deep .v-btn__content {
    font-size: 10px;
    line-height: 14px;
    color: #000000;
}

.article-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 176.5%;
    color: #666666;
    white-space: pre-wrap;
    word-break: break-all;
}
</style>
