<!--
   [2022/12/12 se.yoo]
   ContestItemsWrap.vue
   @note 이미지 아이템 목록 컴포넌트
-->
<template>
    <div>
        <v-row>
            <component
                :is="component"
                v-for="(item, i) in pageList"
                :key="i"
                :item="item"
                :item-sn="itemSn"
                :item-image="itemImage"
                :item-title="itemTitle"
                :item-date="itemDate"
                :style="itemStyle"
                @clickItem="$emit('clickItem', $event)"
            />
        </v-row>
        <no-data v-if="items.length == 0" />
        <v-divider class="scm-community-divider mt-10" />
        <div class="text-center result-pagination-wrap">
            <v-pagination
                v-model="page"
                :length="pageCount"
                :next-aria-label="$t('altNextPage')"
                :previous-aria-label="$t('altBackPage')"
                :page-aria-label="`${$t('altMovePage') + '{0}' + $t('altPage')}`"
                class="scm-community-pagination"
            />
        </div>
    </div>
</template>

<script>
import { getPageCount, getPageList, windowScrollTop } from '@/commons/functions.js';
import NoData from '../com/NoData.vue';

export default {
    name: 'ContestItemsWrap',
    props: {
        items: {
            type: Array
        },
        cols: {
            type: Number,
            default: 4
        },
        itemSn: {
            type: String,
            default: 'sn'
        },
        itemComponent: {
            type: String
        },
        itemImage: {
            type: String,
            default: 'image'
        },
        itemTitle: {
            type: String,
            default: 'title'
        },
        itemDate: {
            type: String,
            default: 'date'
        },
        disabledResponseCols: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NoData
    },
    data() {
        return {
            page: 1
        };
    },
    computed: {
        responsiveCols() {
            let recommend = this.cols;

            if(this.$vuetify.breakpoint.xs) {
                recommend = 1;
            } else if(this.$vuetify.breakpoint.mdAndDown) {
                recommend = 3;
            } else if(this.$vuetify.breakpoint.lg) {
                recommend = 5;
            }

            return Math.min(this.cols, recommend);
        },
        itemCols() {
            return this.disabledResponseCols ? this.cols : this.responsiveCols;
        },
        itemStyle() {
            return {
                width: `${100 / this.itemCols}%`
            }
        },
        itemsPerPage() {
            return this.itemCols * (this.$vuetify.breakpoint.xs ? 5 : 2);
        },
        pageCount(){
            return getPageCount(this.items.length, this.itemsPerPage);
        },
        pageList() {
            return getPageList(this.page, this.items, this.itemsPerPage);
        },
        component() {
            return () => import (`./${this.itemComponent}`);
        }
    },
    watch: {
        page() {
            windowScrollTop();
        }
    }
}
</script>

<style scoped>
.result-pagination-wrap {
    margin-top: 30px;
    margin-bottom: 180px;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .result-pagination-wrap {
        margin-bottom: 60px;
    }
}
</style>