<!--
   [2022/10/21 se.yoo]
   ContestEntriesItem.vue
   @note 공모전 목록 검색 결과 항목 컴포넌트
-->
<template>
    <v-card
        tile
        elevation="0"
        class="card-list-item pa-3"
        @click="clickItem"
        @keydown.enter.stop="clickItem"
    >
        <v-img
            v-if="item.nftGiveYn===1"
            src="../../assets/images/nft_icon.png"
            alt="NFT 아이콘"
            width="15%"
            class="nft-icon ma-5 rounded-lg"
            />
        <image-print
            :aspect-ratio="1/1"
            :src="item[this.itemImage]"
            :alt="`${$t('altContestPoster') + item[itemTitle]}`"
            width="100%"
            height="200"
            class="rounded-lg"
        />
        <v-card-title class="card-list-item-title overflow-ellipsis">
            {{ item[itemTitle] }}
            <v-spacer />
            <v-chip
                v-if="item.awdNm"
                color="#C9CEFF"
                text-color="#252CD2"
                class="item-awd-chip"
            >
                {{ awdNmContent }}
            </v-chip>
        </v-card-title>
    </v-card>
</template>

<script>
import ImagePrint from '../com/ImagePrint.vue';
import { toStringByFormattingTime } from '../../commons/functions.js';
import nftIcon from '../../assets/images/nft_icon.png';

export default {
    name: 'ContestEntriesItem',
    props: {
        item: {
            type: Object
        },
        itemSn: {
            type: String,
            default: 'sn'
        },
        itemImage: {
            type: String,
            default: 'image'
        },
        itemTitle: {
            type: String,
            default: 'title'
        }
    },
    components: { 
        ImagePrint
    },
    data() {
        return {
            toStringByFormattingTime,
            nftIcon
        }
    },
    computed: {
        awdNmContent() {
            switch(this.item.awdNm) {
                case '대상': 
                    return this.$t('grandAward')
                case '최우수상': 
                    return this.$t('topAward')
                case '우수상': 
                    return this.$t('excellenceAward')
                case '장려상':
                    return this.$t('participationAward')
                default:
                    return ''
            } 
        }
    },
    methods: {
        /**
         * @method clickItem
         * @note 항목 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        clickItem() {
            this.$emit('clickItem', this.item);
        }
    }
}
</script>

<style scoped>
.card-list-item {
    display: inline-block;
}

.card-list-item-title {
    padding: 0;
    padding-top: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #303030;
}

.item-awd-chip {
    height: 25px;
}

.nft-icon {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
}
</style>