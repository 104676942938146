<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoId.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(아이디)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            {{ $t('enterId') }}
        </p>
        <div class="community-login-input-label mt-9">
            {{ $t('id') }}
        </div>
        <v-form ref="idForm">
            <v-text-field
                v-model="emlAddr"
                :rules="[rules.email, rules.required]"
                placeholder="ex) metaseoul@meta.co.kr"
                class="community-signup-input mt-0 pt-0"
                title="아이디"
            >
                <template v-slot:append>
                    <v-btn
                        :disabled="disabled"
                        rounded
                        height="35"
                        class="scm-community-button-primary mb-2"
                        aria-label="아이디 중복 확인 버튼"
                        @click="checkDuplicateId"
                    >
                        {{ $t('duplicateVerification') }}
                    </v-btn>
                </template>
            </v-text-field>
        </v-form>
        <v-row
            no-gutters
            class="id-actions justify-end"
        >
            <!-- <v-col
                cols="5"
                class="pr-3"
            >
                <v-btn
                    block
                    outlined
                    height="60"
                    class="scm-community-button-outlined community-login-btn"
                    aria-label="회원가입 이전 페이지 이동 버튼"
                    @click="$emit('prevPage')"
                >
                    {{ $t('back') }}
                </v-btn>
            </v-col> -->
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    :disabled="disabledNext"
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="회원가입 다음 페이지 이동 버튼"
                    @click="nextPage"
                >
                    {{$t('next')}}
                </v-btn>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="confirmDuplicateId"
            show-disagree
            :title="$t('idDuplicate')"
            :content="$t('moveLogin')"
            :agree="$t('login')"
            :disagree="$t('cancel')"
            @agree="routerLogin"
        /> 

        <alert-dialog
            v-model="confirmId"
            :agree="$t('complete')"
            :title="$t('idNotDuplicate')"
            hide-content
        />
    </div>
</template>

<script>
import { rules, signUpStep } from '@/commons/resources.js';
import AlertDialog from '../com/AlertDialog.vue'

export default{
    name:'CommunitySignUpInfoId',
    components:{
        AlertDialog
    }, 
    data(){
        return{
            disabled: true,
            disabledNext: true,
            confirmDuplicateId: false,
            confirmId: false,
            rules,
        }
    },
    computed: {
        emlAddr: {
            get() {
                return this.$store.state.user.emlAddr;
            },
            set(value) {
                this.$store.commit('setUserEmlAddr', value);
            }
        }
    },
    methods:{
        /**
         * @method checkDuplicateId
         * @note 아이디 중복 확인 함수
         * @email se.yoo@naviworks.com
         */
        checkDuplicateId(){
            const params = {
                emlAddr: this.emlAddr
            };

            this.$store.dispatch('overlapCheck', params)
            .then(res => {
                if(res) {
                    this.disabledNext = false;
                    this.confirmId = true;
                }
            }).catch(err => {
                this.disabledNext = true;
                this.confirmDuplicateId = true;
            });
        },
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            this.$emit('nextPage', signUpStep.InfoPwd);
        },
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        }
    },
    watch:{
        emlAddr(){
            this.disabledNext = true;
            
            const validate = this.$refs.idForm.validate();

            if(!validate){
                this.disabled = true;
            } else{
                this.disabled = false;
            }
        },
    }
}
</script>

<style scoped>
.id-actions {
    margin-top: 76px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .id-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>