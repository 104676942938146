<!--
   [2022/10/25 se.yoo]
   NoData.vue
   @note 데이터가 없을 때 안내 문구 전시를 위한 컴포넌트
-->
<template>
    <v-container class="no-data-wrap">
        <span>
            {{ text }}
        </span>
    </v-container>
</template>

<script>
export default {
    name: 'NoData',
    props: {
        noResult: {
            // 검색 결과가 없을 때
            type: Boolean,
            default: false,
        },
        customText: {
            // 사용자 지정 문구
            type: String,
            default: '',
        }
    },
    computed: {
        text() {
            let text = this.$t('noData');

            if(this.customText != undefined && this.customText != '') {
                text = this.customText;
            } else if(this.noResult) {
                text = this.$t('noResult');
            }

            return text;
        }
    }
};
</script>

<style scoped>
.no-data-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
}
</style>