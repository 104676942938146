<!--
   [2024/01/04 sb.hwang]
   CommunityHomeBannerLink.vue
   @note 시민 커뮤니티 홈 메인 배너 아이콘 컴포넌트
-->
<template>
    <v-row
        class="home-main-banner-link">
        <div class="home-main-banner-link-icon">
            <v-img
            :src="require('@/assets/images/youtube_icon.png')"
            width="50"
            class="cursor-pointer"
            :alt="$t('altHomeBannerYoutubeIcon')"
            @click.stop="linkToYoutube"
            />
            <v-img
                :src="require('@/assets/images/instagram_icon.png')"
                width="50" 
                class="cursor-pointer"
                :alt="$t('altHomeBannerInstagramIcon')"
                @click.stop="linkToInstagram"
            />
        </div>
    </v-row>
</template>

<script>
export default {
    name: 'CommunityHomeBannerLink',
    methods: {
        linkToInstagram() {
            window.open('https://instagram.com/metaverse.seoul');
        },
        linkToYoutube() {
            window.open('https://www.youtube.com/channel/UCLd46DxPQN-YljumbsOCh9Q');
        }
    }
}
</script>

<style scoped>
.home-main-banner-link {
    display: flex; 
    width: 100%; 
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
    padding-right: 20px;
    padding-top: 20px;
}

.home-main-banner-link-icon {
    display: inline-flex;
    gap: 20px;
}
</style>