<template>
    <div class="privacy-table-item">
        <div class="privacy-table-item-title">
            <img 
                :src="item.iconUrl"
                class="item-image py-2"
            />
            <div>{{ $t(item.title) }}</div>
        </div>
        <div class="privacy-table-item-content">
            <div>{{ $t(item.content) }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PrivacyLabelingItem',
    props: {
        item: Object,
    },

}
</script>

<style scoped>
.privacy-table-item {
    border-left: 1px solid #ccc;
    text-align: center;
    font-size: 14px;
}

.privacy-table-item:nth-child(3n+1) {
    border-left: none;
}

.privacy-table-item-title {
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-table-item-content {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ccc;
}

.item-image {
    width: 70px;
}
</style>