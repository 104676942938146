import i18n from '@/locales/i18n';
import { menus } from './menus';

/**
 * @method windowScrollTop
 * @note 화면 스크롤을 최상단으로 이동시키는 함수
 * @email se.yoo@naviworks.com
 */
export const windowScrollTop = function() {
    window.scroll(0, 0);
};

/**
 * @method leftPad
 * @param {number} value 숫자
 * @returns {string | number} 10보다 작을 경우 앞에 0을 붙인 값
 * @note 10보다 작을 경우 0을 붙여 반환하는 함수
 * @email se.yoo@naviworks.com
 */
 export const leftPad = function(value) { 
    if (value >= 10) { 
        return value; 
    } 

    return `0${value}`; 
};

/**
 * @method toStringByFormatting
 * @param {Date} source 날짜
 * @param {string} delimiter 연결 문자
 * @returns {string} 년월일 형식으로 변환한 값
 * @note 날짜 데이터를 년월일 형식으로 변환하여 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const toStringByFormatting = function(source, delimiter = '-') { 
    const year = source.getFullYear(); 
    const month = leftPad(source.getMonth() + 1); 
    const day = leftPad(source.getDate()); 
    return [year, month, day].join(delimiter); 
};

/**
 * @method toStringByFormattingTime
 * @param {Date} source 날짜
 * @param {string} delimiter 연결 문자
 * @returns {string} 년월일 시:분:초 형식으로 변환한 값
 * @note 날짜 데이터를 년월일 시:분:초 형식으로 변환하여 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const toStringByFormattingTime = function(source, delimiter = '-', timeDelimiter = ':') { 
    const year = source.getFullYear(); 
    const month = leftPad(source.getMonth() + 1); 
    const day = leftPad(source.getDate()); 
    const fullDate = [year, month, day].join(delimiter); 

    const hour = leftPad(source.getHours());
    const minutes = leftPad(source.getMinutes());
    const seconds = leftPad(source.getSeconds());
    const time = [hour, minutes, seconds].join(timeDelimiter);

    return `${fullDate} ${time}`;
};

/**
 * @method getImageType
 * @param {string | number} image 이미지
 * @returns {string} 이미지 타입
 * @note 이미지 타입을 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const getImageType = function (image) {
    if(typeof image == 'string') {
        if(image.includes('assets/images/')) {
            return 'asset';
        } else if(image.includes('data:image') || image.indexOf('blob:') == 0) {
            return 'dataurl';
        } else if(image == '') {
            return 'empty';
        } else if(urlify(image)) {
            return 'url';
        } else {
            return 'filename';
        }
    } else if(typeof image == 'number') {
        return 'fileSn';
    }

    return 'undefined';
};

/**
 * @method getImage
 * @param {string} image 이미지 주소
 * @returns {string} 출력할 이미지 주소
 * @note 이미지 예외처리 함수
 * @email se.yoo@naviworks.com
 */
export const getImageSrc = function(image, notfoundImg = require('@/assets/images/Empty_I_IMG.png')) {
    try {
        const type = getImageType(image);
        let src = notfoundImg;

        switch(type) {
            case 'dataurl':
            case 'url': 
                src = image;
                break;
            case 'asset':
                src = require(`@/${image}`);
                break;
            default:
                break;
        }
        return src;
    } catch (err) {
        return notfoundImg;
    }
};

/**
 * @method urlify
 * @param {String} text 확인할 문자열 
 * @returns {Boolean} URL인지 여부
 * @note 문자열이 URL인지 확인하는 함수
 * @email gy.yang@naviworks.com
 */
export const urlify = (text) => {
    let urlRegex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;
    let urlArr = text.match(urlRegex);
    return Array.isArray(urlArr) ? true : null;
}

/**
 * @method getPageCount
 * @param {number} listLength 아이템 목록 전체 개수
 * @param {number} itemsPerPage 페이지 별 아이템 전시 개수
 * @returns {number} 페이지 수
 * @note 몇 페이지가 필요한지 계산하여 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const getPageCount = function(listLength, itemsPerPage) {
    return Math.ceil(Number(listLength) / Number(itemsPerPage));
};

/**
 * @method getPageList
 * @param {number} currentPage 현재 페이지
 * @param {array} items 아이템 목록
 * @param {number} itemsPerPage 페이지 별 아이템 전시 개수
 * @returns {number} 전시할 아이템
 * @note 페이지에 전시할 아이템을 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const getPageList = function(currentPage, items, itemsPerPage) {
    let startIdx = (currentPage - 1) * itemsPerPage;
    let endIdx = 0;

    if((startIdx + itemsPerPage) > items.length){
        endIdx = items.length;
    }else{
        endIdx = startIdx + itemsPerPage;
    }

    let results = items.slice(startIdx, endIdx);
    
    return results;
};

/**
 * @method filterItems
 * @param {Array} items 목록
 * @param {Object} searchInfo 검색 설정 
 * @returns {Array} 필터링 된 목록
 * @note 검색 조건에 맞게 필터링된 목록을 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const filterItems = function(items = [], searchInfo) {
    return items.filter( item => {
        if(!searchInfo) return true;

        let result = true;

        if(searchInfo.type && searchInfo.keyword) {
            result = (item[searchInfo.type] || '').toString().indexOf(searchInfo.keyword) !== -1;
        } else if(searchInfo.keyword) {
            result = Object.values(item).some(v => 
                v && v.toString().includes(searchInfo.keyword)
            );
        }

        if(searchInfo.date && searchInfo.date.length > 0) {
            const date = toStringByFormatting(new Date(item[searchInfo.itemDate]));
            const includeDate = (
                date >= searchInfo.date[0] && 
                date <= (searchInfo.date[1] || searchInfo.date[0])
            );

            result = result && includeDate;
        }

        switch(searchInfo.itemLang) {
            case 'ko':
                result = result && (item.langCd == 'ko-KR' || item.langCd == 'xx-XX')
                break;
            case 'en':
                result = result && (item.langCd == 'en-US' || item.langCd == 'xx-XX')
                break;
        }

        return result;
    });
}

/**
 * @method downByFileUrl
 * @param {String} fileUrl 파일 URL
 * @note 파일을 다운로드 하는 함수
 * @email se.yoo@naviworks.com
 */
export const downByFileUrl = function (fileUrl) {
    let element = document.createElement("a")
    element.setAttribute("href",  fileUrl);
    element.setAttribute("download", "download");
    element.click();
    element.remove();
}

/**
 * @method downloadFile
 * @param {Number|String} file 파일 번호/url
 * @note 첨부파일 다운로드 함수
 * @email sh.hong@naviworks.com
 */
 export const downloadFile = function(file) {
    let fileUrl;
    if(urlify(file.toString())) {
        fileUrl = file
    } else {
        fileUrl = `${process.env.VUE_APP_API_URL}/scm/storage/download?fileSn=${file}`
    }

    downByFileUrl(fileUrl);
}

/**
 * @method getCurrMenuRoute
 * @param {Object} to 이동하려는 페이지 정보 
 * @returns {Array} 페이지 제목 배열
 * @note router 정보를 받아 페이지 제목 배열을 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const getCurrMenuRoute = function (to) {
    const toPaths = to.path.split('/');
    const menuPaths = toPaths.splice(2) || [];
    let menuInfos = menus;
    let menuPages = [];

    menuPaths.forEach(path => {
        // 상세 > 숫자 메뉴
        if(!isNaN(+path)) return;

        if(
            menuInfos[path] == undefined ||
            path === 'contest'
        ) {
            switch(path) { // 예외 메뉴들
                case 'login':
                    menuPages.push(i18n.t('login'));
                    break;
                case 'signup':
                    menuPages.push(i18n.t('signUp'));
                    break;
                case 'search':
                    menuPages.push(i18n.t('forgot'));
                    break;
                case 'detail':
                    menuPages.push(i18n.t('entries'));
                    menuInfos = menuInfos.contest.subMenus;
                    break;
                case 'entries':
                    menuPages.push(i18n.t('contestEntries'));
                    menuInfos = menuInfos.contest.subMenus;
                    break;
                case 'contest':
                    menuPages.push(i18n.t('contestList'));
                    menuInfos = menuInfos.contest.subMenus;
                    break;
                case 'tutorial':
                    menuPages.push(i18n.t('tutorial'));
                    menuInfos = menuInfos.about.subMenus;
                    break;
                case 'mypage':
                    menuPages.push(i18n.t('userinfo'));
                    break;
            }
            return;
        }

        const nowMenu = menuInfos[path];

        if(nowMenu) {
            menuPages.push(i18n.t(nowMenu.name));
            menuInfos = nowMenu.subMenus;
        }

    });

    return menuPages;
}

/**
 * @method getCurrMenuTitle
 * @param {Object} to 이동하려는 페이지 정보 
 * @returns {String} 표시할 페이지 제목
 * @note router 정보를 받아 DOM 제목을 반환하는 함수
 * @email se.yoo@naviworks.com
 */
export const getCurrMenuTitle = function (to) {
    let menuPages = getCurrMenuRoute(to);
    let title = i18n.t('scm');

    if(menuPages.length > 0) {
        title = `${menuPages.join(' > ')} | ` + title;
    }

    return title;
}