<!--
   [2022/10/06 se.yoo]
   App.vue
   @note metaverse 시민 커뮤니티 웹 프로젝트 App.vue
-->
<template>
    <div>
        <!-- 모든 router-view에 로딩 스피너 적용되어야 함 -->
        <loading-spinner :loading="loadingSpinner"/>
        <router-view />
    </div>
</template>

<script>
import LoadingSpinner from '@/components/com/LoadingSpinner.vue'

export default {
    name: 'App',
    components: {
        LoadingSpinner
    },
    computed: {
        loadingSpinner() {
            return this.$store.state.loading.loadingStatus;
        }
    },
};
</script>

<style src="./assets/css/common-style.css" />