<!--
   [2024/07/16 sb.hwang]
   PopupDialog.vue
   @note 팝업 다이얼로그 컴포넌트
-->
<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs"
        :max-width="width"
        :persistent="persistent"
        content-class="elevation-0"
    >
        <v-card
            rounded="lg"
            elevation="0"
            class="com-dialog-card pa-10 d-flex justify-center flex-column"
        >
            <slot name="title">
                <v-card-title class="pa-0 align-center justify-center scm-community-section-title">
                    {{ title }}
                </v-card-title>
                <v-btn
                    v-if="closable"
                    class="closable"
                    icon
                    :aria-label="`알림창 버튼_${title}`"
                    @click="btnClick"
                >
                    <v-icon aria-label="알림창 닫기 버튼">mdi-close</v-icon>
                </v-btn>
            </slot>
            <slot name="content">
                <div 
                    class="content my-5"
                    v-html="content"
                />
                <div class="checkable mb-2">
                    <v-checkbox 
                        v-if="checkable"
                        v-model="checkVal"
                        hide-details
                        :label="label"
                    />
                </div>
            </slot>
            <slot 
                v-if="closableBtn"
                name="footer"
            > 
                <v-card-actions class="justify-center">
                    <v-btn
                        class="scm-community-button-primary"
                        @click="btnClick"
                    >
                        {{ footer }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ComDialog',
    props: {
        value: {
            type: Boolean
        },
        title: {
            type: String,
            default: '제목'
        },
        content: {
            type: String,
            default: '내용'
        },
        label: {
            type: String,
            default: '그만 보기'
        },
        width: {
            type: String,
            default: '595'
        },
        closable: {
            type: Boolean,
            default: false
        },
        checkable: {
            type: Boolean,
            default: false
        },
        closableBtn: {
            type: Boolean,
            default: false
        },
        footer: {
            type: String,
            default: '버튼'
        },
        persistent: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return{
            checkVal: false,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        btnClick() {
            if(this.checkVal) {
                this.setCookie("popupYN", 'N', 1);
            }
            this.dialog = false;
            this.$emit('btnClick');
        },
        setCookie(name, value, expiredays) {
            let date = new Date();
            date.setDate(date.getDate() + expiredays);
            document.cookie = escape(name) + "=" + escape(value) + "; expires=" + date.toUTCString();
        }
    },
}
</script>

<style scoped>
::v-deep .v-dialog--fullscreen .com-dialog-card.v-card {
    padding: 16px !important;
}
.scm-community-button-primary {
    background-color: #A5A5A5 !important;
}
.scm-community-button-primary, .scm-community-button-outlined {
    width: 185px;
}

.closable {
    position: absolute;
    right: 20px;
    top: 20px;
}

.content {
    text-align: center;
}

.checkable {
    display: flex;
    justify-content: center;
}
</style>