<!--
   [2022/10/17 gy.yang]
   CommunitySignUpEnd.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(회원가입 완료)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            {{ $t('successSignUp') }}
        </p>
    
        <v-row
            no-gutters
            class="end-actions justify-end"
        >
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="로그인 페이지 이동 버튼"
                    @click="routerLogin"
                >
                    {{ $t('login')}}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name:'CommunitySignUpEnd',
    data() {
        return {
            moved: false,
            timer: null,
        }
    },
    methods: {
        /**
         * @method routerLogin
         * @note 로그인 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerLogin(){
            const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);

            if(isMobile) {
                this.redirectApp();
            } else {
                this.$router.push({
                    name: 'communityLogin'
                })
            }
        },
        redirectApp() {
            const userAgent = navigator.userAgent.toLowerCase();

            this.deepLink(userAgent);
        },
        deepLink(userAgent) {
            if(window.confirm('앱으로 이동하시겠습니까?')) {
                const url = 
                    userAgent.includes('android') 
                    ? 'mseoul://'
                    : 'metaverseSeoul://';
                window.location.href = url;
                
                this.checkInstallApp(userAgent);
            }
        },
        checkInstallApp(userAgent) {
            var timer;

            function clearTimers() {
                clearInterval(check);
                clearTimeout(timer);
            }

            function isHideWeb() {
                if(document.webkitHidden || document.hidden) {
                    clearTimers();
                }
            }

            const check = setInterval(isHideWeb, 200);

            timer = setTimeout(() => {
                this.redirectStore(userAgent);
                clearInterval(check);
            }, 1000);
        },
        redirectStore(userAgent) {
            if(window.confirm('스토어로 이동하시겠습니까?')) {
                window.location.href = 
                    userAgent.includes('android') 
                    ? 'https://play.google.com/store/apps/details?id=kr.go.seoul.metaverse'
                    : 'https://apps.apple.com/kr/app/id1660175436';
            }
        }
    }
}
</script>

<style scoped>
.end-actions {
    margin-top: 210px;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) { 
    .end-actions {
        margin-top: 210px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .end-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>