<template>
    <menu-detail
        ref="detail"
        :title="$t('notice')"
        :search-types="types"
        :hide-search-type="false"
        :hide-search-date="true"
        :hide-sort-type="false"
        :item-order="orderItem"
        @reverse="reverse"
    >
        <template v-slot:content>
            <board-list
                id="list"
                :items="filterNotices"
                item-title="ntcTtlNm"
                item-category="srvcCgCd"
                item-date="crtDt"
                item-lang="langCd"
                @clickItem="routerDetail"
            />
        </template>
        <alert-dialog
            v-model="alertError"
            title="오류 발생"
            content="데이터가 존재하지 않습니다. 메인 화면으로 돌아갑니다."
            @agree="routerHome"
        />
    </menu-detail>
</template>

<script>
import MenuDetail from '@/components/com/MenuDetail.vue';
import BoardList from '@/components/board/BoardList.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';
import { filterItems, toStringByFormatting } from '@/commons/functions';
import { langCd } from '@/commons/resources';
import { mapGetters } from 'vuex';

export default {
    name: 'BoardNotice',
    components: { 
        MenuDetail,
        BoardList,
        AlertDialog
    },
    data() {
        return {
            toStringByFormatting,
            langCd,
            orderItem: false,
            alertError: false
        };
    },
    computed: {
        ...mapGetters({
            notices: 'getPosts',
            searchInfo: 'getSearchInfo',
        }),
        filterNotices() {
            return filterItems(this.order, {
                ...this.searchInfo,
                itemCategory: 'srvcCgCd',
                itemDate: 'crtDt',
                itemLang: this.language,
            });
        },
        types() {
            return [
                { text: this.$t('all'), value: '' },
                { text: this.$t('title'), value: 'ntcTtlNm' }
            ]
        },
        language() {
            return this.$store.state.user.translation
        },
        order() {
            const notices = this.notices
            if(this.orderItem) {
                notices.sort((a, b) => {
                    return a.crtDt.localeCompare(b.crtDt);
                })
            } else {
                notices.sort((a, b) => {
                    return b.crtDt.localeCompare(a.crtDt);
                })
            }
            return notices;
        }
    }, 
    methods: {
        routerDetail(item) {
            this.$router.push({
                name: 'boardNoticeDetail',
                params: { sn: item.ntcSn }
            });
        },
        getNoticeList() {
            this.$store.dispatch('getNoticePostList')
            .catch(err => {
                this.alertError = true;
            });
        },
        reverse() {
            this.orderItem = !this.orderItem;
            this.order;
        },
        routerHome() {
            this.$router.push({ name: 'communityHome' });
        }
    },
    created() {
        this.getNoticeList();
    }
}
</script>

<style scoped>
</style>
