<!--
   [2023/10/25 sb.hwang]
    MyPage.vue
   @note 사용자 정보 화면
-->
<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg8
                class="mypage-layout"
            >
                <menu-detail
                    ref="detail"
                    :title="$t('userinfo')"
                    :hide="true"
                    :hide-sort-type="true"
                >
                    <template v-slot:content>
                        <my-page-info
                        v-model="user"/>
                    </template>
                </menu-detail>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import MyPageInfo from '@/components/mypage/MyPageInfo.vue';
import MenuDetail from '@/components/com/MenuDetail.vue';

export default {
    name: 'UserLayout',
    data() {
        return {
        }
    },
    components: {
        MyPageInfo,
        MenuDetail
    },
    computed: {
        user: {
            get() {
                return this.$store.state.user;
            },
            set(value) {
                this.$store.commit('setNotice', value);
            }
        },
    },
    created() {
    }
}
</script>

<style scoped>
.mypage-layout {
    margin-top: 120px;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .mypage-layout {
        margin-top: 40px;
    }
}
</style>