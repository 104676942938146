<!--
   [2022/11/07 se.yoo]
   ImagePrint.vue
   @note 시민 웹 이미지 처리 컴포넌트
-->
<template>
    <v-img
        :alt="alt"
        :aspect-ratio="aspectRatio"
        :contain="contain"
        :content-class="contentClass"
        :dark="dark"
        :eager="eager"
        :gradient="gradient"
        :height="height"
        :lazy-src="lazySrc"
        :light="light"
        :max-height="maxHeight"
        :max-width="maxWidth"
        :min-height="minHeight"
        :min-width="minWidth"
        :options="options"
        :position="position"
        :sizes="sizes"
        :src="printImageSrc"
        :srcset="srcset"
        :transition="transition"
        :width="width"
        :style="hiddenEmptyStyle"
        @load="imgLoadded = true"
        @loadstart="imgLoadded = false"
        @error="setPrintImageSrcNotFound"
    >
        <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
                <v-progress-circular
                    id="progressCircular"
                    aria-label="progressCircular"
                    indeterminate
                    color="grey"
                />
            </v-row>
      </template>
    </v-img>
</template>

<script>
import { getImageType, getImageSrc } from '@/commons/functions.js';

export default {
    name: 'ImagePrint',
    props: {
        alt: {
            type: String,
            default: undefined
        },
        aspectRatio: {
            type: [String, Number],
            default: undefined
        },
        contain: {
            type: Boolean,
            default: false
        },
        contentClass: {
            type: String,
            default: undefined
        },
        dark: {
            type: Boolean,
            default: false
        },
        eager: {
            type: Boolean,
            default: false
        },
        gradient: {
            type: String,
            default: undefined
        },
        height: {
            type: [String, Number],
            default: undefined
        },
        lazySrc: {
            type: String,
            default: undefined
        },
        light: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: [String, Number],
            default: undefined
        },
        maxWidth: {
            type: [String, Number],
            default: undefined
        },
        minHeight: {
            type: [String, Number],
            default: undefined
        },
        minWidth: {
            type: [String, Number],
            default: undefined
        },
        options: {
            type: Object,
            default: undefined
        },
        position: {
            type: String,
            default: 'center center'
        },
        sizes: {
            type: String,
            default: undefined
        },
        src: {
            type: [String, Object, Number],
            default: undefined
        },
        srcset: {
            type: String,
            default: undefined
        },
        transition: {
            type: [Boolean, String],
            default: undefined
        },
        width: {
            type: [Number, String],
            default: undefined
        },
        hiddenEmpty: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            printImageSrc: undefined,
            imgLoadded: false,
            isEmpty: false
        }
    },
    computed: {
        hiddenEmptyStyle() {
            if(this.hiddenEmpty && (!this.imgLoadded || this.isEmpty)) {
                return {
                    width: '0px',
                    height: '0px',
                    marginBottom: '0 !important'
                };
            } else {
                return {};
            }
        }
    },
    methods: {
        /**
         * @method setPrintImageSrc
         * @param {String} newSrc 새로운 이미지 src
         * @note 전시할 이미지 src를 설정하는 함수
         * @email se.yoo@naviworks.com
         */
        setPrintImageSrc(newSrc) {
            if(getImageType(newSrc) === 'fileSn') {
                const params = { 
                    fileSn: newSrc
                };

                this.$store.dispatch('selectFileInfo', params)
                .then(res => {
                    this.printImageSrc = res.uploadFileAddr;
                }).catch(err => {
                    this.printImageSrc = getImageSrc(null);
                });
            } else {
                this.printImageSrc = getImageSrc(newSrc);
            }
        },
        /**
         * @method setPrintImageSrcNotFound
         * @note not found 이미지로 전시 이미지를 수정하는 함수
         * @email se.yoo@naviworks.com
         */
        setPrintImageSrcNotFound() {
            this.isEmpty = true;
            this.setPrintImageSrc(null);
        },
        /**
         * @method resetImage
         * @param {String} newSrc 새로운 이미지 src
         * @note 새로운 이미지를 전시하기 위해 관련 변수를 초기화하는 함수
         * @email se.yoo@naviworks.com
         */
        resetImage(newSrc) {
            this.isEmpty = false;
            this.setPrintImageSrc(newSrc);
        }
    },
    created() {
        this.resetImage(this.src);
    },
    watch: {
        src(value) {
            this.resetImage(value);
        }
    }
}
</script>

<style scoped>
</style>