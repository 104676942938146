<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        content-class="elevation-0"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="value"
                :disabled="disabled"
                readonly
                dense
                outlined
                hide-details
                height="60"
                :append-icon="disabled? '' : 'mdi-calendar'"
                class="input-field"
                :class="{ 'disabled': disabled }"
                v-bind="attrs"
                v-on="on"
                @click:append="menu = true" 
            />
        </template>
        <v-date-picker
            v-model="date"
            :header-date-format="headerDateFormat"  
            no-title
            scrollable
            @change="changeDate"
        />
    </v-menu>
</template>

<script>

export default {
    name: 'DatePicker',
    props: {
        value: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            menu: false,
        };
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        changeDate() {
            this.menu = false;
        },
        headerDateFormat(date){
            let dateSplit = date.split('-');
            
            if(1 === dateSplit.length){
                return dateSplit[0] + '년';
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
    }
}
</script>

<style scoped>
.disabled::v-deep input {
    color: rgba(0, 0, 0, 0.65) !important;
}

.input-field::v-deep {
    background-color: #F7F7F7;
}

.input-field::v-deep input{
    color: black;
    height: 60px;
    cursor: context-menu;
}

.input-field::v-deep .v-input__append-inner {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0px !important;
}
</style>
