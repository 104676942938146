<!--
   [2022/12/09 se.yoo]
   AboutServiceBannerMiddle.vue
   @note 서비스 소개 중간 배너 컴포넌트
-->
<template>
    <v-card
        tile
        height="465"
        elevation="0"
        color="#EAF2F5"
        class="py-6 py-md-0 overflow-hidden"
    >
        <v-row
            align="center"
            justify="center"
            class="full-height my-0"
        >
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="3"
                class="px-9 px-md-3"
            >
                <h2 class="scm-community-section-title">
                    {{ $t('aboutServiceMiddleTitle') }}
                </h2>
                <div class="about-section-content mt-5">
                    {{ $t('aboutServiceMiddleContent') }}
                </div>
            </v-col>
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="4"
                class="my-n6 my-sm-0 px-9 px-md-3 justify-end"
            >
                <v-img
                    :src="require('@/assets/images/about_people_image.png')"
                    contain
                    :height="$vuetify.breakpoint.mdAndUp ? 465 : 300"
                    max-height="100%"
                    position="bottom right"
                    :alt="$t('altIntroductionImg')"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'AboutServiceBannerMiddle'
}
</script>

<style scoped>

</style>