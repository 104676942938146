<!--
   [2022/10/21 se.yoo]
   ContestEntriesInfo.vue
   @note 공모전 출품작 조회 공모전 정보 컴포넌트
-->
<template>
    <v-row>
        <v-col
            cols="12"
            sm="4"
            md="auto"
        >
            <image-print
                :src="contest.pmtImgFileSn"
                :aspect-ratio="1/1"
                :width="$vuetify.breakpoint.smAndUp ? 290 : '100%'"
                :max-height="270"
                :alt="`${$t('altContestPoster') + contest.ctstNm}`"
                class="rounded-lg"
            />
        </v-col>
        <v-col class="pl-md-10">
            <div class="contest-title mt-sm-5">
                {{ contest.ctstNm }}
            </div>
            <div class="contest-description mt-5">
                {{ contest.tpcNm }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ImagePrint from '../com/ImagePrint.vue';
import { etrTypeInfo } from '@/commons/resources';
import i18n from '@/locales/i18n';

export default {
    name: 'ContestEntriesInfo',
    components: { 
        ImagePrint
    },
    props: {
        contest: {
            type: Object
        }
    },
    computed: {
        etrTypeText() {
            const type = etrTypeInfo.find(item => item.value === this.contest.etrTypeCd);
            return type? type.text : '';
        },
        etrTypeCd() {
            switch(this.contest.etrTypeCd) {
                case etrTypeCd.PHOTO: 
                    return i18n.t('photo');
                case etrTypeCd.VIDEO: 
                    return i18n.t('video');
                case etrTypeCd.PLACE: 
                    return i18n.t('place');
                default:
                    return '';
            }
        }
    }
}
</script>

<style scoped>
.contest-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #303030;
}

.contest-sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #303030;
}

.contest-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #303030;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
</style>