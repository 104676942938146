import Vue from 'vue';
import VueRouter from 'vue-router';
import ContestList from '../views/contest/ContestList.vue';
import ContestEntries from '../views/contest/ContestEntries.vue';
import ContestEntriesDetail from '../views/contest/ContestEntriesDetail.vue';

Vue.use(VueRouter);

const contestRoutes = [
    {
        path: '/',
        name: 'contestList',
        component: ContestList
    },
    {
        path: 'entries/:sn',
        name: 'contestEntries',
        component: ContestEntries
    },
    {
        path: 'entries/detail/:ctstSn/:etrSn',
        name: 'contestEntriesDetail',
        component: ContestEntriesDetail
    }
];

export default contestRoutes;