<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoNickName.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(닉네임)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            {{ $t('enterNickNm') }}
        </p>
        <div class="community-login-input-label mt-9">
            {{ $t('nickName') }}
        </div>
        <v-form ref="nickNmForm">
            <v-text-field
                v-model="nickNm"
                :rules="[rules.required, rules.nickName]"
                title="닉네임"
                :placeholder="$t('nickNameCondition')"
                class="community-signup-input mt-0 pt-0"
            >
                <template v-slot:append>
                    <v-btn
                        :disabled="disabled"
                        rounded
                        height="35"
                        class="scm-community-button-primary mb-2"
                        aria-label="아이디 중복 확인 버튼"
                        @click="checkDuplicateNickNm"
                    >
                        {{ $t('duplicateVerification') }}
                    </v-btn>
                </template>
            </v-text-field>
        </v-form>
        <v-row
            no-gutters
            class="nickname-actions justify-end"
        >
            <!-- <v-col
                cols="5"
                class="pr-3"
            >
                <v-btn
                    block
                    outlined
                    height="60"
                    class="scm-community-button-outlined community-login-btn"
                    aria-label="회원가입 이전 페이지 이동 버튼"
                    @click="$emit('prevPage')"
                >
                    {{ $t('back') }}
                </v-btn>
            </v-col> -->
            <v-col
                cols="7"
                xl="5"
            >
                <v-btn
                    :disabled="disabledNext"
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="회원가입 완료 버튼"
                    @click="signUp"
                >
                    {{$t('next')}}
                </v-btn>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="confirmNickNm"
            :title="title"
            :content="content"
            :hide-content="hideContent"
            :agree="$t('complete')"
            @agree="goLogin"
        />
    </div>
</template>

<script>
import { rules, signUpStep } from '@/commons/resources.js';
import AlertDialog from '../com/AlertDialog.vue';

export default {
    name:'CommunitySignUpInfoNickName',
    components:{
        AlertDialog,
    },
    data(){
        return{
            disabled: true,
            title: '',
            content: '',
            hideContent: false,
            confirmNickNm: false,
            disabledNext: true,
            isRegistered: false,
            rules
        }
    },
    computed: {
        signUpUser() {
            return this.$store.getters.getSignUpUser;
        },
        getEmailSignUpUser() {
            return this.$store.getters.getEmailSignUpUser;
        },
        nickNm: {
            get() {
                return this.$store.state.user.nickNm;
            },
            set(value) {
                this.$store.commit('setUserNickNm', value);
            }
        }
    },
    methods:{
        /**
         * @method checkDuplicateNickNm
         * @note 닉네임 중복 확인 함수
         * @email gy.yang@naviworks.com
         */
        checkDuplicateNickNm(){
            const params = {
                nickNm: this.nickNm
            };

            this.$store.dispatch('overlapCheck', params)
            .then(res => {
                this.title = this.$t('nickNameNotDuplicate');
                this.content = this.$t('nickNameNotDuplicate');;
                this.hideContent = true;
                this.disabledNext = false;
            }).catch(err => {
                this.title = this.$t('nickNameDuplicateTitle');
                this.content = this.$t('nickNameDuplicate');
                this.hideContent = false;
                this.disabledNext = true;
            });

            this.confirmNickNm = true;
        },
        /**
         * @method signUp
         * @note 입력받은 정보로 회원가입하는 함수
         * @email gy.yang@naviworks.com
         */
        signUp(){
            if (this.$store.getters.getEmlCertified.emlCertifed) {
                this.$store.dispatch('insertAccountEmail', this.getEmailSignUpUser)
                .then(() => {
                    this.$emit('nextPage', signUpStep.End);
                }).catch(err => {
                    this.title = this.$t('duplicateCi');
                    this.content = this.$t('moveLogin');
                    this.hideContent = false;
                    this.isRegistered = true;
                    this.confirmNickNm = true;
                })
            } else {
                const params = { 
                    ...this.signUpUser,
                    lglrpsMblTelno: this.$store.state.user.representative.mobileNo,
                    lglrpsNm: this.$store.state.user.representative.name,
                    relCn: this.$store.state.user.representative.relation,
                }
                this.$store.dispatch('insertAccount', params)
                .then(() => {
                    this.$emit('nextPage', signUpStep.End);
                }).catch(err => {
                    this.title = this.$t('duplicateCi');
                    this.content = this.$t('moveLogin');
                    this.hideContent = false;
                    this.isRegistered = true;
                    this.confirmNickNm = true;
                });
            }
        },
        /**
         * @method goLogin
         * @note 로그인 화면으로 돌아가는 함수
         * @email sh.hong@naviworks.com
         */
        goLogin(){
            if(this.isRegistered) {
                this.$router.push({
                    name: 'communityLogin'
                })
            }
        }
    },
    watch:{
        nickNm(){
            this.disabledNext = true;

            const validate = this.$refs.nickNmForm.validate();

            if(!validate){
                this.disabled = true;
            } else {
                this.disabled = false;
            }
        },
    }
}
</script>

<style scoped>
.nickname-actions {
    margin-top: 76px;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .nickname-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>