<template>
        <com-dialog
            v-model="dialog"
            closable-btn
            width="600"
            :title="dialogTitle"
            :footer="dialogTitle"
        >
            <template v-slot:content>
                <v-row class="mt-3">
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('passwordBefore') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="oldPwd"
                            :rules="[rules.required]"
                            :type="showPasswordBefore ? 'text' : 'password'"
                            :error-messages="oldPwd_msg"
                            dense
                            filled
                            rounded
                            height="60"
                            class="input-field rounded-lg"
                            @blur="checkPwd"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPasswordBefore ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPasswordBefore = !showPasswordBefore"
                                >
                                    {{ showPasswordBefore ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('password') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="pswdCn"
                            :rules="[rules.required, rules.password]"
                            :type="showPassword ? 'text' : 'password'"
                            dense
                            filled
                            rounded
                            height="60"
                            :disabled="disabled"
                            class="input-field rounded-lg"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPassword ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPassword = !showPassword"
                                >
                                    {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-3">
                    <v-col 
                        cols="12"
                        class="pb-0">
                        {{ $t('passwordConfirm') }}
                    </v-col>
                    <v-col cols="12"
                        class="pb-0">
                        <v-text-field
                            v-model="pswdCnConfirm"
                            :rules="[rules.required, confirmPw, rules.password]"
                            :type="showPasswordConfirm ? 'text' : 'password'"
                            dense
                            filled
                            rounded
                            height="60"
                            :disabled="disabled"
                            class="input-field rounded-lg"
                        >
                            <template v-slot:append>
                                <v-icon
                                    :aria-label="`${$t('altPasswordCheckIcon') + showPasswordConfirm ? $t('altNotLook') : $t('altLook')}`"
                                    class="mt-2"
                                    @click="showPasswordConfirm = !showPasswordConfirm"
                                >
                                    {{ showPasswordConfirm ? "mdi-eye" : "mdi-eye-off" }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </com-dialog>
</template>

<script>
import ComDialog from '../com/ComDialog.vue';
import { rules } from '@/commons/resources.js';

export default {
    name: 'ChangePwdDialog',
    data() {
        return {
            showDialog: false,
            oldPwd: '',
            pswdCn: '',
            pswdCnConfirm: '',
            showPasswordBefore: false,
            showPassword: false,
            showPasswordConfirm: false,
            rules,
            oldPwd_msg: [],
        }
    },
    components: {
        ComDialog
    }
}
</script>


<style>

</style>