import { axiosService } from "@/commons/api";

const state =
{
    etrSn: 0,
    acutSn: 0,
    nickNm: '',
    etrNm: '',
    sttsCd: '',
    rcptDt: '',
    aprvDt: '',
    etrTypeCd: '',
    etrDscpCn: '',
    etrFileSn: '',
    etrTubnlFileSn: '',
    vtCnt: 0,
    awdNm: '',
    ctstSn: 0,

    entries: [],
};

const actions=
{
    /**
     * @method resetEntry
     * @param {object} context 컨텍스트 객체
     * @note 공모전 출품작 state를 초기화 하는 함수
     * @email gy.yang@naviworks.com
     */
    resetEntry(context) {
        const emptyEntry = {};

        context.commit('setEntry', emptyEntry);
    },
    /**
     * @method selectEntryList
     * @param {Object} context 컨텍스트 객체
     * @returns {Promise} 결과 데이터
     * @note 공모전 출품작 목록을 반환하는 함수
     * @email gy.yang@naviworks.com
     */
    selectEntryList(context, ctstSn) {
        return new Promise(function(resolve, reject)
        {
            const params = {
                ctstSn: ctstSn
            };

            axiosService
            .get('scm/ctst/user/etr', { params: params })
            .then(res => {
                context.commit('setEntries', res.data.etr);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    },
    /**
     * @method selectEntryDetail
     * @param {Object} context 컨텍스트 객체
     * @param {Number} ctstSn 검색할 공모전 일련번호
     * @returns {Promise} 결과 데이터
     * @note 공모전 출품작 상세 정보를 반환하는 함수
     * @email gy.yang@naviworks.com
     */
    selectEntryDetail(context, etrSn) {
        return new Promise(function(resolve, reject)
        {
            const params = {
                etrSn: etrSn
            };

            axiosService
            .get('scm/ctst/user/etr', { params: params })
            .then(res => {
                context.commit('setEntry', res.data.etr);
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    }
};

const getters=
{
    getEntry: function (state) {
        return {
            etrSn: state.etrSn,
            acutSn: state.acutSn,
            nickNm: state.nickNm,
            etrNm: state.etrNm,
            sttsCd: state.sttsCd,
            rcptDt: state.rcptDt,
            aprvDt: state.aprvDt,
            etrTypeCd: state.etrTypeCd,
            etrDscpCn: state.etrDscpCn,
            etrFileSn: state.etrFileSn,
            etrTubnlFileSn: state.etrTubnlFileSn,
            vtCnt: state.vtCnt,
            awdNm: state.awdNm,
            ctstSn: state.ctstSn
        };
    },
    getEntries: function (state) {
        return _.cloneDeep(state.entries);
    }
};

const mutations= 
{
    setEntry(state, entry) {
        state.etrSn = entry.etrSn || 0;
        state.acutSn = entry.acutSn || 0;
        state.nickNm = entry.nickNm || '';
        state.etrNm = entry.etrNm || '';
        state.sttsCd = entry.sttsCd || '';
        state.aprvDt = entry.aprvDt || '';
        state.etrTypeCd = entry.etrTypeCd || '';
        state.rcptDt = entry.rcptDt || '';
        state.etrTypeCd = entry.etrTypeCd || '';
        state.etrDscpCn = entry.etrDscpCn || '';
        state.etrFileSn = entry.etrFileSn || '';
        state.etrTubnlFileSn = entry.etrTubnlFileSn || '';
        state.vtCnt = entry.vtCnt || 0;
        state.awdNm = entry.awdNm || '';
        state.ctstSn = entry.ctstSn || 0;
    },
    setEntries(state, entries) {
        state.entries = entries;
    }
};

export default
{
    state, actions, getters, mutations
};