<!--
   [2022/08/01 sy.jeon]
   NiceApp.vue
   @note 나이스 인증 모듈
-->
<template>
    <v-container fluid>
        <form
            id="subform"
            ref="checkForm"
            method="post"   
            action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
        >
            <input
                type="hidden"
                name="m"
                value="checkplusService"
            />
            <input
                id="encode"
                type="hidden"
                name="EncodeData"
                value=""
            />
        </form>
        <h1>Redirection...</h1>
    </v-container>
</template>

<script>

export default {
    name: 'NiceApp',
    components: {
    },
    data() {
        return {
            encData: ''
        };
    },
    mounted(){
        this.$nextTick(() => {
            this.$store.dispatch('selectNiceEncData_App')
            .then(res => {
                document.getElementById("encode").value = res.result.sEncData
                document.getElementById("subform").submit();
            });
        })
    }, 
    methods: {
    }
}
</script>

<style scoped>
</style>