<!--
   [2022/10/21 se.yoo]
   ContestListItem.vue
   @note 공모전 목록 검색 결과 항목 컴포넌트
-->
<template>
    <v-card
        tile
        elevation="0"
        class="card-list-item pa-3"
        @click="clickItem"
        @keydown.enter.stop="clickItem"
    >
        <image-print
            :aspect-ratio="1/1"
            :src="item[this.itemImage]"
            :alt="`${$t('altContestPoster') + item[itemTitle]}`"
            width="100%"
            class="rounded-lg"
        />
        <v-card-title class="card-list-item-title overflow-ellipsis">
            {{ item[itemTitle] }}
        </v-card-title>
        <v-card-text class="card-list-item-date">
            {{ toStringByFormattingTime(new Date(item[itemDate])) }}
        </v-card-text>
    </v-card>
</template>

<script>
import ImagePrint from '../com/ImagePrint.vue';
import { toStringByFormattingTime } from '../../commons/functions.js';

export default {
    name: 'ContestListItem',
    props: {
        item: {
            type: Object
        },
        itemSn: {
            type: String,
            default: 'sn'
        },
        itemImage: {
            type: String,
            default: 'image'
        },
        itemTitle: {
            type: String,
            default: 'title'
        },
        itemDate: {
            type: String,
            default: 'date'
        }
    },
    components: { 
        ImagePrint
    },
    data() {
        return {
            imgSrc: '',
            toStringByFormattingTime
        }
    },
    methods: {
        /**
         * @method clickItem
         * @note 항목 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        clickItem() {
            this.$emit('clickItem', this.item);
        }
    }
}
</script>

<style scoped>
.card-list-item {
    display: inline-block;
}

.card-list-item-title {
    padding: 0;
    padding-top: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #303030;
}

.card-list-item-date {
    padding: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #6C6C6C !important;
}
</style>