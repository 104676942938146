<template>
    <v-container fluid>
        <v-img
            :src="require('@/assets/images/contest_banner.png')"
            :alt="$t('altDownloadBannerImg')"
            cover
            height="140"
            class="scm-img-gradient-filter-primary full-width-in-container"
        />
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg8
            >
                <v-breadcrumbs
                    :items="menus"
                    divider=">"
                    class="board-breadcrumbs"
                />
                <router-view />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { getCurrMenuRoute } from '@/commons/functions';
import { mapGetters } from 'vuex';

export default {
    name: 'BoardLayout',
    data() {
        return {
            boardRoutes: [
                {
                    name: 'boardNotice'
                },
                {
                    name: 'boardNoticeDetail',
                    ele: 'ntcSn'
                }
            ]
        };
    },
    computed: {   
        ...mapGetters({
            board: 'getPost',
        }),
        ntcSn() {
            return this.board.ntcSn;
        },
        menuRoutes() {
            return getCurrMenuRoute(this.$route);
        },
        menus() {
            return [
                {
                    text: 'HOME',
                    exact: true,
                    to: { name: 'communityHome' }
                },
                ...this.menuRoutes.map((menu, i) => {
                    const route = this.boardRoutes[i];

                    return {
                        text: menu,
                        exact: true,
                        to: route ? {
                                name: route.name,
                                params: {
                                    sn: this[route.ele] || this[route.ele]
                                }
                            }: undefined
                    };
                })
            ];
        }
    }
}
</script>

<style scoped>
.board-breadcrumbs,
.board-breadcrumbs::v-deep .v-breadcrumbs__item {
    font-size: 15px;
    line-height: 20px;
    color: #6C6C6C !important;
}

.board-breadcrumbs {
    margin-top: 6em;
    margin-bottom: 4em;
    padding: 0;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .board-breadcrumbs {
        margin-top: 3em;
        margin-bottom: 2em;
    }
}
</style>