<template>
    <v-container>
        <div v-if="privacyVersion === 3">
            <div class="mb-5">
                {{ $t('privacyDesc') }}
            </div>
            
            <privacy-labeling-table class="mb-5"/>
            <privacy-index-table class="mb-5"/>
            <privacy-policy-items class="mb-3"/>

        </div>

        <div 
            v-if="privacyVersion !== 3"
            class="mb-3"
            v-html="privacyTermsContent"
        />
        
        <privacy-before-policy
            :index = "privacyVersion"
            :text = "policy"
            @changePolicy="changePolicy"
        />
    </v-container>
</template>

<script>
import { privacyTerms } from '@/commons/resources.js';
import PrivacyLabelingTable from './PrivacyLabelingTable.vue';
import PrivacyIndexTable from './PrivacyIndexTable.vue';
import PrivacyPolicyItems from './PrivacyPolicyItems.vue';
import PrivacyBeforePolicy from './PrivacyBeforePolicy.vue';
import i18n from '@/locales/i18n';

export default {
    name: 'CommunityPrivacyContent',
    data() {
        return {
            privacyVersion: 3,
            privacyTerms,
            beforePolicy: [
                'clickTermsPrivacyV1',
                'clickTermsPrivacyV2',
            ],
            nowPolicy: ['clickTermsPrivacyV3']
        }
    },
    components: {
        PrivacyLabelingTable,
        PrivacyIndexTable,
        PrivacyPolicyItems,
        PrivacyBeforePolicy
    },
    computed: {
        privacyTermsContent() {
            return  i18n.t(this.privacyTerms[`privacy${this.privacyVersion}`].content);
        },
        privacyTermsTitle() {
            return  i18n.t(this.privacyTerms[`privacy${this.privacyVersion}`].title);
        },
        policy() {
            return this.privacyVersion === 3 ? this.beforePolicy : this.nowPolicy;
        }
    },
    methods: {
        changePolicy(i) {
            this.privacyVersion === 3 ? this.privacyVersion = i+1 : this.privacyVersion = 3;
            document.getElementsByClassName("terms-content")[0].scrollTop = 0;

        }
    }
}
</script>

<style scoped>

</style>