import { render, staticRenderFns } from "./ContestItemsWrap.vue?vue&type=template&id=626fe219&scoped=true&"
import script from "./ContestItemsWrap.vue?vue&type=script&lang=js&"
export * from "./ContestItemsWrap.vue?vue&type=script&lang=js&"
import style0 from "./ContestItemsWrap.vue?vue&type=style&index=0&id=626fe219&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626fe219",
  null
  
)

export default component.exports