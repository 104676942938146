<template>
    <v-container class="pa-0">
        <div class="privacy-table">
            <div class="privacy-table-title py-2">&lt; {{ $t('privacyIndexTable') }} &gt;</div>
            <div class="privacy-table-items">
                <div class="privacy-table-item py-2">
                    <div
                        v-for="(item, i) in left"
                        :key="i"
                        class="px-2 cursor-pointer"
                        @click="indexClick(i)"
                    >
                        {{ $t(item) }}
                    </div>
                </div>
                <div class="privacy-table-item py-2">
                    <div
                        v-for="(item, i) in right"
                        :key="i"
                        class="px-2 cursor-pointer"
                        @click="indexClick(i+7)"
                    >
                        {{ $t(item) }}
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import i18n from '@/locales/i18n';

export default {
    name: 'PrivacyIndexTable',
    data() {
        return {
            left: [
                'termsPrivacyTitle1',
                'termsPrivacyTitle2',
                'termsPrivacyTitle3',
                'termsPrivacyTitle4',
                'termsPrivacyTitle5',
                'termsPrivacyTitle6',
                'termsPrivacyTitle7',
                'termsPrivacyTitle8',
            ],
            right: [
                'termsPrivacyTitle9',
                'termsPrivacyTitle10',
                'termsPrivacyTitle11',
                'termsPrivacyTitle12',
                'termsPrivacyTitle13',
                'termsPrivacyTitle14',
                'termsPrivacyTitle15',
            ]
        }
    },
    methods: {
        indexClick(i) {
            const indexTop = document.querySelector(`.privacy-policy-item-${i}`).offsetTop
            document.getElementsByClassName("terms-content")[0].scrollTop = indexTop;
        }
    }

}
</script>

<style scoped>
.privacy-table {
    border-top: 2px solid #333;
    width: 100%;
    border-bottom: 1px solid #767676;
}

.privacy-table .privacy-table-title {
    font-weight: bold; 
    background: #F4F6F7; 
    border-bottom: 1px solid #ccc; 
    text-align: center;
}

.privacy-table .privacy-table-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, auto);
}

.privacy-table .privacy-table-items .privacy-table-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
.privacy-table .privacy-table-items .privacy-table-item:nth-child(2) {
    border-left: 1px solid #ccc;
}
</style>