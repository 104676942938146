import Vue from 'vue';
import VueRouter from 'vue-router';
import BoardNotice from '../views/board/BoardNotice.vue';
import BoardNoticeDetail from '../views/board/BoardNoticeDetail.vue';

Vue.use(VueRouter);

const boardRoutes = [
    {
        path: '/',
        name: 'boardNotice',
        component: BoardNotice
    },
    {
        path: 'detail/:sn',
        name: 'boardNoticeDetail',
        component: BoardNoticeDetail
    }
];

export default boardRoutes;
