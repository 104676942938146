<template>
    <v-row 
        v-if="!hideType" 
        justify="end"
    >
        <v-btn 
            v-if="!itemOrder"
            text
            @click="$emit('reverse')"
        >
            {{ $t('newest') }}
            <v-icon>
                mdi-swap-vertical
            </v-icon>
        </v-btn>
        <v-btn 
            v-else
            text
            @click="$emit('reverse')"
        >
            {{ $t('oldest') }}
            <v-icon
            class="reverseSort">
                mdi-swap-vertical
            </v-icon>
        </v-btn>
    </v-row>
</template>

<script>

export default {
    name: 'SortItem',
    props: {
        itemOrder: {
            type: Boolean,
            default: false
        },
        hideType: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.row {
    margin: 0px;
}
.reverseSort {
    transform: scaleY(-1); 
}
</style>
