<!--
   [2022/12/08 se.yoo]
   CommunityHomeBannerDown.vue
   @note 
-->
<template>
    <v-img
        :src="require('@/assets/images/main_banner_sub.png')"
        cover
        height="400"
        :alt="$t('altDownloadBannerImg')"
        class="scm-img-gradient-filter-grey align-center"
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col
                cols="11"
                sm="10"
                md="8"
                class="px-9 px-md-3"
            >
                <h2 class="scm-community-section-title">
                    {{ $t('metaverseSeoul') }}
                </h2>
                <div
                    class="mt-5 home-section-content"
                    v-html="$t('metaverseSeoulDownComment')"
                />
                <v-btn
                    dark
                    outlined
                    rounded
                    class="px-6 mt-15"
                    aria-label="다운로드 화면 이동 버튼"
                    @click="routerDownload"
                >
                    {{ $t('download') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-img>
</template>

<script>
export default {
    name: 'CommunityHomeBannerDown',
    methods: {
        /**
         * @method routerDownload
         * @note 다운로드 페이지로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerDownload() {
            this.$router.push({ name: 'downloadService' });
        }
    }
}
</script>

<style scoped>
.scm-community-section-title,
.home-section-content {
    color: #FFFFFF !important;
}
</style>