<!--
   [2024/05/31 sb.hwang]
   DownloadServicePc.vue
   @note 다운로드 페이지 PC버전 컴포넌트
-->
<template>
    <div>
        <div class="text-body-2 my-2">
            [PC{{ $t('filedownload') }} {{ $t('minirecoSpec') }}]
        </div>
        <v-simple-table class="download-table">
            <caption>PC다운로드 최소 및 권장 사양 안내 - 구분, 최소사양, 권장사양으로 구성</caption>
            <thead>
                <tr>
                    <th 
                        class="text-center" 
                        scope="col"
                    >
                        {{ $t('division') }}
                    </th>
                    <th 
                        class="text-center" 
                        scope="col"
                    >
                        {{ $t('miniSpec') }}
                    </th>
                    <th 
                        class="text-center" 
                        scope="col"
                    >
                        {{ $t('recoSpec') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>OS</td>
                    <td colspan="2">Window 10 Pro</td>
                </tr>
                <tr>
                    <td>CPU</td>
                    <td>Intel Core i5-9600K 3.70 GHz</td>
                    <td>AMD Ryzen 5 3600X 6-Core Processor 3.79GHz</td>
                </tr>
                <tr>
                    <td>RAM</td>
                    <td colspan="2">8GB</td>
                </tr>
                <tr>
                    <td>GPU</td>
                    <td colspan="2">NVIDIA GeForce GTX 1660 Ti</td>
                </tr>
                <tr>
                    <td>GPU RAM</td>
                    <td colspan="2">2GB</td>
                </tr>
                <tr>
                    <td>{{ $t('hardDrive') }}</td>
                    <td colspan="2">{{ $t('hardDriveInfo') }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { downloadFile } from '@/commons/functions';

export default {
    name: 'DownloadServicePc',
    components:{
    },
    data() {
        return {
            staticFileChksmCn: '',
            staticFileUrlAddr: '',
        }
    },
    computed: {
    },
    methods: {
        /**
         * @method clickRequestdPc
         * @note PC버전 다운로드 버튼 클릭 이벤트 함수
         * @email jake.jeon@naviworks.com
         */
        clickRequestdPc(){
            downloadFile(this.staticFileUrlAddr);
            this.$store.dispatch('downloadPc')
        },
        /**
         * @method selectPcUrl
         * @note 정적 파일 최신버전 정보를 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectPcUrl() {
            const params = {
                staticFileClsfNm: 'pc_installer'
            }

            this.$store.dispatch('selectStaticFile', {params})
            .then((res) => {
                this.staticFileChksmCn = res.staticFileChksmCn;
                this.staticFileUrlAddr = res.staticFileUrlAddr;
            })
        }
    },
    created() {
        this.selectPcUrl();
    }
}
</script>

<style scoped>

.download-section-button {
    background: #0A0A0A !important;
    border-radius: 10px !important;
    width: 235px !important;
    height: 70px !important;
    color: white !important;
    box-shadow: none !important;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
}

.download-table {
    text-align: center;
}

table th {
    font-weight: bold;
}

table th + th,
table td + td { 
    border-left:1px solid #dddddd; 
}


.theme--light.v-data-table td:nth-child(2),
.theme--light.v-data-table td:nth-child(3) {
    width: 40%;
}
</style>