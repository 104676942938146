<!--
   [2022/10/06 se.yoo]
   CommunityFooter.vue
   @note 시민 커뮤니티 웹 페이지 푸터
-->
<template>
    <v-footer class="community-footer d-flex justify-center">
        <v-flex
            xs12
            lg8
            :align-self-center="$vuetify.breakpoint.lgAndUp"
            :align-self-end="$vuetify.breakpoint.mdAndDown"
            class="px-3 pb-6 pa-lg-0"
        >
            <v-img
                :src="require('@/assets/images/scm-community-logo-dark.svg')"
                :alt="$t('altLogoImg_noClick')"
                contain
                height="30"
                max-width="190"
            />
            
            <div class="community-footer-content mt-3">
                <div 
                    class="cursor-pointer"
                    @click="privatePolicy"
                >
                    [{{ $t('privacyPolicy') }}]
                </div>
                {{ $t('address') }}
                <a
                    target="_blank"
                    href="https://www.seoul.go.kr/seoul/map.do"
                >
                    [{{ $t('directions') }}]
                </a> <br />
                {{ $t('number') }} <br />
                © Seoul Metropolitan Government all rights reserved
            </div>
            
        </v-flex>
        <com-dialog
            v-model="showPolicy"
            closable
            width="1000"
            :title="$t('termsPrivacy')"
        >
            <template v-slot:content>
                <v-card
                    tile
                    outlined
                    class="mt-4 mt-sm-6 pa-3 terms-content"
                >
                    <community-privacy-content/>
                </v-card>
            </template>
        </com-dialog>
    </v-footer>
</template>

<script>
import ComDialog from '../components/com/ComDialog.vue'
import CommunityPrivacyContent from '../components/privacy/CommunityPrivacyContent.vue';

export default {
    name: 'CommunityFooter',
    data() {
        return {
            showPolicy: false,
        }
    },
    components: {
        ComDialog,
        CommunityPrivacyContent,
    },
    computed: {
    },
    methods: {
        privatePolicy() {
            this.showPolicy = true;
        },
    }
}
</script>

<style scoped>
.community-footer {
    background-color: #FFFFFF;
    min-height: 280px;
    max-width: 100vw;
    border-top: 1px solid #E6E6E6;
}

.community-footer-content {
    font-size: 14px;
    line-height: 30px;
    color: #303030;
}

.community-footer-content a {
    color: #303030;
    text-decoration: none;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .community-footer-content {
        font-size: 12px;
    }
}
.terms-content {
    height: calc(100vh - 80px - 24px - 36px);
    max-height: 470px;
    overflow-y: auto;
    white-space: pre-wrap;
}
</style>