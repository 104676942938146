import Vue from 'vue';
import VueRouter from 'vue-router';
import CommunityLogin from '../views/login/CommunityLogin.vue';
import CommunitySignUp from '../views/login/CommunitySignUp.vue';
import CommunitySearch from '../views/login/CommunitySearch.vue';

Vue.use(VueRouter);

const loginRoutes = [
    {
        path: '/',
        name: 'communityLogin',
        component: CommunityLogin
    },
    {
        path:'signup',
        name: 'communitySignUp',
        component: CommunitySignUp
    },
    {
        path:'search/:menu',
        name: 'communitySearch',
        component: CommunitySearch
    }
];

export default loginRoutes;