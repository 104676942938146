<!--
   [2022/10/17 gy.yang]
   CommunitySignUpInfoCert.vue
   @note 시민 커뮤니티 웹 페이지 회원가입 화면(본인인증)
-->
<template>
    <div class="mt-4">
        <p class="community-login-desc">
            <template v-if="menu == accountMenu.SIGN_UP">
                {{ $t('personaAuthenticationSeoul') }}
            </template>
            <template v-else>
                {{  $t('personaAuthentication') }}
                <!-- {{ menuText }}를 위해서는<br />
                본인 인증이 필요합니다. -->
            </template>
        </p>
    
        <v-row
            no-gutters
            class="cert-actions justify-end"
        >
            <!-- <v-col
                cols="7"
                class="mb-3"
            >
                <v-btn
                    block
                    outlined
                    height="60"
                    class="scm-community-button-outlined community-login-btn"
                    aria-label="회원가입 이전 페이지 이동 버튼"
                    @click="$emit('prevPage')"
                >
                    {{ $t('back') }}
                </v-btn>
            </v-col> -->
            <v-col
                cols="7"
                class="mb-3"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="본인인증 버튼"
                    title="새 창 열림"
                    @click="fnPopup"
                >
                    {{ $t('nice') }}
                </v-btn>
            </v-col>
            <v-col
                cols="7"
                class="mb-3"
            >
                <v-btn
                    block
                    height="60"
                    class="scm-community-button-primary community-login-btn"
                    aria-label="본인인증 버튼"
                    title="새 창 열림"
                    @click="emailPopup"
                >
                    {{ $t('emailPass') }}
                </v-btn>
            </v-col>
        </v-row>
        <nice-dialog ref="niceModule"/>
        <alert-dialog
            v-model="errorSignUp"
            :title="errorTitle"
            :content="errorContent"
            agree="확인"
            @agree="goLogin"
        /> 
        <community-email-certified-dialog
            v-model="dialogEmail"
            :menu="menu"
            @notExistedId="notExistedId"
            @emlCertifiedComplete="emlCertifiedComplete"
        />
    </div>
</template>

<script>
import AlertDialog from '../com/AlertDialog.vue'
import NiceDialog from '@/components/nice/NiceDialog.vue';
import CommunityEmailCertifiedDialog from '@/components/login/CommunityEmailCertifiedDialog.vue';
import { signUpStep, findStep } from '@/commons/resources.js';
import { accountMenu, accountMenuInfo } from '@/commons/resources';
import i18n from '@/locales/i18n';

export default {
    name:'CommunitySignUpInfoCert',
    components: {
        NiceDialog,
        AlertDialog,
        CommunityEmailCertifiedDialog
    },
    props:{
        menu: {
            type: String,
            default: accountMenu.SIGN_UP
        }
    },
    data() {
        return {
            accountMenu,
            errorSignUp: false,
            errorTitle: '',
            errorContent: '',
            dialogEmail: false,
            emlCertified: false,
            mobileCertifed: false,
        };
    },
    computed: {
        signUpUser() {
            return this.$store.getters.getSignUpUser;
        },
        menuInfo() {
            return accountMenuInfo.find(item => 
                item.value === this.menu
            );
        },
        menuText() {
            return this.menuInfo? i18n.t(this.menuInfo.text) : '';
        }
    },
    methods:{
        /**
         * @method nextPage
         * @note 입력받은 정보를 보내고 다음 화면을 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        nextPage(){
            if(this.menu == accountMenu.SIGN_UP && this.mobileCertified) {
                this.$emit('nextPage', signUpStep.InfoId);
            } else if (this.menu == accountMenu.SIGN_UP && this.emlCertified) {
                this.$emit('nextPage', signUpStep.InfoAge);
            } else if(this.menu == accountMenu.SEARCH_ID) {
                this.$emit('nextPage', findStep.FindId);
            } else if(this.menu == accountMenu.SEARCH_PW) {
                this.$emit('nextPage', findStep.FindPwd);
            }
        },
        /**
         * @method fnPopup
         * @note 본인인증 함수
         * @email gy.yang@naviworks.com
         */
        fnPopup(){
            this.$refs.niceModule.fnPopup()
            .then((data) => {
                let params = {};
                if(this.menu == accountMenu.SIGN_UP) {
                    params = {
                        enc: data
                    };
                    
                    this.$store.dispatch('checkCi', params)
                    .then(() => {
                        const paramsUnder = {
                        enc: data,
                        name: '',
                        mobileNo: '',
                        birthDate: '',
                    };
                        this.$store.dispatch('checkUnder', paramsUnder)
                        .then((res) => {
                            if(!res.result.isUnder14) {
                                this.$store.commit('setUser', params);
                                this.mobileCertified = true;
                                this.nextPage();
                            } else {
                                this.errorSignUp = true;
                                this.errorTitle = '14세 미만의 사용자';
                                this.errorContent = '14세 미만 가입 프로세스를 이용해 주세요.'
                            }
                        })
                        .catch(() => {
                            this.errorSignUp = true;
                            this.errorTitle = '오류 발생';
                            this.errorContent = '회원가입 프로세스를 다시 진행해 주세요.'
                        })
                    })
                    .catch(() => {
                        this.errorSignUp = true;
                        this.errorTitle = '이미 등록된 사용자';
                        this.errorContent = '확인 버튼 클릭 시 로그인 페이지로 이동합니다.'
                    })
                } else {
                    params = {
                        ...this.signUpUser,
                        enc: data
                    }
                    this.$store.commit('setUser', params);
                    this.nextPage();
                }
                
            })
            .catch( (e) => {
                this.encData = e;
                alert("실패");
            })
        },
        /**
         * @method emlCertifiedComplet
         * @note 이메일 본인인증이 완료된 후 실행되는 함수
         * @email jake.jeon@naviworks.com
         */
        emlCertifiedComplete(eml) {
            this.emlCertified = true;
            if(this.menu == accountMenu.SEARCH_ID) {
                this.$store.commit('setUserRcvryEmlAddr', eml);
            }
            this.nextPage();
        },
        /**
         * @method emailPopup
         * @note 본인인증 (이메일) 함수
         * @email jake.jeon@naviworks.com
         */
        emailPopup(){
            this.dialogEmail = true;
        },
        /**
         * @method goLogin
         * @note 로그인 화면으로 돌아가는 함수
         * @email sh.hong@naviworks.com
         */
         goLogin(){
            this.$router.push({
                name: 'communityLogin'
            })
        },
        notExistedId() {
            this.errorSignUp = true;
            this.errorTitle = '존재하지 않는 사용자';
            this.errorContent = '존재하지 않는 사용자입니다.'
        }
    }
}
</script>

<style scoped>
.cert-actions {
    margin-top: 210px;
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) { 
    .cert-actions {
        margin-top: 210px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .cert-actions {
        margin-top: 60px;
        margin-bottom: 12px;
    }
}
</style>