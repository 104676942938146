import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import nice from './modules/nice';
import user from './modules/user';
import search from './modules/search';
import contest from './modules/contest';
import entry from './modules/entry';
import file from './modules/file';
import loading from './modules/loading';
import board from './modules/board';
import youtube from './modules/youtube';
import tutorial from './modules/tutorial';
import download from './modules/download';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        nice,
        user,
        search,
        contest,
        entry,
        file,
        loading,
        board,
        youtube,
        tutorial,
        download
    },
    plugins: [
        createPersistedState({
            paths: ['user', 'search', 'loading'],
        })
    ]
});
