<!--
   [2024/05/31 sb.hwang]
   BlackIconBtn.vue
   @note 검정 배경 + 아이콘 버튼
-->
<template>
    <div>
        <v-btn
            :href="url"
            target="_blank"
            class="download-section-button"
            :aria-label="label + ' 다운로드 버튼'"
            @click="click"
        >
            <v-icon
                dark
                size="36"
                class="mr-2"
                :aria-label="label + ' 아이콘'"
            >
                {{ icon }}
            </v-icon>
            {{ btnName }}
        </v-btn>
    </div>
</template>

<script>

export default {
    name: 'BlackIconBtn',
    components:{
    },
    props: {
        url: {
            type: String,
        },
        label: {
            type: String,
        },
        icon: {
            type: String,
        },
        btnName: {
            type: String
        }
    },
    data() {
        return {
        }
    },
    methods: { 
        click() {
            this.$emit('click')
        }
    },
    created() {
    }
}
</script>

<style scoped>

.download-section-button {
    background: #0A0A0A !important;
    border-radius: 10px !important;
    width: 235px !important;
    height: 70px !important;
    color: white !important;
    box-shadow: none !important;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
}
</style>