<template>
    <v-container fluid>
        <v-row
            class="pr-md-10"
            align="center"
        >
            <v-col 
                cols="6"
                md="4"
                lg="3"
                class="input-field-label pr-sm-3"
            >
                {{ $t(label) }}
            </v-col>
            <v-col
                v-if="type=='disabled'" 
                cols="12"
                md="8"
                lg="9"
            >
                <v-text-field
                    v-model="inputData"
                    dense
                    outlined
                    hide-details
                    height="60"
                    disabled
                    class="input-field disabled"
                />
            </v-col>
            <v-col
                v-if="type=='datepicker'" 
                cols="12"
                md="8"
                lg="9"
            >
                <date-picker
                    v-model="date"
                    :disabled="disabled"/>
            </v-col>
            <v-col
                v-if="type=='radio'" 
                cols="12"
                md="8"
                lg="9"
            >
                <v-radio-group
                    v-model="radio"
                    row
                    :disabled="disabled"
                    class="input-field-radio"
                >
                    <v-radio
                        :label="$t('female')"
                        value="0"
                    />
                    <v-radio
                        :label="$t('male')"
                        value="1"
                    />
                </v-radio-group>
            </v-col>
            <v-col 
                v-if="type=='btn'"
                cols="12"
                md="8"
                lg="9">
                <v-btn
                    id="change"
                    outlined
                    height="60"
                    :disabled="disabled"
                    aria-label="변경 버튼"
                    class="input-field-btn"
                    @click="$emit('clickBtn')"
                >
                    {{ text }}
                </v-btn>
            </v-col>
            <v-col
                v-if="type=='input'"
                cols="12"
                md="8"
                lg="9"
            >
                <v-text-field
                    v-model="inputData"
                    dense
                    outlined
                    hide-details
                    height="60"
                    :disabled="disabled"
                    class="input-field"
                />
            </v-col>
            <v-col
                v-if="type=='inputBtn'"
                style="display: flex;"
                cols="12"
                md="8"
                lg="9"
            >
                <v-text-field
                    v-model="inputData"
                    dense
                    outlined
                    hide-details
                    height="60"
                    class="input-field mr-2 disabled"
                />
                <v-btn
                    id="change"
                    outlined
                    height="60"
                    :disabled="disabled"
                    aria-label="변경 버튼"
                    class="input-field-btn"
                    @click="$emit('clickBtn')"
                >
                    {{ text }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatePicker from './DatePicker.vue';
export default {
    name: 'InputField',
    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        type: {
            type: String,
            default: "input"
        },
        text: {
            type: String
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            menu: false,
        }
    },
    methods: {
        changeDate() {
            this.menu = false;
        },
        headerDateFormat(date){
            let dateSplit = date.split('-');
            
            if(1 === dateSplit.length){
                return dateSplit[0] + '년';
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        radio: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        inputData: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    created() {
    }
}
</script>

<style scoped>
.input-field-label {
    font-weight: 500;
    font-size: 20px;
    word-break: keep-all;

    overflow: hidden;
    text-overflow: ellipsis;
}

.disabled::v-deep input {
    color: rgba(0, 0, 0, 0.65) !important;
}

.input-field::v-deep {
    background-color: #F7F7F7;
}

.input-field::v-deep input{
    color: black;
    height: 60px;
}

.input-field::v-deep .v-input__append-inner {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0px !important;
}

.input-field-radio.v-input--selection-controls::v-deep{
    margin-top: 0;
    padding-top: 0;
}

.input-field-radio::v-deep .v-input__control{
    height: 60px;
}

.input-field-radio::v-deep .v-input__slot{
    height: 100% !important;
    margin-bottom: 0;
}

.input-field-btn::v-deep .v-btn__content{
    color: black !important;
}
</style>