<template>     
    <v-container
        id="content"
        fluid
        class="pa-0"
    >
        <article-detail
            id="content"
            :item="notice"
            item-title="ntcTtlNm"
            item-desc="cn"
            item-img="imgUrlAddr"
            item-url="extrlUrlAddr"
            :article-infos="infos"
            html-desc
            @clickDownload="clickDownload"
        />
        <alert-dialog
            v-model="alertError"
            title="오류 발생"
            content="데이터가 존재하지 않습니다. 메인 화면으로 돌아갑니다."
            @agree="routerHome"
        />
    </v-container>
</template>

<script>
import ArticleDetail from '@/components/com/ArticleDetail.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';
import { downloadFile } from '@/commons/functions';

export default {
    name: 'BoardNoticeDetail',
    components: { 
        ArticleDetail,
        AlertDialog
    },
    data() {
        return {
            downloadFile,
            alertError: false
        }
    },
    computed: {
        notice(){
            return this.$store.getters.getPost;
        },
        infos() {
            return [
                { text: this.$t('srvcCategory'), value: 'srvcCgCd', isCategory: true },
                { text: this.$t('registrationDate'), value: 'crtDt', dateShortenToTime: true },
                // { 
                //     text: this.$t('filedownload'),
                //     value: 'imgUrlAddr', 
                //     isFile: true
                // }
            ]
        }
    },
    methods: {
        search() {
            this.$router.push({
                name: 'boardNotice'
            });
        },
        getNotice(){
            this.$store.dispatch('selectPostDetail', this.$route.params.sn)
            .catch(err => {
                this.alertError = true;
            });
        },
        clickDownload() {
            downloadFile(this.notice.imgUrlAddr)
        },
        routerHome() {
            this.$router.push({ name: 'communityHome' });
        },
    },
    created(){
        this.getNotice();
    }
}
</script>

<style scoped>
</style>
