<!--
   [2022/12/09 se.yoo]
   ContestLayout.vue
   @note 공모전 메뉴 레이아웃
-->
<template>
    <v-container fluid>
        <v-img
            :src="require('@/assets/images/contest_banner.png')"
            :alt="$t('altDownloadBannerImg')"
            cover
            height="140"
            class="scm-img-gradient-filter-primary full-width-in-container"
        />
        <v-layout justify-center>
            <v-flex
                xs11
                sm10
                lg8
            >
                <v-breadcrumbs
                    :items="menus"
                    divider=">"
                    class="contest-breadcrumbs"
                />
                <router-view />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { getCurrMenuRoute } from '@/commons/functions';
import { mapGetters } from 'vuex';

export default {
    name: 'ContestLayout',
    data() {
        return {
            contestRoutes: [
                {
                    name: 'contestList'
                },
                {
                    name: 'contestEntries',
                    ele: 'ctstSn'
                },
                {
                    name: 'contestEntriesDetail',
                    ele: 'etrSn'
                }
            ]
        };
    },
    computed: {   
        ...mapGetters({
            contest: 'getContest',
            entry: 'getEntry'
        }),
        ctstSn() {
            return this.contest.ctstSn;
        },
        etrSn() {
            return this.entry.etrSn;
        },
        menuRoutes() {
            return getCurrMenuRoute(this.$route);
        },
        menus() {
            return [
                {
                    text: 'HOME',
                    exact: true,
                    to: { name: 'communityHome' }
                },
                ...this.menuRoutes.map((menu, i) => {
                    const route = this.contestRoutes[i];

                    return {
                        text: menu,
                        exact: true,
                        to: route ? {
                                name: route.name,
                                params: {
                                    sn: this[route.ele] || this[route.ele]
                                }
                            }: undefined
                    };
                })
            ];
        }
    }
}
</script>

<style scoped>
.contest-breadcrumbs,
.contest-breadcrumbs::v-deep .v-breadcrumbs__item {
    font-size: 15px;
    line-height: 20px;
    color: #6C6C6C !important;
}

.contest-breadcrumbs {
    margin-top: 6em;
    margin-bottom: 4em;
    padding: 0;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    .contest-breadcrumbs {
        margin-top: 3em;
        margin-bottom: 2em;
    }
}
</style>