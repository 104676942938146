<template>
    <v-card
        tile
        elevation="0"
        class="item-wrap py-8 px-3"
        @click="clickItem"
        @keydown.enter.stop="clickItem"
    >
        <v-card-title class="item-title pa-0">
            {{ item[itemTitle] }}
            <v-icon
                v-if="item[itemLock]"
                size="21"
                class="ml-2"
                :aria-label="$t('altSecretIcon')"
            >
                mdi-lock
            </v-icon>
        </v-card-title>
        <v-card-text class="pa-0 item-info">
            <slot
                name="info"
                :slot-item="item"
            >
                <div class="item-category">
                    {{ $t('srvcCategory') }} : 
                    <span class="black--text">
                        {{ category }} 
                    </span>
                </div>
            </slot>
            <slot
                name="info"
                :slot-item="item"
            >
                <div class="item-date">
                    {{ $t('registrationDate') }} : 
                    <span class="black--text">
                        {{ date }} 
                    </span>
                </div>
            </slot>
            <slot
                name="lang"
                :slot-item="item"
            >
                <div class="item-lang">
                    {{ $t('language') }} : 
                    <span class="black--text">
                        {{ language }}
                    </span>
                </div>
            </slot>
        </v-card-text>
    </v-card>
</template>

<script>
import { toStringByFormatting } from '@/commons/functions';
import { langCd, srvcCgCd } from '@/commons/resources';

export default {
    name: 'BoardListItem',
    props: {
        item: {
            type: Object
        },
        itemSn: {
            type: String,
            default: 'sn'
        },
        itemLock: {
            type: String,
            default: 'lock'
        },
        itemTitle: {
            type: String,
            default: 'title'
        },
        itemCategory: {
            type: String,
            default: 'category'
        },
        itemDate: {
            type: String,
            default: 'date'
        },
        itemLang: {
            type: String,
            default: 'lang'
        },
        dateShorten: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            langCd,
            srvcCgCd
        }
    },
    computed: {
        date() {
            const result = this.item[this.itemDate];

            if(this.dateShorten) {
                return toStringByFormatting(new Date(result));
            } else {
                return result;
            }
        },
        language() {
            const lang = langCd.find(item => item.value === this.item[this.itemLang]);
            return lang? this.$t(lang.text) : '';
        },
        category() {
            const cate = srvcCgCd[this.item[this.itemCategory]];
            return this.$t(cate);
        }
    },
    methods: {
        clickItem() {
            this.$emit('clickItem', this.item);
        }
    }
}
</script>

<style scoped>
.item-wrap {
    border-bottom: 1px solid #C9CECB;
    border-radius: 0;
}

.item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.item-wrap:hover .item-title,
.item-wrap:focus .item-title {
    color: #3172D2;
}

.item-info {
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #747474;
}
</style>
