<!--
   [2022/12/13 se.yoo]
   ComDialog.vue
   @note 다이얼로그 컴포넌트
-->
<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs"
        :max-width="width"
        :persistent="persistent"
        content-class="elevation-0"
    >
        <v-card
            rounded="lg"
            elevation="0"
            :class="{ 'com-dialog-card-bg': bg  === true}"
            class="com-dialog-card pa-10 d-flex justify-center flex-column"
        >
            <slot name="title">
                <v-card-title class="pa-0 align-center scm-community-section-title">
                    {{ title }}
                    <v-spacer />
                    <v-btn
                        v-if="closable"
                        icon
                        :aria-label="`알림창 버튼_${title}`"
                        @click="dialog = false"
                    >
                        <v-icon aria-label="알림창 닫기 버튼">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
            </slot>
            <slot name="content">
                {{ content }}
            </slot>
            <slot 
                v-if="closableBtn"
                name="footer"
            > 
                <v-card-actions class="justify-center">
                    <v-btn
                        height="40"
                        class="scm-community-button-outlined community-login-btn"
                        @click="cancelDialog"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        height="40"
                        class="scm-community-button-primary"
                        @click="$emit('btnClick')"
                    >
                        {{ footer }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ComDialog',
    props: {
        value: {
            type: Boolean
        },
        title: {
            type: String,
            default: '제목'
        },
        content: {
            type: String,
            default: '내용'
        },
        width: {
            type: String,
            default: '595'
        },
        closable: {
            type: Boolean,
            default: false
        },
        closableBtn: {
            type: Boolean,
            default: false
        },
        footer: {
            type: String,
            default: '버튼'
        },
        persistent: {
            type: Boolean,
            default: true
        },
        bg: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        cancelDialog() {
            this.dialog = false;
            this.$emit('cancelClick');
        }
    }
}
</script>

<style scoped>
::v-deep .v-dialog--fullscreen .com-dialog-card.v-card {
    padding: 16px !important;
}
.scm-community-button-primary {
    background-color: #A5A5A5 !important;
}
.scm-community-button-primary, .scm-community-button-outlined {
    width: 185px;
}
.com-dialog-card-bg {
    padding: 10px !important;
    background-color: rgba(255, 255, 255, 0.7);
}
</style>