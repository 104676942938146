<!--
   [2022/10/12 gy.yang]
   CommunityLogin.vue
   @note 시민 커뮤니티 웹 페이지 로그인 화면
-->
<template>
    <div>
        <v-img
            :src="require('@/assets/images/scm-community-logo-dark.svg')"
            :alt="$t('altLogoImg')"
            contain
            width="80%"
            height="60"
            max-width="330"
        />
        <v-form
            ref="loginForm"
            class="mt-6 mt-md-12"
        >
            <v-text-field
                v-model="userId"
                :rules="[rules.required]"
                :placeholder="$t('id')"
                outlined
                hide-details="auto"
                title="아이디"
                class="community-login-input"
                @keydown.enter="login"
            />
            <v-text-field
                v-model="password"
                type="password"
                :rules="[rules.required]"
                :placeholder="$t('password')"
                outlined
                hide-details="auto"
                title="비밀번호"
                class="community-login-input mt-4"
                @keydown.enter="login"
            />
            <v-btn
                block
                height="60"
                class="scm-community-button-primary mt-4 community-login-btn"
                aria-label="로그인 완료 버튼"
                @click="login"
            >
                {{ $t('login') }}
            </v-btn>
        </v-form>
        <v-flex class="mt-3 login-actions">
            <v-btn
                v-for="menu, key in translatedAccountMenuInfo"
                :key="key"
                text
                color="#626375"
                :aria-label="`${menu.text}`"
                @click="routerMenu(menu.value)"
            >
                {{ menu.text }}
            </v-btn>
        </v-flex>
        <alert-dialog
            v-model="showAlert"
            :title="alertTitle"
            :content="alertContent"
        />
    </div>
</template>

<script>
import AlertDialog from '@/components/com/AlertDialog.vue';
import { rules, accountMenu, accountMenuInfo } from '@/commons/resources.js';

export default {
    name: 'CommunityLogin',
    components: { 
        AlertDialog
    },
    data() {
        return {
            userId: '',
            password: '',
            showAlert: false,
            alertTitle: '',
            alertContent: '',
            rules,
            accountMenuInfo
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        translatedAccountMenuInfo() {
            return this.accountMenuInfo.map(item => {
                return {
                value: item.value,
                text: this.$i18n.t(item.text),
                };
            });
        }
    },
    methods:{
        /**
         * @method login
         * @note 로그인 함수
         * @email se.yoo@naviworks.com
         */
        login(){
            const validate = this.$refs.loginForm.validate();
            
            if(!validate) return;

            const params = {
                emlAddr: this.userId,
                pswdCn: this.password
            }
            
            this.$store.dispatch('accountLogin', params)
            .then(() => {
                this.$cookies.set("user_id", this.user.emlAddr, 0);
                this.$cookies.set('user_token', this.user.token, 0);
                location.href = '/';
            }).catch((err) => {
                this.showAlert = true;
                this.alertTitle = this.$t('failLoginTitle');
                this.alertContent = err.response && err.response.data ?
                    this.$t('failLogin') : 
                    this.$t('askAdmin');
            })
        },
        /**
         * @method routerMenu
         * @param {String} menuValue 이동할 메뉴
         * @note 화면 이동 함수
         * @email se.yoo@naviworks.com
         */
         routerMenu(menuValue){
            if(menuValue === accountMenu.SIGN_UP) {
                this.$router.push({
                    name: 'communitySignUp'
                });
            } else {
                this.$router.push({
                    name: 'communitySearch',
                    params: { menu: menuValue }
                });
            }
        }
    }
}
</script>

<style scoped>
.login-actions::v-deep .v-btn {
    padding: 0 7px;
}

.login-actions::v-deep .v-btn::before,
.login-actions::v-deep .v-btn .v-ripple__container {
    opacity: 0 !important;
}

.login-actions::v-deep .v-btn:focus .v-btn__content,
.login-actions::v-deep .v-btn:hover .v-btn__content {
    text-decoration: underline;
}


.login-actions::v-deep .v-btn__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #6C6C6C;
}

.login-actions::v-deep .v-btn:not(:first-child)::before {
    content: '';
    display: block;
    width: 1px;
    height: 15px;
    background: #6C6C6C;
    opacity: 1 !important;
    margin: auto 0;
}
</style>