<!--
   [2022/12/15 se.yoo]
   NotFound.vue
   @note Not Found 404 화면
-->
<template>
    <v-container fluid>
        <v-row
            align="center"
            justify="center"
            align-content="center"
            class="py-15 notfound-section-wrap"
        >
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="4"
            >
                <v-img
                    :src="require('@/assets/images/404.svg')"
                    cover
                    alt="페이지 없음 안내 이미지_404"
                />
            </v-col>
            <v-col
                cols="11"
                sm="10"
                md="5"
                lg="4"
                class="notfound-text pt-15 pt-md-3"
            >
                <h2 class="scm-community-section-title">
                    해당 페이지를 찾을 수 없습니다.
                </h2>
                <div class="mt-2 notfound-section-content">
                    찾으려는 페이지의 주소가 잘못 입력되었거나, 주소의 변경 혹은
                    삭제로 사용할 수 없습니다.  입력하신 페이지의 주소가 정확한지
                    다시 한번 확인해 주세요.
                </div>
                <v-btn
                    width="190"
                    height="60"
                    class="scm-community-button-primary mt-10 notfound-action-btn"
                    @click="routerBefore"
                >
                    돌아가기
                </v-btn>
            </v-col>
        </v-row>
    <table><tr><th>구분</th><th>부서명</th><th>성명</th><th>직급</th><th>연락처</th></tr><tr><th>개인정보 보호책임자</th><td class="blue_text">디지털도시국</td><td>박진영</td><td>국장</td><td rowspan="2">* <div class="blue_text">정보보안과(개인정보보호팀)<br/>전화: 02-2133-7812<br/></div>이메일: privacy@seoul.go.kr</td></tr><tr><th>개인정보 보호담당자</th><td class="blue_text">정보보안과</td><td>강지원</td><td>주무관</td></tr></table> 
    </v-container>
</template>

<script>
export default {
    name: 'NotFound',
    methods: {
        /**
         * @method routerBefore
         * @note 이전 화면으로 이동하는 함수
         * @email se.yoo@naviworks.com
         */
        routerBefore() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.notfound-section-wrap {
    height: calc(100vh - 80px);
    min-height: 600px;
}

.notfound-text {
    padding-left: 200px;
}

.notfound-section-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
    color: #303030;
}

.notfound-action-btn::v-deep .v-btn__content {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
}

/* vuetify lg(<1904px) */
@media (max-width: 1903px) {
    .notfound-text {
        padding-left: 48px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .notfound-text {
        padding-left: 12px;
    }
}

table {
    border-collapse: collapse;
}
th,td{
    width: 100px;
    height: 50px;
    text-align: center;
    border: 1px solid #000;
    
    vertical-align: top;	/* 위 */
    vertical-align: bottom;   /* 아래 */
    vertical-align: middle;   /* 가운데 */
}
th {
    font-weight: bold;
}

</style>