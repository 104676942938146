/**
 * [2024/05/31 sb.hwang]
 * download.js
 * @note 다운로드 서비스 store
 */

import { axiosService } from "@/commons/api";

const state =
{
};
 
const actions=
{
    /**
     * @method selectStaticFile
     * @returns {Promise} 결과 데이터
     * @param {object} context 컨텍스트 객체
     * @note 종류별 정적 파일 최신버전 정보를 조회하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectStaticFile({}, {params}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get(`scm/static/latest`, {params})
            .then(res => {
                resolve(res.data.result);
            }).catch(err => {
                reject(err);
            });
        });
    },

};
 
const getters=
{
    
};
 
const mutations= 
{
};

export default
{
    state, actions, getters, mutations
};