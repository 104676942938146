<!--
   [2022/12/09 se.yoo]
   AboutService.vue
   @note 서비스 소개 화면
-->
<template>
    <v-container fluid>
        <v-img
            :src="require('@/assets/images/main_banner_sub.png')"
            :alt="$t('altHomeBannerImg')"
            cover
            :height="$vuetify.breakpoint.mdAndUp ? 450 : 270"
            class="scm-img-gradient-filter-primary full-width-in-container"
        />
        <about-service-top />
        <about-service-banner-middle class="full-width-in-container" />
        <about-service-bottom />
    </v-container>
</template>

<script>
import AboutServiceTop from '@/components/about/AboutServiceTop.vue';
import AboutServiceBannerMiddle from '@/components/about/AboutServiceBannerMiddle.vue';
import AboutServiceBottom from '@/components/about/AboutServiceBottom.vue';

export default {
    name: 'AboutService',
    components: { 
        AboutServiceTop,
        AboutServiceBannerMiddle,
        AboutServiceBottom
    },
}
</script>

<style scoped>
::v-deep .about-section-content {
    font-weight: 500;
    font-size: 25px;
    line-height: 36px;
    color: #303030;
}

/* vuetify sm(<960px) */
@media (max-width: 959px) { 
    ::v-deep .about-section-wrap {
        max-height: unset !important;
        height: auto;
    }

    ::v-deep .about-section-content {
        font-size: 20px;
    }
}
</style>