/**
 * [2022/08/01 sh.hong]
 * nice.js
 * @note 나이스 store
 */
import { axiosService } from "@/commons/api";
 
const state =
{

};
 
const actions=
{
    /**
     * @method selectNiceEncData
     * @param {object} context 컨텍스트 객체
     * @returns {Promise} 결과 데이터
     * @note Nice인증 요청 암호화 데이터를 요청하는 함수
     * @email sy.jeon@naviworks.com
     */
    selectNiceEncData({}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get('scm/accounts/niceUserWeb')
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
     /**
      * @method selectNiceEncData_App
      * @param {object} context 컨텍스트 객체
      * @returns {Promise} 결과 데이터
      * @note Nice인증 요청 암호화 데이터를 요청하는 함수
      * @email sy.jeon@naviworks.com
      */
    selectNiceEncData_App({}) {
        return new Promise(function(resolve, reject)
        {
            axiosService
            .get('scm/accounts/niceApp')
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
};
 
const getters=
{
    
};
 
const mutations= 
{

};

export default
{
    state, actions, getters, mutations
};