<!--
   [2022/10/21 se.yoo]
   ContestListFilter.vue
   @note 공모전 목록 필터 컴포넌트
-->
<template>
<v-row
    align="center"
>
    <v-col
        cols="5"
        sm="5"
        md="3"
        xl="2"
        class="pr-0"
    >
        <v-select
            v-model="type"
            :items="typeItems"
            :menu-props="{
                offsetY: true
            }"
            :title="$t('type')"
            dense
            outlined
            hide-details
            height="29"
            class="search-form-input"
            @change="onSearch"
        />
    </v-col>
    <v-col
        cols="8"
        sm="7"
        md="3"
        xl="3"
        class="pt-0 pt-sm-3 pr-0"
    >
            <v-text-field
                :value="printDates"
                dense
                outlined
                readonly
                hide-details
                height="29"
                placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                title="기간"
                class="search-form-input"
                @keydown.delete="deleteDate"
                @focus.stop="(datePicker = true)"
            />
            <v-date-picker
                v-show="datePicker"
                v-model="date"
                :header-date-format="headerDateFormat"
                range
                no-title
                scrollable
                color="blue darken-2"
                class="mt-1 search-date-picker"
                @input="inputDate"
            >
                <v-spacer/>
                <v-btn
                    text
                    color="blue darken-2"
                    @click="deleteDate"
                >
                    {{  $t('reset') }}
                </v-btn>
                <v-btn
                    text
                    color="blue darken-2"
                    @click="(datePicker = false)"
                >
                    {{ $t('cancel') }}
                </v-btn>
            </v-date-picker>
        </v-col>
        <v-spacer/>
        <v-col
            cols="9"
            md="3"
            lg="4"
            xl="3"
            class="pt-0 pt-md-3 pr-0" 
        >
            <v-text-field
                v-model="keyword"
                dense
                outlined
                hide-details
                height="40"
                :placeholder="$t('keyWord')"
                title="키워드"
                class="search-form-input"
                @keydown.enter="onSearch"
            />
        </v-col>
        <v-col 
            cols="auto"
            class="pt-0 pt-md-3" 
        >
            <v-btn
                width="65"
                height="40"
                class="scm-community-button-primary"
                aria-label="키워드 검색 버튼"
                @click="onSearch"
            >
                {{$t('search')}}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ContestListFilter',
    props: {
        typeKey: {
            type: String,
            default: 'typeCd'
        },
        typeItems: {
            type: Array
        }
    },
    data(){
        return{
            type: '',
            keyword: '',
            date: [],
            datePicker: false
        }
    },
    computed: {
        ...mapGetters({
            search: 'getSearchInfo',
            searchPayload: 'getSearchPayload'
        }),
        sortedDate() {
            return [...this.date].sort();
        },
        printDates() {
            return this.date && this.date.length > 0 ? this.sortedDate.join(' ~ ') : '';
        }
    },
    methods: {
        /**
         * @method onSearch
         * @note 검색 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        onSearch() {
            const payload = {};
            payload[this.typeKey] = this.type;
            
            this.$store.commit('setSearchKeyword', this.keyword);
            this.$store.commit('setSearchDate', this.sortedDate);
            this.$store.commit('setSearchPayload', payload);

            this.$emit('search');
        },
        /**
         * @method headerDateFormat
         * @param {string} date 날짜
         * @returns {string} 년-월
         * @note 달력 헤더에 나타낼 문자 반환 함수
         * @email se.yoo@naviworks.com
         */
        headerDateFormat(date){
            let dateSplit = date.split('-');
            if(1 === dateSplit.length){
                return dateSplit[0];
            }

            return dateSplit[0] + '-' + dateSplit[1];
        },
        /**
         * @method deleteDate
         * @note 날짜를 지우는 함수
         * @email gy.yang@naviworks.com
         */
        deleteDate() {
            this.date = [];
            this.datePicker = false;
        },
        inputDate() {
            if(this.date.length >= 2) this.datePicker = false;
        }
    },
    created() {
        let routerNm = this.$route.name;

        this.$store.dispatch('checkSearchReset', routerNm)
        .then(() => {
            this.keyword = this.search.keyword;
            this.date = this.search.date;
            this.type = this.searchPayload[this.typeKey] || '';
        });
    }
}
</script>

<style scoped>
.flex {
    flex: 5 1 0;
}
.flex:nth-child(3) {
    flex: 1 1 0;
}
.search-form-input {
    border-radius: 5px !important;
}

.search-form-input::v-deep .v-select__selection,
.search-form-input::v-deep .v-text-field__slot input {
    font-size: 15px;
    line-height: 100%;
    margin: 0;
}

.search-form-input::v-deep .v-input__slot {
    min-height: 40px !important;
}

.search-form-input::v-deep fieldset {
    border: 1px solid #D9D9D9;
}

.search-form-input::v-deep .v-select__slot input {
    cursor: pointer;
}

.search-date-picker{
    position: absolute !important;
    z-index: 1;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

</style>

<style>

</style>