<!--
   [2022/10/17 se.yoo]
   AlertDialog.vue
   @note 알림 다이얼로그 컴포넌트
-->
<template>
    <com-dialog v-model="dialog">
        <template v-slot:title>
            <v-card-title
                :class="{'mt-5': hideContent}"
                class="justify-center scm-community-section-title"
            >
                {{ title }}
            </v-card-title>
        </template>
        <template v-slot:content>
            <v-card-text 
                v-if="!hideContent"
                class="text-center alert-content"
            >
                {{ content }}
            </v-card-text>
            <v-card-actions class="justify-center alert-actions">
                <v-btn
                    v-if="showDisagree"
                    :color="disagreeColor"
                    dark
                    outlined
                    width="190px"
                    height="60px"
                    elevation="0"
                    class="alert-btn mr-3"
                    :aria-label="`알림창 취소 버튼_${disagree}`"
                    @click="clickDisagree"
                >
                    {{ disagree }}
                </v-btn>
                <v-btn
                    :style="agreeStyle"
                    dark
                    width="190px"
                    height="60px"
                    elevation="0"
                    class="alert-btn"
                    :aria-label="`알림창 확인 버튼_${agree}`"
                    @click="clickAgree"
                >
                    {{ agree }}
                </v-btn>
            </v-card-actions>
        </template>
    </com-dialog>
</template>

<script>
import ComDialog from './ComDialog.vue';
export default {
    name: 'AlertDialog',
    components: { 
        ComDialog
    },
    props: {
        value: {
            type: Boolean
        },
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
        },
        agree: {
            type: String,
            default: '확인'
        },
        agreeColor: {
            type: String,
            default: '#0096EB'
        },
        disagree: {
            type: String,
            default: '취소'
        },
        disagreeColor: {
            type: String,
            default: '#6C6C6C'
        },
        hideContent: {
            type: Boolean,
            default: false
        },
        showDisagree: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        agreeStyle() {
            return {
                background: this.agreeColor
            };
        }
    },
    methods: {
        /**
         * @method clickAgree
         * @note 확인(agree) 버튼 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        clickAgree() {
            this.$emit('agree');
            this.dialog = false;
        },
        /**
         * @method clickDisagree
         * @note 취소(disagree) 버튼 클릭 이벤트 함수
         * @email se.yoo@naviworks.com
         */
        clickDisagree() {
            this.$emit('disagree');
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
.alert-content {
    width: 300px;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #303030;
    padding: 0 !important;
    margin: auto;
    margin-top: 45px;
}

.alert-actions {
    margin-top: 62px;
}

.alert-btn::v-deep .v-btn__content {
    font-size: 20px;
    line-height: 29px;
}
</style>