import { render, staticRenderFns } from "./CommunitySignUpInfoAgeCheck.vue?vue&type=template&id=8b3c2056&scoped=true&"
import script from "./CommunitySignUpInfoAgeCheck.vue?vue&type=script&lang=js&"
export * from "./CommunitySignUpInfoAgeCheck.vue?vue&type=script&lang=js&"
import style0 from "./CommunitySignUpInfoAgeCheck.vue?vue&type=style&index=0&id=8b3c2056&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b3c2056",
  null
  
)

export default component.exports